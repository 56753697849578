import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import {
  absClients,
  saveRequest,
  clientIdbanks,
  feeClient,
  detailsClient,
  getBaseFee,
  getChains,
} from '../../helpers/api';
import { makeRefId } from '../../helpers/formatter';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SVGLogo from '../../components/Common/SVGLogo';
import TezosLogo from '../../components/Common/TezosLogo';
import EthereumLogo from '../../components/Common/EthereumLogo';
import PolygonLogo from '../../components/Common/PolygonLogo';
import { truncStringPortion } from '../../helpers/formatter.js';

const IBAN = require('iban');
const descLimit = 100;

class OpSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      description: null,
      descCharLeft: descLimit,
      pending: false,
      error: null,
      activeTab: '1',
      publicKeyHash: null,
      copied: false,
      displayIban: false,
      country: null,
      idbanks: [],
      client: null,
      selectedIban: null,
      referenceId: null,
      bic: null,
      salvusIban: null,
      nbPendings: 0,
      clients: [],
      wallets: [],
      selectedClient: null,
      selectedWallet: null,
      feeModel: null,
      commission: 0,
      chains: [],
      chain: null,
      loading: false,
      baseFees: [],
      baseFee: 0,
      tezosReserve: null,
      ethereumReserve: null,
      polygonReserve: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateAmount = (event) => {
    this.setState({ amount: parseInt(event.target.value) });
  };

  updateClient = async (event) => {
    this.setState({ loading: true });
    const publicKeyHash = event.target.value;
    this.setState({ selectedClient: publicKeyHash });
    this.updateIdbanks(publicKeyHash);
    const res = await detailsClient(publicKeyHash);
    this.setState({ wallets: res.data.wallets });
    const feeModel = await feeClient(publicKeyHash);
    const baseFees = await getBaseFee();
    await this.setState({
      feeModel: feeModel.data,
      loading: false,
      baseFees: baseFees.data,
    });
    this.updateChain(this.state.chain);
  };

  componentDidMount = () => {
    this._asyncRequest = getChains()
      .then((res) => {
        this.setState({ chains: res.chains });
      })
      .catch((error) => {
        console.error(error);
      });
    this._asyncRequest = absClients()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            clients: res.data.clients,
            salvusIban: res.data.salvusIban,
            tezosReserve: res.data.tezos,
            ethereumReserve: res.data.ethereum,
            polygonReserve: res.data.polygon,
          });
          this.updateIdbanks(res.data.clients[0].publicKeyHash);
        } else {
          console.error(res.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  validateRequest = () => {
    this.setState({ error: null });
    if (!this.state.chain) {
      this.setState({ error: 'No Chain selected' });
      return;
    } else if (!this.state.selectedIban) {
      this.setState({ error: 'No IBAN selected or IBAN / BIC invalid' });
      return;
    } else if (this.state.amount < 100) {
      this.setState({ error: 'Invalid EUROP amount' });
      return;
    } else if (!this.state.selectedWallet) {
      this.setState({ error: 'No wallet selected' });
      return;
    } else {
      if (this.state.feeModel) {
        this.setState({
          commission: Math.floor(
            (this.state.feeModel.burn / 100) * this.state.amount,
          ),
        });
      }
      this.setState({
        referenceId: makeRefId(8),
        amount: Math.floor(this.state.amount),
      });
      this.toggleTab('2');
    }
  };

  registerRequest = () => {
    this.setState({ pending: true });
    this._asyncRequest = saveRequest(
      this.state.referenceId,
      this.state.amount,
      this.state.description,
      this.state.iban || this.state.selectedIban,
      'SALE',
      this.state.selectedWallet,
      this.state.chain,
    )
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.toggleTab('3');
        } else {
          console.log(res.error);
          this.setState({ error: res.error, pending: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.toString(), pending: false });
      });
  };

  copyReference = () => {
    navigator.clipboard.writeText(this.state.referenceId);
    this.setState({ copied: true });
    setTimeout(
      function () {
        this.setState({ copied: false });
      }.bind(this),
      2000,
    );
  };

  updateIdbanks = (publicKeyHash) => {
    this._asyncRequest = clientIdbanks(publicKeyHash)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ idbanks: res.data.idbanks });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateChain = async (chain) => {
    await this.setState({ baseFee: 0 });
    for (let baseFee of this.state.baseFees) {
      if (baseFee.chain === chain) this.setState({ baseFee: baseFee.euro });
    }
    this.setState({ chain: chain });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Request" breadcrumbItem="Sell EUROP" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Set a sale request to sell EUROP
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="create">
                          <p>Please fill all required fields</p>
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}

                          <FormGroup>
                            <Label>Select client</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <select
                                    className="form-control"
                                    onChange={this.updateClient}
                                  >
                                    <option disabled selected value>
                                      {' '}
                                      -- select a client --{' '}
                                    </option>
                                    {this.state.clients.map((c) => (
                                      <option
                                        value={c.publicKeyHash}
                                        key={c.publicKeyHash}
                                      >
                                        {c.name}
                                      </option>
                                    ))}
                                  </select>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          {this.state.loading ? (
                            <Loader
                              type="Puff"
                              color="#545454"
                              height={50}
                              width={50}
                            />
                          ) : (
                            this.state.selectedClient && (
                              <React.Fragment>
                                <FormGroup>
                                  <Label>Select chain</Label>
                                  <Row>
                                    <Col xs="12">
                                      <p>
                                        {this.state.chains.map((chain) => (
                                          <span
                                            className="text-capitalize mr-2"
                                            key={chain.slug}
                                          >
                                            <input
                                              type="radio"
                                              name="chain"
                                              onClick={() => {
                                                this.updateChain(chain.name);
                                              }}
                                            />{' '}
                                            {chain.name}
                                            <small>
                                              [chain fee:{' '}
                                              <strong>
                                                {this.state.baseFees.map(
                                                  (baseFee) =>
                                                    baseFee.chain ===
                                                      chain.name &&
                                                    baseFee.euro * 2,
                                                )}
                                                €
                                              </strong>
                                              ]
                                            </small>
                                          </span>
                                        ))}
                                      </p>
                                    </Col>
                                  </Row>
                                </FormGroup>

                                {this.state.wallets &&
                                  this.state.wallets.length > 0 && (
                                    <FormGroup>
                                      <Label>
                                        Select wallet sending the funds
                                      </Label>
                                      <Row>
                                        {this.state.wallets.map(
                                          (wallet) =>
                                            wallet.chain ===
                                              this.state.chain && (
                                              <Col
                                                xl="3"
                                                sm="12"
                                                key={wallet.publicKeyHash}
                                              >
                                                <div className="mb-3">
                                                  <label
                                                    className="card-radio-label mb-2"
                                                    onClick={() => {
                                                      this.setState({
                                                        selectedWallet:
                                                          wallet.publicKeyHash,
                                                      });
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      name="wallet"
                                                      id={wallet.publicKeyHash}
                                                      className="card-radio-input"
                                                      readOnly
                                                    />

                                                    <div className="card-radio">
                                                      <div>
                                                        {wallet.chain ===
                                                        'tezos' ? (
                                                          <TezosLogo
                                                            width="18"
                                                            height="18"
                                                          />
                                                        ) : wallet.chain ===
                                                          'ethereum' ? (
                                                          <EthereumLogo
                                                            width="18"
                                                            height="18"
                                                          />
                                                        ) : (
                                                          <PolygonLogo
                                                            width="20"
                                                            height="20"
                                                          />
                                                        )}
                                                        <span>
                                                          {truncStringPortion(
                                                            wallet.publicKeyHash,
                                                            8,
                                                            6,
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <p className="text-muted font-size-11 mb-1">
                                                          {wallet.chain}
                                                        </p>
                                                        <h5 className="font-size-16 mb-1">
                                                          {wallet.balance.toLocaleString()}{' '}
                                                          <SVGLogo
                                                            width="18"
                                                            height="18"
                                                          />
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </label>
                                                </div>
                                              </Col>
                                            ),
                                        )}
                                      </Row>
                                    </FormGroup>
                                  )}

                                <FormGroup>
                                  <Label>EUROP amount to sell</Label>

                                  <Row>
                                    <Col sm="8">
                                      <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">
                                          <span className="input-group-text">
                                          EUROP amount
                                          </span>
                                        </InputGroupAddon>
                                        <Input
                                          type="number"
                                          className="form-control"
                                          onChange={this.updateAmount}
                                          min="1"
                                          step="1"
                                          autoComplete="off"
                                          placeholder="Whole amount"
                                        />
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <FormGroup>
                                  <Label>
                                    Short description <small>(Optional)</small>
                                  </Label>

                                  <Row>
                                    <Col sm="12">
                                      <InputGroup className="mb-2">
                                        <InputGroupAddon addonType="prepend">
                                          <span className="input-group-text">
                                            {this.state.descCharLeft} char. left
                                          </span>
                                        </InputGroupAddon>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          maxLength={descLimit}
                                          onChange={this.updateDescription}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <FormGroup>
                                  {this.state.idbanks &&
                                    this.state.idbanks.length > 0 && (
                                      <Label>
                                        Click to select a registered Bank
                                        account that will receive sale funds
                                      </Label>
                                    )}
                                  <Row>
                                    {this.state.idbanks &&
                                      this.state.idbanks.map((idbank) => (
                                        <Col xl="3" sm="12" key={idbank.iban}>
                                          <div className="mb-3">
                                            <label
                                              className="card-radio-label mb-2"
                                              onClick={() => {
                                                this.setState({
                                                  selectedIban: idbank.iban,
                                                });
                                              }}
                                            >
                                              <input
                                                type="radio"
                                                name="iban"
                                                id={idbank.iban}
                                                className="card-radio-input"
                                                readOnly
                                              />

                                              <div className="card-radio">
                                                <div>
                                                  <p className="text-muted font-size-12 mb-1">
                                                    {idbank.iban}
                                                  </p>
                                                  <h5>{idbank.bic}</h5>
                                                </div>
                                              </div>
                                            </label>
                                          </div>
                                        </Col>
                                      ))}
                                  </Row>
                                </FormGroup>
                                <div className="mt-4">
                                  <Button
                                    type="button"
                                    color="success"
                                    onClick={this.validateRequest}
                                  >
                                    Next
                                  </Button>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </TabPane>
                        <TabPane tabId="2" id="validation">
                          <h5 className="mb-4">Validate sale request</h5>
                          <p>
                            To receive EUR funds, please validate following
                            request and transfer EUROP funds to Schuman Reserve
                          </p>
                          <hr />
                          <h5 className="mb-3">
                            Reference ID :{' '}
                            <strong className="text-primary">
                              {this.state.referenceId}
                            </strong>
                          </h5>
                          <h5 className="mb-3">
                          EUROP to send:{' '}
                            <strong className="text-primary">
                              {this.state.amount} <small>EUROP</small>
                            </strong>
                          </h5>
                          <hr />
                          {this.state.commission > 0 && (
                            <h5 className="mb-3">
                              Commission :{' '}
                              <strong>
                                {this.state.commission} <small>€</small>
                              </strong>
                            </h5>
                          )}
                          {this.state.baseFee > 0 && (
                            <h5 className="mb-3">
                              Chain fees :{' '}
                              <strong>
                                {this.state.baseFee} <small>€</small>
                              </strong>
                            </h5>
                          )}
                          <h5 className="mb-3">
                            € to receive:{' '}
                            <strong>
                              {this.state.amount -
                                this.state.commission -
                                Math.floor(this.state.baseFee)}
                              <small>€</small>
                            </strong>
                          </h5>
                          <h5 className="mb-3">
                            Client :
                            <strong className="ml-1">
                              {this.state.clients.map(
                                (c) =>
                                  this.state.selectedClient ===
                                    c.publicKeyHash && c.name,
                              )}
                            </strong>
                          </h5>
                          <h5 className="mb-3">
                            Reserve :
                            <strong className="ml-1">
                              {this.state.chain === 'ethereum'
                                ? this.state.ethereumReserve
                                : this.state.chain === 'polygon'
                                ? this.state.polygonReserve
                                : this.state.tezosReserve}
                            </strong>
                          </h5>
                          <p className="mt-3">
                            By validating the request, client agrees to transfer{' '}
                            <strong className="ml-1 mr-1">
                              {this.state.amount}EUROP
                            </strong>{' '}
                            from
                            <span className="ml-1 mr-1">
                              {this.state.selectedWallet}
                            </span>{' '}
                            to
                            <strong className="ml-1 mr-1">
                              Schuman Reserve account{' '}
                              {this.state.chain === 'ethereum'
                                ? this.state.ethereumReserve
                                : this.state.chain === 'polygon'
                                ? this.state.polygonReserve
                                : this.state.tezosReserve}
                            </strong>
                            .
                          </p>
                          <p className="mt-3">
                          EUROP funds will be burned on{' '}
                            <strong>{this.state.chain}</strong> chain and{' '}
                            <strong>
                              {this.state.amount -
                                this.state.commission -
                                Math.floor(this.state.baseFee)}
                            </strong>
                            € will be transferred to <br />
                            <strong className="text-primary">
                              {(this.state.selectedIban || this.state.iban) &&
                                IBAN.printFormat(
                                  this.state.selectedIban || this.state.iban,
                                  '-',
                                )}
                            </strong>
                          </p>
                          <div className="mt-4">
                            {this.state.pending ? (
                              <p className="text-primary">
                                Processing request... Please wait.
                              </p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.registerRequest}
                              >
                                Validate sale request
                              </Button>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="3" id="final">
                          <h5 className="mb-4 text-success">
                            Sale request submitted
                          </h5>
                          <p>
                            A notification & summary mail has been sent to
                            <strong className="ml-1">
                              {this.state.clients.map(
                                (c) =>
                                  this.state.selectedClient ===
                                    c.publicKeyHash && c.name,
                              )}
                            </strong>
                          </p>
                          <p>Reference</p>
                          <h5 className="mb-3">
                            <strong className="text-primary">
                              {this.state.referenceId}
                            </strong>
                            <span>
                              {!this.state.copied ? (
                                <small
                                  className="badge badge-primary clickable ml-2"
                                  onClick={() => {
                                    this.copyReference();
                                  }}
                                >
                                  copy
                                </small>
                              ) : (
                                <small className="badge text-success pointer ml-2">
                                  copied!
                                </small>
                              )}
                            </span>
                          </h5>
                          <h5 className="mb-3">
                          EUROP to send:{' '}
                            <strong className="text-primary">
                              {this.state.amount} <small>EUROP</small>
                            </strong>
                          </h5>
                          <hr />

                          <p className="mt-2">
                            Sale request submitted and awaiting
                            <strong className="ml-1 mr-1">
                              {this.state.amount}EUROP
                            </strong>{' '}
                            tranfer on Schuman reserve wallet{' '}
                            <strong>
                              {this.state.chain === 'ethereum'
                                ? this.state.ethereumReserve
                                : this.state.chain === 'polygon'
                                ? this.state.polygonReserve
                                : this.state.tezosReserve}
                            </strong>
                            . Once received EUROP will send{' '}
                            {this.state.amount -
                              this.state.commission -
                              Math.floor(this.state.baseFee)}
                            € on account{' '}
                            <strong className="ml-1 mr-1">
                              {(this.state.selectedIban || this.state.iban) &&
                                IBAN.printFormat(
                                  this.state.selectedIban || this.state.iban,
                                  '-',
                                )}
                            </strong>
                          </p>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default OpSale;
