import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import logo from '../../assets/images/logo.png';
import { contractStorage, dictAliases, listMultisigs } from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';

//i18n
import { withTranslation } from 'react-i18next';

class FA2Structure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      modal: false,
      signers: [],
      multiSigs: [],
      storage: {},
      ops: [],
      user: {},
      loading: true,
      aliases: [],
    };
    this.togglemodal.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  componentDidMount = () => {
    this._asyncRequest = listMultisigs()
      .then((res) => {
        console.log(res);
        if (res.status === 'SUCCESS') {
          this.setState({
            multiSigs: res.data.multiSigs,
            signers: res.data.signers,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._asyncRequest = contractStorage('token')
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ storage: res.data.value });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-2">Token contract</CardTitle>
                    <div className="text-center mb-4">
                      <div className="mx-auto">
                        <Avatar src={logo} round size={82} />
                      </div>
                      <p className="font-16 text-muted mb-2">Contract ID</p>
                      <h5 className="font-size-14">
                        {this.state.storage.contract_id}
                      </h5>
                      <p className="font-16 text-muted mb-3"></p>
                      <hr />
                      <h5>
                        <Link to="#" className="text-dark">
                          Transfer status -
                          {this.state.storage.paused &&
                          this.state.storage.paused.toString() === 'true' ? (
                            <span className="text-danger font-16 ml-1">
                              Paused
                            </span>
                          ) : (
                            <span className="text-success font-16 ml-1">
                              Active
                            </span>
                          )}
                        </Link>
                        <br />
                        <small className="mr-2">Supply:</small>
                        <SVGLogo />{' '}
                        {this.state.storage.totalSupply
                          ? parseFloat(
                              this.state.storage.totalSupply /
                                this.state.decimals,
                            ).toLocaleString('fr')
                          : 0}
                      </h5>
                    </div>
                    <Row className="mb-1">
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-primary font-size-16">
                              <i className="mdi mdi-cog-outline text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Administrator</h5>
                          <p className="text-muted mb-0 font-size-10">
                            {truncStringPortion(
                              this.state.storage.administrator,
                              7,
                              6,
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-info font-size-16">
                              <i className="mdi mdi-stamper text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Master Minter</h5>
                          <p className="text-muted mb-0 font-size-10">
                            {truncStringPortion(
                              this.state.storage.master_minter ||
                                this.state.storage.minter,
                              7,
                              6,
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-pink font-size-16">
                              <i className="mdi mdi-gavel text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Owner</h5>
                          <p className="text-muted mb-0 font-size-10">
                            {truncStringPortion(this.state.storage.owner, 7, 6)}
                          </p>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-dark font-size-16">
                              <i className="mdi mdi-stamper text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Delegatted minters</h5>
                          {this.state.storage.minters &&
                            Object.keys(this.state.storage.minters).map(
                              (minter) => (
                                <p className="text-muted mb-0 font-size-10">
                                  {truncStringPortion(minter, 7, 6)}
                                </p>
                              ),
                            )}
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-secondary font-size-16">
                              <i className="mdi mdi-circle-multiple-outline text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Fees manager</h5>
                          <p className="text-muted mb-0 font-size-10">
                            {this.state.storage.fees_manager
                              ? truncStringPortion(
                                  this.state.storage.fees_manager,
                                  7,
                                  6,
                                )
                              : '-'}
                          </p>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div className="social-source text-center mt-3">
                          <div className="avatar-xs mx-auto mb-3">
                            <span className="avatar-title rounded-circle bg-success font-size-16">
                              <i className="bx bx-id-card text-white"></i>
                            </span>
                          </div>
                          <h5 className="font-size-12">Rights manager</h5>
                          <p className="text-muted mb-0 font-size-10">
                            {this.state.storage.rights_manager
                              ? truncStringPortion(
                                  this.state.storage.rights_manager,
                                  7,
                                  6,
                                )
                              : '-'}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                {this.state.multiSigs &&
                  this.state.multiSigs.map(
                    (multiSig) =>
                      multiSig.publicKeyHash === this.state.storage.owner && (
                        <Card
                          className="mini-stats-wid"
                          key={multiSig.publicKeyHash}
                        >
                          <CardBody>
                            <Media>
                              <Media body>
                                <small className="text-muted font-weight-medium">
                                  {this.state.storage.owner}
                                </small>
                                <h4 className="mb-0">
                                  Owner{' '}
                                  <small>
                                    ({multiSig.storage.signers.length} signers)
                                  </small>
                                </h4>
                                {multiSig.storage &&
                                  multiSig.storage.signers && (
                                    <p>
                                      {multiSig.storage.signers.map(
                                        (signer) => (
                                          <span
                                            className="d-block font-size-11 mt-1 mb-0"
                                            key={'sig_' + signer}
                                          >
                                            <i className="mdi mdi-account mr-2"></i>
                                            {this.state.aliases[signer] &&
                                            this.state.aliases[signer].name
                                              ? this.state.aliases[signer].name
                                              : truncStringPortion(
                                                  signer,
                                                  8,
                                                  6,
                                                )}
                                            <small className="ml-2">
                                              ({signer})
                                            </small>
                                          </span>
                                        ),
                                      )}
                                    </p>
                                  )}
                                <h5>Operations</h5>
                                <span className="badge badge-pink ml-2">
                                  Change Administrator
                                </span>
                                <span className="badge badge-pink ml-2">
                                  Change Master minter
                                </span>
                                <span className="badge badge-pink ml-2">
                                  Create new token
                                </span>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                <span className="avatar-title bg-pink">
                                  <i className="mdi mdi-gavel font-size-24"></i>
                                </span>
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      ),
                  )}
                {this.state.multiSigs &&
                  this.state.multiSigs.map(
                    (multiSig) =>
                      multiSig.publicKeyHash ===
                        this.state.storage.administrator && (
                        <Card
                          className="mini-stats-wid"
                          key={multiSig.publicKeyHash}
                        >
                          <CardBody>
                            <Media>
                              <Media body>
                                <small className="text-muted font-weight-medium">
                                  {this.state.storage.administrator}
                                </small>
                                <h4 className="mb-0">
                                  Administrator{' '}
                                  <small>
                                    ({multiSig.storage.signers.length} signers)
                                  </small>
                                </h4>
                                {multiSig.storage &&
                                  multiSig.storage.signers && (
                                    <p>
                                      {multiSig.storage.signers.map(
                                        (signer) => (
                                          <span
                                            className="d-block font-size-11 mt-1 mb-0"
                                            key={'sig_' + signer}
                                          >
                                            <i className="mdi mdi-account mr-2"></i>
                                            {this.state.aliases[signer] &&
                                            this.state.aliases[signer].name
                                              ? this.state.aliases[signer].name
                                              : truncStringPortion(
                                                  signer,
                                                  8,
                                                  6,
                                                )}
                                            <small className="ml-2">
                                              ({signer})
                                            </small>
                                          </span>
                                        ),
                                      )}
                                    </p>
                                  )}
                                <h5>Operations</h5>
                                <span className="badge badge-primary ml-2">
                                  Pause transfers
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Force transfer
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Lock/unlock address
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Change/remove fees manager
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Change/remove rights manager
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Update fees percentage
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Update fees threshold
                                </span>
                                <span className="badge badge-primary ml-2">
                                  Update storage fees
                                </span>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                <span className="avatar-title bg-primary">
                                  <i className="mdi mdi-cog-outline font-size-24"></i>
                                </span>
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      ),
                  )}
              </Col>
              <Col xl="4">
                {this.state.multiSigs &&
                  this.state.multiSigs.map(
                    (multiSig) =>
                      multiSig.publicKeyHash ===
                        this.state.storage.master_minter && (
                        <Card
                          className="mini-stats-wid"
                          key={multiSig.publicKeyHash}
                        >
                          <CardBody>
                            <Media>
                              <Media body>
                                <small className="text-muted font-weight-medium">
                                  {this.state.storage.master_minter}
                                </small>
                                <h4 className="mb-0">
                                  Master minter{' '}
                                  <small>
                                    (
                                    {multiSig.storage.operators.length +
                                      multiSig.storage.controllers.length}{' '}
                                    signers)
                                  </small>
                                </h4>
                                {multiSig.storage &&
                                  multiSig.storage.operators &&
                                  multiSig.storage.controllers && (
                                    <p>
                                      {multiSig.storage.operators.map(
                                        (operator) => (
                                          <span
                                            className="d-block font-size-10 mt-1 mb-0"
                                            key={'op_' + operator}
                                          >
                                            <i className="mdi mdi-account mr-2"></i>
                                            {this.state.aliases[operator] &&
                                            this.state.aliases[operator].name
                                              ? this.state.aliases[operator]
                                                  .name
                                              : truncStringPortion(
                                                  operator,
                                                  8,
                                                  6,
                                                )}
                                            <small className="ml-2">
                                              ({operator})
                                            </small>
                                            <span className="ml-2">
                                              (operator)
                                            </span>
                                          </span>
                                        ),
                                      )}
                                      {multiSig.storage.controllers.map(
                                        (controller) => (
                                          <span
                                            className="d-block font-size-10 mt-1 mb-0"
                                            key={'ctrl_' + controller}
                                          >
                                            <i className="mdi mdi-account mr-2"></i>
                                            {this.state.aliases[controller] &&
                                            this.state.aliases[controller].name
                                              ? this.state.aliases[controller]
                                                  .name
                                              : truncStringPortion(
                                                  controller,
                                                  8,
                                                  6,
                                                )}
                                            <small className="ml-2">
                                              ({controller})
                                            </small>
                                            <span className="ml-2">
                                              (controller)
                                            </span>
                                          </span>
                                        ),
                                      )}
                                    </p>
                                  )}
                                <h5>Operations</h5>
                                <span className="badge badge-info ml-2">
                                  Mint
                                </span>
                                <span className="badge badge-info ml-2">
                                  Burn
                                </span>
                                <span className="badge badge-info ml-2">
                                  Add minter
                                </span>
                                <span className="badge badge-info ml-2">
                                  Remove minter
                                </span>
                                <span className="badge badge-info ml-2">
                                  Update allowance
                                </span>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-dark align-self-center">
                                <span className="avatar-title bg-info">
                                  <i className="mdi mdi-stamper font-size-24"></i>
                                </span>
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      ),
                  )}
                {this.state.multiSigs &&
                  this.state.multiSigs.map(
                    (multiSig) =>
                      multiSig.name === 'Reserve' && (
                        <Card
                          className="mini-stats-wid"
                          key={multiSig.publicKeyHash}
                        >
                          <CardBody>
                            <Media>
                              <Media body>
                                <small className="text-muted font-weight-medium">
                                  {multiSig.publicKeyHash}
                                </small>
                                <h4 className="mb-0">
                                  Reserve{' '}
                                  <small>
                                    ({multiSig.storage.signers.length} signers)
                                  </small>
                                </h4>
                                {multiSig.storage &&
                                  multiSig.storage.signers && (
                                    <p>
                                      {multiSig.storage.signers.map(
                                        (signer) => (
                                          <span
                                            className="d-block font-size-11 mt-1 mb-0"
                                            key={'sig_' + signer}
                                          >
                                            <i className="mdi mdi-account mr-2"></i>
                                            {this.state.aliases[signer] &&
                                            this.state.aliases[signer].name
                                              ? this.state.aliases[signer].name
                                              : truncStringPortion(
                                                  signer,
                                                  8,
                                                  6,
                                                )}
                                            <small className="ml-2">
                                              ({signer})
                                            </small>
                                          </span>
                                        ),
                                      )}
                                    </p>
                                  )}
                                <h5>Operations</h5>
                                <span className="badge badge-warning ml-2">
                                  Transfer
                                </span>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-pink align-self-center">
                                <span className="avatar-title bg-warning">
                                  <i className="mdi mdi-wallet font-size-24"></i>
                                </span>
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      ),
                  )}
              </Col>
              {this.state.storage.fees && (
                <Col md="12">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Media>
                        <Media body>
                          <small className="text-muted font-weight-medium">
                            {this.state.storage.fees_manager}
                          </small>
                          <h4 className="mb-0">Fees manager</h4>
                          <p className="mb-0">
                            Transfer:{' '}
                            <b>
                              {this.state.storage.fees
                                ? this.state.storage.fees.gasFee / 100
                                : '-'}
                              %
                            </b>{' '}
                            <small>of amount</small>
                            <hr />
                            Gasless transfer:{' '}
                            <b>
                              {this.state.storage.fees
                                ? this.state.storage.fees.gaslessFee / 100
                                : '-'}
                              %
                            </b>{' '}
                            <small>of amount</small>
                            <hr />
                            <b>
                              €
                              {this.state.storage.fees
                                ? this.state.storage.fees.threshold /
                                  this.state.decimals
                                : '-'}
                            </b>{' '}
                            <small>min. amount for gasless transfer</small>
                            <br />
                            <b>
                              Tx ~0.003 XTZ = €
                              {this.state.storage.euro_change
                                ? parseFloat(
                                    this.state.storage.euro_change * 0.003,
                                  ).toFixed(3)
                                : '-'}
                            </b>{' '}
                            <small className="ml-1">
                              (1 XTZ = €
                              {this.state.storage.euro_change
                                ? this.state.storage.euro_change.toFixed(3)
                                : '-'}
                              )
                            </small>
                            <hr />
                            <b>
                              €
                              {this.state.storage.fees
                                ? this.state.storage.fees.storageFee /
                                  this.state.decimals
                                : '-'}
                            </b>{' '}
                            <small>
                              {' '}
                              storage amount when registering new account for
                              gasless transfer
                            </small>
                            <br />
                            <b>
                              Tx ~0.0195 XTZ = €
                              {this.state.storage.euro_change
                                ? parseFloat(
                                    this.state.storage.euro_change * 0.0195,
                                  ).toFixed(3)
                                : '-'}
                            </b>{' '}
                            <small className="ml-1">
                              (1 XTZ = €
                              {this.state.storage.euro_change
                                ? this.state.storage.euro_change.toFixed(3)
                                : '-'}
                              )
                            </small>
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FA2Structure);
