import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import './datatables.scss';
import { retrieveActivity, dictAliases } from '../../helpers/api';
import { normalizeString } from '../../helpers/formatter.js';
import CSV from '../../components/Common/CSV';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      startDate: new Date(),
      activeTab: '1',
      activities: [],
      limit: false,
      loading: true,
      aliases: {},
      csvRows: [],
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this._asyncRequest = retrieveActivity(500)
          .then((res) => {
            if (res.status === 'SUCCESS') {
              let rows = [],
                csvRows = [];
              res.data.forEach((act) => {
                try {
                  const jsonBody = JSON.parse(
                    act.body.replace(/null/g, '"Not applicable"'),
                  );
                  rows.push({
                    created_at:
                      act.createdAt.replace('T', ' ').replace('Z', '') + ' GMT',
                    event:
                      act.event === 'SAVE_OPERATION'
                        ? jsonBody['opType']
                        : normalizeString(act.event.replace(/_/g, ' ')),
                    body: (
                      <>
                        {Object.keys(jsonBody).map((e, i) => (
                          <div key={i} className="mt-1 font-size-10">
                            {e} :
                            <span className="ml-1">
                              {['amount', 'balance', 'value'].includes(e)
                                ? parseFloat(
                                    jsonBody[e] / this.state.decimals,
                                  ) + ' €'
                                : typeof jsonBody[e] === 'object'
                                ? Object.keys(jsonBody[e]).map((k, j) => (
                                    <div
                                      key={e + 'sub' + j}
                                      className="mt-1 font-size-10 ml-3"
                                    >
                                      {k} :
                                      <span className="ml-1">
                                        {[
                                          'amount',
                                          'balance',
                                          'value',
                                        ].includes(k)
                                          ? parseFloat(
                                              jsonBody[e][k] /
                                                this.state.decimals,
                                            ) + ' €'
                                          : k !== 'publicKeyHash' &&
                                            this.state.aliases[
                                              jsonBody[e][k].toString()
                                            ] &&
                                            this.state.aliases[
                                              jsonBody[e][k].toString()
                                            ].name
                                          ? this.state.aliases[
                                              jsonBody[e][k].toString()
                                            ].name
                                          : jsonBody[e][k].toString()}
                                      </span>
                                    </div>
                                  ))
                                : e !== 'publicKeyHash' &&
                                  this.state.aliases[jsonBody[e].toString()] &&
                                  this.state.aliases[jsonBody[e].toString()]
                                    .name
                                ? this.state.aliases[jsonBody[e].toString()]
                                    .name
                                : jsonBody[e].toString()}
                            </span>
                          </div>
                        ))}
                      </>
                    ),
                    success: act.success ? (
                      <span className="badge badge-success font-size-10">
                        Validated
                      </span>
                    ) : (
                      <span className="badge badge-danger font-size-10">
                        Error
                      </span>
                    ),
                    user_id: act.User ? act.User.email : act.userId,
                  });
                } catch (e) {
                  rows.push({
                    created_at:
                      act.createdAt.replace('T', ' ').replace('Z', '') + ' GMT',
                    event: act.event,
                    body: JSON.stringify(act.body).replaceAll('null', '""'),
                    success: act.success ? (
                      <span className="badge badge-success font-size-10">
                        Validated
                      </span>
                    ) : (
                      <span className="badge badge-danger font-size-10">
                        Error
                      </span>
                    ),
                    user_id: act.User ? act.User.email : act.userId,
                  });
                  console.log(e);
                }
                csvRows.push({
                  created_at: act.createdAt,
                  event: act.event,
                  body: JSON.stringify(
                    act.body.replace(/null/g, '"Not applicable"'),
                  ),
                  success: act.success.toString(),
                  user_id: act.userId,
                });
              });
              this.setState({
                activities: {
                  columns: [
                    {
                      label: 'Date',
                      field: 'created_at',
                      sort: 'asc',
                      width: 136,
                    },
                    {
                      label: 'Event',
                      field: 'event',
                      sort: 'asc',
                      width: 136,
                    },
                    {
                      label: 'Parameters',
                      field: 'body',
                      width: 160,
                    },
                    {
                      label: 'Status',
                      field: 'success',
                      sort: 'asc',
                      width: 104,
                    },
                    {
                      label: 'User email',
                      field: 'user_id',
                      sort: 'asc',
                      width: 100,
                    },
                  ],
                  rows: rows,
                },
                loading: false,
                csvRows,
              });
            } else {
              console.log(res.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">Platform operations</h4>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="p-3"
                      >
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.activities}
                            className="mt-1"
                          />
                          <CSV
                            transactions={this.state.activities}
                            csvRows={this.state.csvRows}
                            order={['created_at', 'desc']}
                            name="activity"
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Activity;
