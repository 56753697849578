import './rightbar.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { listAliases } from '../../helpers/api';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { hideRightSidebar } from '../../store/actions';
import SimpleBar from 'simplebar-react';

class RightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallets: [],
      multiSigs: [],
      copied: null,
      filter: 'client',
      activeToken:
        this.props.activeToken === 'fa2'
          ? 'tezos'
          : this.props.activeToken === 'erc20'
          ? 'ethereum'
          : 'polygon',
    };
    this.hideRightbar = this.hideRightbar.bind(this);
  }

  /**
   * Hides the right sidebar
   */
  hideRightbar(e) {
    e.preventDefault();
    this.props.hideRightSidebar();
  }

  setFilter = (filter) => {
    this.setState({ filter: filter });
  };

  copyPublicKeyHash = (publicKeyHash) => {
    navigator.clipboard.writeText(publicKeyHash);
    this.setState({ copied: publicKeyHash });
    setTimeout(
      function () {
        this.setState({ copied: null });
      }.bind(this),
      2000,
    );
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (e.target.tagName === 'OPTION') {
      return true;
    }

    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.props.hideRightSidebar();
  };

  componentDidMount = () => {
    if (this.state.wallets.length === 0) {
      this._asyncRequest = listAliases()
        .then((res) => {
          if (res.status === 'SUCCESS') {
            this.setState({
              wallets: res.data.wallets,
              multiSigs: res.data.multiSigs,
            });
          } else {
            console.log(res.error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="right-bar"
          style={{ overflowY: 'auto' }}
          ref={(node) => (this.node = node)}
        >
          <SimpleBar>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={this.hideRightbar}
                  className="right-bar-toggle float-right"
                >
                  <i className="mdi mdi-close noti-icon"></i>
                </Link>
                <h5 className="m-0">Wallets / MultiSigs</h5>
              </div>

              <hr className="my-0 mb-2" />

              <Nav tabs className="nav-tabs-custom m-3" role="tablist">
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeToken === 'tezos',
                    })}
                    onClick={() => {
                      this.setState({ activeToken: 'tezos' });
                    }}
                  >
                    <p className="font-weight-bold mb-1">Tezos</p>
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeToken === 'ethereum',
                    })}
                    onClick={() => {
                      this.setState({ activeToken: 'ethereum' });
                    }}
                  >
                    <p className="font-weight-bold mb-1">Ethereum</p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeToken === 'polygon',
                    })}
                    onClick={() => {
                      this.setState({ activeToken: 'polygon' });
                    }}
                  >
                    <p className="font-weight-bold mb-1">Polygon</p>
                  </NavLink>
                </NavItem>
              </Nav>
              {this.state.filter === 'client' ? (
                <div className="btn btn-primary waves-effect waves-light btn-sm ml-2 mr-2 mb-3">
                  <i className="mdi mdi-account-circle-outline mr-1"></i>Clients
                </div>
              ) : (
                <div
                  className="btn btn-secondary waves-effect waves-light btn-sm ml-2 mr-2 mb-3"
                  onClick={() => {
                    this.setFilter('client');
                  }}
                >
                  <i className="mdi mdi-account-circle-outline mr-1"></i>Clients
                </div>
              )}
              {this.state.filter === 'ledger' ? (
                <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-3">
                  <i className="mdi mdi-shield-key mr-1"></i>Ledgers
                </div>
              ) : (
                <div
                  className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-3"
                  onClick={() => {
                    this.setFilter('ledger');
                  }}
                >
                  <i className="mdi mdi-shield-key mr-1"></i>Ledgers
                </div>
              )}
              {this.state.filter === 'platform' ? (
                <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-3">
                  <i className="mdi mdi-wallet mr-1"></i>Platforms
                </div>
              ) : (
                <div
                  className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-3"
                  onClick={() => {
                    this.setFilter('platform');
                  }}
                >
                  <i className="mdi mdi-wallet mr-1"></i>Platforms
                </div>
              )}
              {this.state.filter === 'multisig' ? (
                <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-3">
                  <i className="mdi mdi-wallet mr-1"></i>MultiSigs
                </div>
              ) : (
                <div
                  className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-3"
                  onClick={() => {
                    this.setFilter('multisig');
                  }}
                >
                  <i className="mdi mdi-wallet mr-1"></i>MultiSigs
                </div>
              )}
              {this.state.wallets &&
                this.state.wallets.map(
                  (wallet) =>
                    (wallet.walletType === this.state.filter ||
                      (this.state.filter === 'platform' &&
                        wallet.walletType === 'auto')) &&
                    (wallet.chain === this.state.activeToken ||
                      (this.state.activeToken === 'polygon' &&
                        wallet.chain === 'ethereum')) && (
                      <div className="p-2" key={'c' + wallet.publicKeyHash}>
                        <div className="radio-toolbar">
                          <span className="mb-1">{wallet.name}</span>
                          {this.state.copied !== wallet.publicKeyHash ? (
                            <span
                              className="badge badge-primary float-right clickable"
                              onClick={() => {
                                this.copyPublicKeyHash(wallet.publicKeyHash);
                              }}
                            >
                              copy
                            </span>
                          ) : (
                            <span className="badge float-right pointer">
                              copied!
                            </span>
                          )}
                          <p className="text-muted mb-2 font-size-10">
                            {wallet.publicKeyHash}
                          </p>
                        </div>
                      </div>
                    ),
                )}
              {this.state.filter === 'multisig' &&
                this.state.multiSigs &&
                this.state.multiSigs.map(
                  (multiSig) =>
                    multiSig.chain === this.state.activeToken && (
                      <div className="p-2" key={'m' + multiSig.publicKeyHash}>
                        <div className="radio-toolbar">
                          <span className="mb-1">{multiSig.name}</span>
                          {this.state.copied !== multiSig.publicKeyHash ? (
                            <span
                              className="badge badge-primary float-right clickable"
                              onClick={() => {
                                this.copyPublicKeyHash(multiSig.publicKeyHash);
                              }}
                            >
                              copy
                            </span>
                          ) : (
                            <span className="badge float-right pointer">
                              copied!
                            </span>
                          )}
                          <p className="text-muted mb-2 font-size-10">
                            {multiSig.publicKeyHash}
                          </p>
                        </div>
                      </div>
                    ),
                )}
            </div>
          </SimpleBar>
        </div>
        <div className="rightbar-overlay"></div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { activeToken } = state.Token;
  return { ...state.Layout, activeToken };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
})(RightSidebar);
