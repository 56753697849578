import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getBaseFee, updtBaseFee } from '../../helpers/api';

class BaseFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseFees: null,
      euro: null,
      chain: 'tezos',
      success: false,
      error: null,
    };
  }

  updtEuro = (e) => {
    if (!isNaN(e.target.value)) this.setState({ euro: e.target.value });
  };

  updtChain = (e) => {
    this.setState({ chain: e.target.value });
  };

  componentDidMount = async () => {
    const resp = await getBaseFee();
    this.setState({ baseFees: resp.data });
  };

  pushBasefee = async () => {
    try {
      await updtBaseFee(this.state.chain, this.state.euro);
      const resp = await getBaseFee();
      this.setState({ baseFees: resp.data, success: true, error: null });
    } catch (e) {
      console.log(e);
      this.setState({ error: e.toString(), success: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Quote"
              breadcrumbItem={`Manage ${this.props.match.params.currency} quote`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-2">Manage Gas fees</CardTitle>
                    {this.state.error && (
                      <p className="badge badge-danger font-size-12">
                        {this.state.error}
                      </p>
                    )}
                    {this.state.success && (
                      <p className="badge badge-success font-size-12">
                        Gas fee successfully updated
                      </p>
                    )}
                    <Row className="mb-2">
                      <Col md="2">
                        <InputGroup className="mb-2">
                          <Input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={this.updtEuro}
                            autoComplete="off"
                            placeholder="Price €"
                          />
                          <InputGroupAddon addonType="append">
                            <span className="input-group-text">€</span>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col md="2">
                        <select
                          className="form-control"
                          onChange={this.updtChain}
                        >
                          <option value="tezos">Tezos</option>
                          <option value="ethereum">Ethereum</option>
                          <option value="polygon">Polygon</option>
                        </select>
                      </Col>
                      <Col md="6">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            this.pushBasefee();
                          }}
                        >
                          Update Gas fees
                        </button>
                      </Col>
                    </Row>
                    {this.state.baseFees && (
                      <React.Fragment>
                        <hr />
                        <CardTitle className="mt-4 mb-2">
                          List of Gas fees
                        </CardTitle>
                        <Table className="project-list-table table-nowrap table-centered">
                          <thead>
                            <tr>
                              <th scope="col">Chain</th>
                              <th scope="col">Euro value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.baseFees.map((basefee) => (
                              <tr>
                                <td>{basefee.chain.toUpperCase()}</td>
                                <td>
                                  <strong>{basefee.euro}</strong> €
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(BaseFee);
