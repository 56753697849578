import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { Container, Row } from "reactstrap";
import BeatLoader from 'react-spinners/BeatLoader'

import Breadcrumbs from '../../components/Common/Breadcrumb';
import CardNotification from "./card-notif";
import { getNotificationConfig } from "../../helpers/api"
import NewAlert from './new-alert'

const Alert = ({ user: self }) => {
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])

  const loadNotifications = async () => {
    setLoading(true)
    let res = await getNotificationConfig()
    if (res.status === 'SUCCESS') {
      setNotifications(res.data.map(u => {
        return {
          id: u.id,
          name: `${u.name}`,
          cnt: u.cnt,
        }
      }))
    }
    setLoading(false)
  }

  useEffect(() => {
    loadNotifications()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Alert" />
          <div style={{ marginBottom: '20px' }}>
            <NewAlert loadNotifications={loadNotifications} />
          </div>

          {loading && (
            <div style={{ width: '100%', height: '100%', minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <BeatLoader
                  size={15}
                  margin={2}
                  loading={true}
                  color={"#2a3042"}
                />
              </div>
            </div>
          )}

          {!loading && (
            <Row>
              {
                notifications.map((notification) =>
                  <CardNotification notification={notification} key={notification.id} loadNotifications={loadNotifications} />
                )
              }
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { user } = state.Login;
  return { user };
}

export default withRouter(connect(mapStatetoProps, null)(Alert));