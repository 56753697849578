import React, { Component } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passPhrase: null,
    };
  }

  updatePassPhrase = (event) => {
    this.setState({ passPhrase: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          size="sm"
          isOpen={this.props.deleteModal}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Remove Wallet
            </h5>
          </div>
          {this.props.selectedWallet.walletType === 'platform' ? (
            <div className="modal-body">
              {this.props.error && (
                <p className="text-danger">{this.props.error}</p>
              )}
              <FormGroup>
                <Label>
                  Please enter <b>{this.props.selectedWallet.name}</b> wallet
                  passphrase to remove it
                </Label>

                <Row>
                  <Col sm="12">
                    <InputGroup className="mb-2">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="bx bx-lock"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        className="form-control"
                        onChange={this.updatePassPhrase}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <Row className="mt-3">
                <Col sm="4">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => this.props.toggleDeleteModal()}
                  >
                    Cancel
                  </button>
                </Col>
                <Col sm="8">
                  <button
                    className="btn btn-danger btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() =>
                      this.props.deleteWallet(
                        this.props.selectedWallet.publicKeyHash,
                        this.state.passPhrase,
                      )
                    }
                  >
                    Remove
                  </button>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="modal-body">
              <FormGroup>
                <Label>
                  Are you to remove <b>{this.props.selectedWallet.name}</b>{' '}
                  wallet?
                </Label>
              </FormGroup>
              <Row className="mt-3">
                <Col sm="4">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => this.props.toggleDeleteModal()}
                  >
                    Cancel
                  </button>
                </Col>
                <Col sm="8">
                  <button
                    className="btn btn-danger btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() =>
                      this.props.deleteWallet(
                        this.props.selectedWallet.publicKeyHash,
                        this.state.passPhrase,
                      )
                    }
                  >
                    Remove
                  </button>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeleteModal;
