import React from 'react';

const Copyright = () => {
  return (
    <p className="text-dark">
      © {new Date().getFullYear()} Sceme. Powered by{' '}
      <a
        href="https://sceme.io"
        rel={'noreferrer'}
        target="_blank"
        className="font-weight-bold text-success"
      >
        Sceme.io
      </a>
    </p>
  );
};

export default Copyright;
