import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import TrustBuilder from '../pages/Authentication/TrustBuilder';
/* import ResetPassword from '../pages/Authentication/ResetPassword'; */
import Logout from '../pages/Authentication/Logout';
/* import ForgetPwd from '../pages/Authentication/ForgetPassword'; */

// Inner Authentication
/* import Login1 from '../pages/AuthenticationInner/Login';
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen'; */

// Dashboard
import Dashboard from '../pages/Dashboard/index';
import FA2Structure from '../pages/Structure/fa2';
import ERC20Structure from '../pages/Structure/erc20';
import PolygonStructure from '../pages/Structure/polygon';

// Transactions
import Transactions from '../pages/Transactions/index';
import AddressTx from '../pages/Transactions/AddressTx';
import TransactionDetails from '../pages/Transaction-details/index';
import OCA from '../pages/OCA/index';
import ExtractDetails from '../pages/OCA/details';
import UserTxs from '../pages/OCA/user';

// Proposals
import MinterProposals from '../pages/Proposals/Minter';
import OwnerProposals from '../pages/Proposals/Owner';
import ReserveProposals from '../pages/Proposals/Reserve';
import AdministratorProposals from '../pages/Proposals/Administrator';
import ProposalDetails from '../pages/Proposals/Details';

// Account
import Account from '../pages/Account/index';

// Wallets
import FA2Wallets from '../pages/Wallets/fa2';
import ERC20Wallets from '../pages/Wallets/erc20';
import PolygonWallets from '../pages/Wallets/polygon';
import CreateWallet from '../pages/Wallets/Create';
import RestoreWallet from '../pages/Wallets/Restore';
import ReferenceSigner from '../pages/Wallets/ReferenceSigner';
import ConnectLedger from '../pages/Wallets/ConnectLedger';
import KeystoreUtils from '../pages/Wallets/KeystoreUtils';

// Clients
import NewClient from '../pages/Client/NewClient';
import ClientDetails from '../pages/Client/Details';
import AllClients from '../pages/Client/Clients';

// Financial Operations
import FinancialOperation from '../pages/FinancialOperation/index';
import RightsOperation from '../pages/FinancialOperation/RightsOperation';
import TransferXTZ from '../pages/FinancialOperation/TransferXTZ';
import TransferEURUS from '../pages/FinancialOperation/TransferEURUS';
import ChangeFeesManager from '../pages/FinancialOperation/ChangeFeesManager';
import ChangeRightsManager from '../pages/FinancialOperation/ChangeRightsManager';
import ChangeMultisig from '../pages/FinancialOperation/ChangeMultisig';
import ChangeMinter from '../pages/FinancialOperation/ChangeMinter';

//ETH Financial Operations
import ERC20 from '../pages/FinancialOperation/ERC20';
import Polygon from '../pages/FinancialOperation/Polygon';
import EthProposals from '../pages/Proposals/EthProposals';
import PolygonProposals from '../pages/Proposals/PolygonProposals';
import EthProposalDetails from '../pages/Proposals/EthDetails';
import PolygonProposalDetails from '../pages/Proposals/PolygonDetails';
import ETHAddGnosisSigner from '../pages/FinancialOperation/ETHAddGnosisSigner';
import ETHRemoveGnosisSigner from '../pages/FinancialOperation/ETHRemoveGnosisSigner';
import PolygonAddGnosisSigner from '../pages/FinancialOperation/PolygonAddGnosisSigner';
import PolygonRemoveGnosisSigner from '../pages/FinancialOperation/PolygonRemoveGnosisSigner';

// Activities
import Activity from '../pages/Activity/index';
import Access from '../pages/Activity/access';

// Alert
import Alert from '../pages/Alert/index';
import AlertDetails from '../pages/AlertDetails/index';

// Baker
//import Baker from "../pages/Baker/index";
//import Analytics from "../pages/Baker/Analytics";

// Request - Operator side
import AllRequests from '../pages/Requests/AllRequests';
import OpPurchase from '../pages/Requests/OpPurchase';
import OpSale from '../pages/Requests/OpSale';

// Bank
import Bank from '../pages/Bank/index';
import NewBankAccount from '../pages/Bank/NewBankAccount';
import NewBankOperation from '../pages/Bank/NewBankOperation';

// Quotes
import Quotes from '../pages/Quotes/index';

// Basefees
import BaseFee from '../pages/Client/BaseFee';

import Registrations from '../pages/Client/Registrations';
import RegistrationDetails from '../pages/Client/RegistrationDetails';
import Config from '../pages/Config';

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard, action: 'reader:all' },
  { path: '/fa2/structure', component: FA2Structure, action: 'reader:all' },
  { path: '/erc20/structure', component: ERC20Structure, action: 'reader:all' },
  {
    path: '/polygon/structure',
    component: PolygonStructure,
    action: 'reader:all',
  },
  { path: '/transactions', component: Transactions, action: 'reader:all' },
  {
    path: '/address-tx/:publicKeyHash',
    component: AddressTx,
    action: 'reader:all',
  },
  {
    path: '/transaction-details/:opHash',
    component: TransactionDetails,
    action: 'reader:all',
  },
  {
    path: '/proposals/:contract/proposal-details',
    component: ProposalDetails,
    action: 'reader:all',
  },
  {
    path: '/proposals/minter',
    component: MinterProposals,
    action: 'reader:all',
  },
  { path: '/proposals/owner', component: OwnerProposals, action: 'reader:all' },
  {
    path: '/proposals/reserve',
    component: ReserveProposals,
    action: 'reader:all',
  },
  {
    path: '/proposals/administrator',
    component: AdministratorProposals,
    action: 'reader:all',
  },
  {
    path: '/financial-fa2/:contract/:op',
    component: FinancialOperation,
    action: 'admin:financial',
  },
  {
    path: '/rights-operation/:contract/:op',
    component: RightsOperation,
    action: 'admin:financial',
  },
  {
    path: '/change-multisig/:op',
    component: ChangeMultisig,
    action: 'admin:financial',
  },
  {
    path: '/change-minter/:op',
    component: ChangeMinter,
    action: 'admin:financial',
  },
  {
    path: '/change-feesmanager',
    component: ChangeFeesManager,
    action: 'admin:financial',
  },
  {
    path: '/change-rightsmanager',
    component: ChangeRightsManager,
    action: 'admin:financial',
  },
  {
    path: '/transfer-xtz',
    component: TransferXTZ,
    action: 'operator:financial',
  },
  {
    path: '/transfer-eurus',
    component: TransferEURUS,
    action: 'operator:financial',
  },
  { path: '/fa2/wallets', component: FA2Wallets, action: 'reader:all' },
  { path: '/erc20/wallets', component: ERC20Wallets, action: 'reader:all' },
  { path: '/polygon/wallets', component: PolygonWallets, action: 'reader:all' },
  {
    path: '/create-wallet',
    component: CreateWallet,
    action: 'operator:wallet',
  },
  {
    path: '/restore-wallet',
    component: RestoreWallet,
    action: 'operator:wallet',
  },
  {
    path: '/connect-ledger',
    component: ConnectLedger,
    action: 'operator:wallet',
  },
  {
    path: '/keystore-utils',
    component: KeystoreUtils,
    action: 'operator:wallet',
  },
  {
    path: '/reference-signer',
    component: ReferenceSigner,
    action: 'operator:wallet',
  },
  { path: '/new-client', component: NewClient, action: 'operator:wallet' },
  {
    path: '/client-details/:publicKeyHash',
    component: ClientDetails,
    action: 'reader:all',
  },
  { path: '/all-clients', component: AllClients, action: 'reader:all' },
  { path: '/registrations', component: Registrations, action: 'reader:all' },
  {
    path: '/registration-details/:id',
    component: RegistrationDetails,
    action: 'reader:all',
  },
  { path: '/oca/:publicKeyHash', component: OCA },
  { path: '/extract/:extractId', component: ExtractDetails },
  { path: '/oca-user/:clientId/:uuid', component: UserTxs },
  { path: '/account', component: Account, action: 'admin:access' },
  { path: '/activity', component: Activity, action: 'admin:access' },
  { path: '/access', component: Access, action: 'admin:access' },
  {
    path: '/alert/:id',
    exact: true,
    component: AlertDetails,
    action: 'admin:access',
  },
  { path: '/alert', exact: true, component: Alert, action: 'admin:access' },
  { path: '/bank', component: Bank, action: 'reader:all' },
  { path: '/new-bank', component: NewBankAccount, action: 'operator:financial' },
  { path: '/new-bank-op', component: NewBankOperation, action: 'operator:financial' },
  { path: '/quote/:currency', component: Quotes, action: 'operator:financial' },
  { path: '/basefees', component: BaseFee, action: 'operator:financial' },

  // Schuman operator client action paths
  {
    path: '/request/all-requests',
    exact: true,
    component: AllRequests,
    action: 'operator:financial',
  },
  {
    path: '/request/op-purchase',
    exact: true,
    component: OpPurchase,
    action: 'operator:financial',
  },
  {
    path: '/request/op-sale',
    exact: true,
    component: OpSale,
    action: 'operator:financial',
  },

  //ETH ops routes
  {
    path: '/erc20',
    exact: true,
    component: ERC20,
    action: 'operator:financial',
  },
  {
    path: '/polygon',
    exact: true,
    component: Polygon,
    action: 'operator:financial',
  },
  {
    path: '/financial-erc20/:role/:op',
    exact: true,
    component: ERC20,
    action: 'operator:financial',
  },
  {
    path: '/financial-polygon/:role/:op',
    exact: true,
    component: Polygon,
    action: 'operator:financial',
  },
  {
    path: '/eth-proposals/:role',
    exact: true,
    component: EthProposals,
    action: 'operator:financial',
  },
  {
    path: '/polygon-proposals/:role',
    exact: true,
    component: PolygonProposals,
    action: 'operator:financial',
  },
  {
    path: '/eth-proposal/:role/:safeTxHash',
    exact: true,
    component: EthProposalDetails,
    action: 'operator:financial',
  },
  {
    path: '/polygon-proposal/:role/:safeTxHash',
    exact: true,
    component: PolygonProposalDetails,
    action: 'operator:financial',
  },
  {
    path: '/gnosis/add/ETHsigner/:role',
    exact: true,
    component: ETHAddGnosisSigner,
    action: 'operator:financial',
  },
  {
    path: '/gnosis/remove/ETHsigner/:role',
    exact: true,
    component: ETHRemoveGnosisSigner,
    action: 'operator:financial',
  },
  {
    path: '/gnosis/add/POLYGONsigner/:role',
    exact: true,
    component: PolygonAddGnosisSigner,
    action: 'operator:financial',
  },
  {
    path: '/gnosis/remove/POLYGONsigner/:role',
    exact: true,
    component: PolygonRemoveGnosisSigner,
    action: 'operator:financial',
  },

  // Load & display config
  { path: '/config', component: Config, action: 'admin:financial' },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: TrustBuilder },
  /* { path: '/reset-password', component: ResetPassword },
  { path: '/forgot-password', component: ForgetPwd }, */

  // Authentication Inner
/*   { path: '/pages-login', component: Login1 },
  { path: '/pages-forgot-pwd', component: ForgetPwd1 },
  { path: '/auth-lock-screen', component: LockScreen }, */
];

export { authProtectedRoutes, publicRoutes };
