import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import './datatables.scss';
import { contractProposals, dictAliases } from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import CSV from '../../components/Common/CSV';
import SVGLogo from '../../components/Common/SVGLogo.js';

class ReserveProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 1000000,
      startDate: new Date(),
      activeTab: '1',
      csvRows: [],
      transactions: {
        columns: [
          {
            label: 'Proposal ID',
            field: 'proposalId',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Amount',
            field: 'amount',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Receiver',
            field: 'receiver',
            width: 160,
          },
          {
            label: 'Approvals',
            field: 'approvals',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Rejects',
            field: 'rejects',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'Actions',
            field: 'action',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: [],
      },
      limit: false,
      loading: true,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChange.bind(this);
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        aliases: [],
      });
    }
  }

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this._asyncRequest = contractProposals('reserve')
      .then((res) => {
        if (res.status === 'SUCCESS') {
          for (let proposal of res.data) {
            const rejects = proposal.value.rejects.length;
            const accepts = proposal.value.approvals.length;
            let row = {
              proposalId: proposal.key,
              proposalType: proposal.value.operation,
              date: proposal.time,
              amount: (
                <span>
                  {parseFloat(proposal.value.amount / this.state.decimals)}{' '}
                  <SVGLogo width="13" height="13" />
                </span>
              ),
              receiver:
                this.state.aliases[proposal.value.t] &&
                this.state.aliases[proposal.value.t].name
                  ? this.state.aliases[proposal.value.t].name
                  : proposal.value.t,
              approvals: (
                <span className="badge font-size-9 text-left">
                  {proposal.value.approvals.map((approval) => (
                    <div key={approval} className="mt-1">
                      {this.state.aliases[approval] &&
                      this.state.aliases[approval].name
                        ? this.state.aliases[approval].name
                        : truncStringPortion(approval, 8, 6)}
                    </div>
                  ))}
                </span>
              ),
              rejects: (
                <span className="badge font-size-9 text-left">
                  {proposal.value.rejects.map((reject) => (
                    <div key={reject} className="mt-1">
                      {this.state.aliases[reject] &&
                      this.state.aliases[reject].name
                        ? this.state.aliases[reject].name
                        : truncStringPortion(reject, 8, 6)}
                    </div>
                  ))}
                </span>
              ),
              status: (
                <b>
                  {proposal.value.status.toString() === 'true'
                    ? 'Closed'
                    : 'Pending'}
                  <br />
                  {proposal.value.status.toString() === 'true' &&
                    rejects > 0 && (
                      <span className="badge badge-danger font-size-10">
                        Rejected
                      </span>
                    )}
                  {proposal.value.status.toString() === 'true' &&
                    accepts > rejects && (
                      <span className="badge badge-success font-size-10">
                        Accepted
                      </span>
                    )}
                  {proposal.value.status === 'false' && (
                    <span className="badge badge-warning font-size-10">
                      Open
                    </span>
                  )}
                </b>
              ),
              action:
                proposal.value.status.toString() === 'true' ? (
                  <Link
                    as="span"
                    className="badge badge-primary font-size-11"
                    style={{ cursor: 'pointer' }}
                    to={`/proposals/reserve/proposal-details?bigmap=0&contract=reserve&proposalId=${proposal.key}`}
                  >
                    View Details
                  </Link>
                ) : (
                  <Link
                    as="span"
                    className="badge badge-warning font-size-11"
                    style={{ cursor: 'pointer' }}
                    to={`/proposals/reserve/proposal-details?bigmap=0&contract=reserve&proposalId=${proposal.key}`}
                  >
                    Accept / Reject
                  </Link>
                ),
            };

            let csvRow = {
              proposalId: proposal.key,
              proposalType: proposal.value.operation,
              date: proposal.time,
              amount:
                parseFloat(proposal.value.amount / this.state.decimals) + ' €',
              receiver:
                this.state.aliases[proposal.value.t] &&
                this.state.aliases[proposal.value.t].name
                  ? this.state.aliases[proposal.value.t].name
                  : proposal.value.t,
              approvals: `${proposal.value.approvals.map((approval) =>
                this.state.aliases[approval] &&
                this.state.aliases[approval].name
                  ? this.state.aliases[approval].name
                  : approval,
              )}`,
              rejects: `${proposal.value.rejects.map((reject) =>
                this.state.aliases[reject] && this.state.aliases[reject].name
                  ? this.state.aliases[reject].name
                  : reject,
              )}`,
              status: `${
                proposal.value.status.toString() === 'true'
                  ? 'Closed'
                  : 'Pending'
              } ${
                proposal.value.status.toString() === 'true' && rejects > accepts
                  ? 'Rejected'
                  : ''
              }${
                proposal.value.status.toString() === 'true' && accepts > rejects
                  ? 'Accepted'
                  : ''
              }${proposal.value.status.toString() === 'false' ? 'Open' : ''}`,
              action: `https://${window.location.hostname}/proposals/reserve/proposal-details?bigmap=0&contract=reserve&proposalId=${proposal.key}`,
            };

            this.setState({
              transactions: {
                columns: [...this.state.transactions.columns],
                rows: [...this.state.transactions.rows, row],
              },
              csvRows: [...this.state.csvRows, csvRow],
            });
          }
          this.setState({ loading: false });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">Reserve proposals</h4>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="p-3"
                      >
                        <TabPane tabId="1" id="all-order">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.transactions}
                            className="mt-1"
                            hover
                          />
                          <CSV
                            name="Reserve_Proposals"
                            transactions={this.state.transactions}
                            csvRows={this.state.csvRows}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ReserveProposals;
