import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { unreadOps, setRead, dictAliases } from "../../../helpers/api";
import { truncStringPortion } from '../../../helpers/formatter.js';

//i18n
import { withTranslation } from 'react-i18next';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      ops: [],
      aliases: [],
      lastclear: null
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  componentDidMount = () => {
    this.processOps();

    this._interval = setInterval(() => { this.processOps() }, 10000)
    this._asyncRequest = dictAliases().then(
        res => {
            if (res.status === 'SUCCESS'){
                this.setState({aliases: res.data});
            }else{
                console.log(res.error);
            }
        }
    ).catch(error => {
        console.log(error);
    });
  }

  componentWillUnmount() {
    try {
      clearInterval(this._interval);
    } catch(e) {}
  }

  processOps = () => {
    this._asyncRequest = unreadOps().then(
            res => {
                this.setState({ops: res.data});
                if(res.data.length > 0)
                    this.setState({lastclear: res.data[0].createdAt});
            }
    ).catch(error => {
        console.log(error);
    });
  }

  viewAll = () => {
    this._asyncRequest = setRead(this.state.lastclear).then(
      res => {
            this.setState({ops: []});
      }
    ).catch(error => {
        console.log(error);
    });
  }

  render() {
    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          {this.state.ops && this.state.ops.length > 0 &&
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button" id="page-header-notifications-dropdown">
                <i className="bx bx-bell bx-tada"></i>
                <span className="badge badge-success badge-pill text-dark">{this.state.ops.length}</span>
              </DropdownToggle>
          }

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                </Col>
                <div className="col-auto">
                  <a href="#!" className="small" onClick={() => {this.viewAll()}}> Clear All</a>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {this.state.ops && this.state.ops.map(op => op.isValidated?
                  <div key={op.opHash} className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        {op.opType === "accept" ?
                            <span className="avatar-title bg-success rounded-circle font-size-16">
                              <i className="bx bx-check-circle"></i>
                            </span>
                        :
                                op.opType === "reject" ?
                                    <span className="avatar-title bg-danger rounded-circle font-size-16">
                                      <i className="bx bx-stop-circle"></i>
                                    </span>
                            :
                                op.opType === "€ Transfer" ?
                                    <span className="avatar-title bg-pink rounded-circle font-size-16">
                                      <i className="bx bx-euro"></i>
                                    </span>
                                :
                                    op.opType === "ꜩ Transfer" ?
                                        <span className="avatar-title bg-pink rounded-circle font-size-16">
                                          <i className="bx bx-coin"></i>
                                        </span>
                                    :
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                          <i className="bx bx-book-open"></i>
                                        </span>
                        }
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">
                            {op.opType}
                            {op.chain === 'ethereum'?
                              <Link to={`/eth-proposal/${op.contract}/${op.opHash}`} className="badge badge-primary font-size-11 float-right">View</Link>
                            :
                              op.proposalId?
                                  <Link to={`/proposals/${op.contract}/proposal-details?bigmap=0&contract=${op.contract}&proposalId=${op.proposalId}`} className="badge badge-primary font-size-11 float-right">View</Link>
                              :
                                  <Link to={`/transaction-details/${op.opHash}`} className="badge badge-primary font-size-11 float-right">View</Link>
                            }
                        </h6>
                        {op.chain === 'ethereum'?
                          <p className="mb-0 mt-0 font-size-10">
                              From {this.state.aliases[op.caller.toLowerCase()] && this.state.aliases[op.caller.toLowerCase()].name? this.state.aliases[op.caller.toLowerCase()].name : truncStringPortion(op.caller, 8, 6)}
                              <span className="mb-1 badge badge-success ml-2">Applied</span>
                          </p>
                        :
                          <p className="mb-0 mt-0 font-size-10">
                              From {this.state.aliases[op.caller] && this.state.aliases[op.caller].name? this.state.aliases[op.caller].name : truncStringPortion(op.caller, 8, 6)}
                              <span className="mb-1 badge badge-success ml-2">Applied</span>
                          </p>  
                        }
                        {op.chain === 'ethereum'?
                          <span className="mb-1 badge badge-dark ml-2">Ethereum chain</span>
                        :
                          <span className="mb-1 badge badge-info ml-2">Tezos chain</span>
                        }
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{op.opDescription}</p>
                          <p className="mb-0 font-size-10"><i className="mdi mdi-clock-outline"></i> {op.createdAt.replace('T', ' ').replace('Z', '') + ' GMT'} </p>
                        </div>
                      </div>
                    </div>
                  </div>
              :
                  <div key={op.opHash} className="text-reset notification-item">
                    <div className="media bg-danger">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-white text-danger rounded-circle font-size-16">
                        {op.opType === "accept" ?
                              <i className="bx bx-check-circle"></i>
                        :
                                op.opType === "reject" ?
                                    <i className="bx bx-stop-circle"></i>
                            :
                                op.opType === "€ Transfer" ?
                                    <i className="bx bx-euro"></i>
                                :
                                    op.opType === "ꜩ Transfer" ?
                                        <i className="bx bx-coin"></i>
                                    :
                                        <i className="bx bx-book-open"></i>
                        }
                        </span>
                      </div>
                      <div className="media-body bg-danger text-white">
                        <h6 className="mt-0 mb-1 text-white">
                            {op.opType}
                            {op.chain === 'ethereum'?
                                  <Link to={`/eth-proposal/${op.contract}/${op.opHash}`} className="badge badge-primary font-size-11 float-right">View</Link>
                            :
                              op.proposalId?
                                  <Link to={`/proposals/${op.contract}/proposal-details?bigmap=0&contract=${op.contract}&proposalId=${op.proposalId}`} className="badge badge-primary font-size-11 float-right">View</Link>
                              :
                                  <Link to={`/transaction-details/${op.opHash}`} className="badge badge-primary font-size-11 float-right">View</Link>
                            }
                        </h6>
                        <p className="mb-0 mt-0 font-size-10 text-white">
                            From {this.state.aliases[op.caller] && this.state.aliases[op.caller].name? this.state.aliases[op.caller].name : truncStringPortion(op.caller, 8, 6)}
                            {op.isValidated? <span className="mb-1 badge badge-success ml-2">Applied</span>: <span className="mb-1 badge badge-dark ml-2">Chain error</span>}
                        </p>
                        <div className="font-size-12 text-white">
                          <p className="mb-1">{op.opDescription}</p>
                          <p className="mb-0 font-size-10"><i className="mdi mdi-clock-outline"></i> {op.createdAt.replace('T', ' ').replace('Z', '') + ' GMT'} </p>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </SimpleBar>
            <div className="p-2 border-top">
              <div
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                onClick={() => {this.viewAll()}}
              >
                {" "}
               {this.props.t('Clear all')}{" "}
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withTranslation()(NotificationDropdown);
