import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { TezosToolkit } from '@taquito/taquito';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';

import { dictAliases, rpcNode } from '../../helpers/api';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const derivationPath = "44'/1729'/0'/0'/0'";

class ConnectLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      publicKeyHash: null,
      aliases: [],
      pending: false,
      signed: false,
      signature: null,
      message: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  signCheck = async () => {
    this.setState({ pending: true });
    const Tezos = new TezosToolkit(this.state.tezosNode);
    const transport = await TransportWebHID.create();
    const ledgerSigner = new LedgerSigner(
      transport, //required
      derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
      true, // prompt optional
      DerivationType.ED25519, // derivationType optional
    );
    Tezos.setSignerProvider(ledgerSigner);
    const sig = await Tezos.signer.sign(this.state.message);
    await transport.close();
    this.setState({
      signature: sig.prefixSig,
      signed: true,
      error: null,
      pending: false,
    });
  };

  componentDidMount = async () => {
    let resNode = await rpcNode();
    await this.setState({ tezosNode: resNode.data });
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Wallets"
              breadcrumbItem="Connect & sign with Ledger"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Connect & sign with Ledger
                    </h4>
                    <p className="text-danger"></p>
                    <>
                      <FormGroup>
                        {!this.state.signed && !this.state.pending && (
                          <>
                            <FormGroup>
                              <Label>Set message to sign</Label>
                              <input
                                type="text"
                                onChange={this.handleMessage}
                                className="form-control"
                              />
                            </FormGroup>
                            <Button
                              className="mt-2"
                              type="button"
                              color="success"
                              onClick={() => {
                                this.signCheck();
                              }}
                            >
                              Sign with Ledger
                            </Button>
                          </>
                        )}
                        {!this.state.signed && this.state.pending && (
                          <p className="text-primary mt-3">
                            Please follow further instructions from your
                            Ledger...
                          </p>
                        )}
                        {this.state.signed && !this.state.pending && (
                          <p>
                            Signature :{' '}
                            <span className="text-success">
                              {this.state.signature}
                            </span>
                          </p>
                        )}
                      </FormGroup>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ConnectLedger;
