import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { ethers } from 'ethers';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import classnames from 'classnames';
import { TezosToolkit } from '@taquito/taquito';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';

import { referenceSigner, rpcNode, fetchERC20config } from '../../helpers/api';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const descLimit = 100;
const nameLimit = 40;
const derivationPath = "44'/1729'/0'/0'/0'";

class ReferenceSigner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      description: null,
      validPhrase: false,
      descCharLeft: descLimit,
      nameCharLeft: nameLimit,
      pending: false,
      error: null,
      activeTab: '1',
      mnemonic: null,
      publicKeyHashTezos: null,
      connectedLedger: false,
      publicKeyHashEthereum: null,
      connectedEthLedger: false,
      cfgEth: {},
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateName = (event) => {
    this.setState({
      nameCharLeft: nameLimit - event.target.value.length,
      name: event.target.value,
    });
  };

  updateEthAddr = (event) => {
    this.setState({
      publicKeyHashEthereum: event.target.value,
      connectedEthLedger: event.target.value.length === 42,
    });
  };

  updateTzAddr = (event) => {
    this.setState({
      publicKeyHashTezos: event.target.value,
      connectedLedger: event.target.value.length === 36,
    });
  };

  referenceTezosSigner = () => {
    if (!this.state.connectedLedger)
      this.setState({ error: 'Ledger not connected', pending: false });
    else if (this.state.name.length < 4)
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = referenceSigner(
        this.state.publicKeyHashTezos,
        this.state.name,
        this.state.description,
        'tezos',
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            window.location = '/fa2/wallets';
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  referenceEthereumSigner = () => {
    if (!this.state.connectedEthLedger)
      this.setState({ error: 'MetaMask signer not connected', pending: false });
    else if (this.state.name.length < 4)
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = referenceSigner(
        this.state.publicKeyHashEthereum,
        this.state.name,
        this.state.description,
        'ethereum',
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            window.location = '/erc20/wallets';
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  connectLedger = async () => {
    const Tezos = new TezosToolkit(this.state.tezosNode);
    const transport = await TransportWebHID.request();
    const ledgerSigner = new LedgerSigner(
      transport, //required
      derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
      true, // prompt optional
      DerivationType.ED25519, // derivationType optional
    );
    Tezos.setProvider({ signer: ledgerSigner });
    const publicKeyHash = await ledgerSigner.publicKeyHash();
    this.setState({ publicKeyHashTezos: publicKeyHash, connectedLedger: true });
  };

  connectEthLedger = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    this.setState({
      publicKeyHashEthereum: safeOwner.address,
      connectedEthLedger: true,
    });
  };

  componentDidMount = async () => {
    let cfgEth = await fetchERC20config();
    let resNode = await rpcNode();
    await this.setState({ cfgEth: cfgEth.data, tezosNode: resNode.data });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Wallet"
              breadcrumbItem="Reference a signing wallet"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-wallet"></i> Reference a signing
                      wallet on the platform
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '1',
                            })}
                            onClick={() => {
                              this.toggleTab('1');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Tezos</p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '2',
                            })}
                            onClick={() => {
                              this.toggleTab('2');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Ethereum</p>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="tezos">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          {!this.state.connectedLedger ? (
                            <FormGroup>
                              <Label>
                                Unlock Ledger, select your wallet, click Connect
                                Ledger, and follow instructions onto your Ledger
                                device
                              </Label>{' '}
                              <br />
                              <Button
                                className="mt-2"
                                type="button"
                                color="info"
                                onClick={() => {
                                  this.connectLedger();
                                }}
                              >
                                Connect Ledger
                              </Button>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <Label>Ledger connected</Label> <br />
                              <p>{this.state.publicKeyHashTezos}</p>
                            </FormGroup>
                          )}

                          {!this.state.connectedLedger && (
                            <FormGroup>
                              <Label>Signer address</Label>

                              <Row>
                                <Col sm="8">
                                  <InputGroup className="mb-2">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="tz1.."
                                      onChange={this.updateTzAddr}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          <FormGroup>
                            <Label>Signer name</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.nameCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateName}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.name &&
                                    this.state.name.length > 3 ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-message-alt-check"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-message-alt-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a short description</Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.descCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={descLimit}
                                    onChange={this.updateDescription}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <div className="text-center mt-4">
                            {this.state.pending ? (
                              <p>Referencing wallet. please wait...</p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.referenceTezosSigner}
                              >
                                Reference Signer
                              </Button>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="tezos">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          {!this.state.connectedEthLedger ? (
                            <FormGroup>
                              <Label>
                                Unlock Ledger, select your wallet, click Connect
                                Ledger, and follow instructions onto your Ledger
                                device
                              </Label>{' '}
                              <br />
                              <Button
                                className="mt-2"
                                type="button"
                                color="dark"
                                onClick={() => {
                                  this.connectEthLedger();
                                }}
                              >
                                Connect Ledger
                              </Button>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <Label>Signer connected</Label> <br />
                              <p>{this.state.publicKeyHashEthereum}</p>
                            </FormGroup>
                          )}

                          {!this.state.connectEthLedger && (
                            <FormGroup>
                              <Label>Signer address</Label>

                              <Row>
                                <Col sm="8">
                                  <InputGroup className="mb-2">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="0x..."
                                      onChange={this.updateEthAddr}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          <FormGroup>
                            <Label>Signer name</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.nameCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateName}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.name &&
                                    this.state.name.length > 3 ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-message-alt-check"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-message-alt-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a short description</Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.descCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={descLimit}
                                    onChange={this.updateDescription}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <div className="text-center mt-4">
                            {this.state.pending ? (
                              <p>Referencing wallet. please wait...</p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.referenceEthereumSigner}
                              >
                                Reference Signer
                              </Button>
                            )}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ReferenceSigner;
