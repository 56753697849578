import React, { Component } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { RECAPTCHA_KEY } from '../../constants';
import { loginInwebo, apiError } from '../../store/actions';
import TRUSTBUILDER from '../../assets/images/inwebo.png';
import Copyright from '../../components/Common/Copyright';

class TrustBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, expired: false };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleChange = (value) => {
    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: 'true' });
  };

  handleValidSubmit(event, values) {
    this.setState({ loading: true });
    this.props.loginInwebo(values, this.props.history, this.state.value);
  }

  componentDidMount() {
    this.props.apiError('');
  }

  render() {
    const error = this.props.error
      ? JSON.parse(this.props.error.message)
      : null;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="wcard-schuman">
                  </div>
                  <CardBody className="pt-4">
                    <div className="p-2">
                      <h5 className="mb-2">Authentication</h5>
                      <hr />
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {error && <Alert color="danger">{error.err}</Alert>}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <p className="text-muted font-size-12">
                          Authentication powered by
                          <br />
                          <img
                            src={TRUSTBUILDER}
                            height={24}
                            className="ml-1 mb-1"
                            alt="TrustBuilder"
                          />
                        </p>
                        <HCaptcha
                          sitekey={RECAPTCHA_KEY}
                          onVerify={this.handleChange}
                        />
                        {this.state.loading && !error ? (
                          <React.Fragment>
                            <Loader
                              type="Puff"
                              color="#50a5f1"
                              height={50}
                              width={50}
                            />
                            <p className="mt-2 text-info">
                              Notification sent onto your TrustBuilder
                              Authenticator application.{' '}
                              <strong>
                                Set your pin code to unlock access...
                              </strong>
                            </p>
                          </React.Fragment>
                        ) : (
                          !error && (
                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                              >
                                Sign with TrustBuilder Authenticator
                              </button>
                            </div>
                          )
                        )}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <Copyright />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginInwebo, apiError })(TrustBuilder),
);
