import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { fetchPolygonconfig } from "../../helpers/api"
import PolygonLogo from '../../components/Common/PolygonLogo.js'
import axios from 'axios'

class SidebarPolygonProposals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countMinter: 0,
            countAdmin: 0,
            countOwner: 0,
            countReserve: 0,
            total: 0,
            cfgPolygon: {},
        }
    }

    componentDidMount = async () => {
        let cfgPolygon = await fetchPolygonconfig()
        await this.setState({ cfgPolygon: cfgPolygon.data })
        this.loadCounter()
        this._interval = setInterval(() => { this.loadCounter() }, 30000)
    }

    componentWillUnmount() {
        clearInterval(this._interval);
    }

    loadCounter = async () => {
        if (this.state.cfgPolygon) {
            const transactionServiceUrl = this.state.cfgPolygon.safe_tx_url
            let mtx = 0, atx = 0, otx = 0, rtx = 0;
            // console.log(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.master_minter}/multisig-transactions?executed=false`)
            try {
                let respmtx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.master_minter}/multisig-transactions?executed=false`)
                mtx = respmtx.data.count
                respmtx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.master_minter}/multisig-transactions`)
                mtx = mtx - (respmtx.data.count - respmtx.data.countUniqueNonce)
                let respatx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.administrator}/multisig-transactions?executed=false`)
                atx = respatx.data.count
                respatx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.administrator}/multisig-transactions`)
                atx = atx - (respatx.data.count - respatx.data.countUniqueNonce)
                let respotx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.owner}/multisig-transactions?executed=false`)
                otx = respotx.data.count
                respotx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.owner}/multisig-transactions`)
                otx = otx - (respotx.data.count - respotx.data.countUniqueNonce)
                let resprtx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.reserve}/multisig-transactions?executed=false`)
                rtx = resprtx.data.count
                resprtx = await axios.get(`${transactionServiceUrl}/api/v1/safes/${this.state.cfgPolygon.reserve}/multisig-transactions`)
                rtx = rtx - (resprtx.data.count - resprtx.data.countUniqueNonce)
            } catch (e) {
                console.log(e)
            }
            this.setState({ countMinter: mtx, countAdmin: atx, countOwner: otx, countReserve: rtx, total: (mtx + atx + otx + rtx) })
        }
    }

    render() {
        return (
            <li>
                <Link to="/#" className="has-arrow  waves-effect">
                    <i><PolygonLogo width="16" height="16" /></i>
                    {this.state.total > 0 &&
                        <span className="badge badge-pill badge-success float-right text-dark">{this.state.total}</span>
                    }
                    <span>Polygon Proposals</span>
                </Link>
                <ul className="sub-menu">
                    <li><Link to="/polygon-proposals/administrator">{this.state.countAdmin > 0 && <span className="badge badge-pill badge-success float-right text-dark">{this.state.countAdmin}</span>}Administrator</Link></li>
                    <li><Link to="/polygon-proposals/master_minter">{this.state.countMinter > 0 && <span className="badge badge-pill badge-success float-right text-dark">{this.state.countMinter}</span>}Minter</Link></li>
                    <li><Link to="/polygon-proposals/reserve">{this.state.countReserve > 0 && <span className="badge badge-pill badge-success float-right text-dark">{this.state.countReserve}</span>}Reserve</Link></li>
                    <li><Link to="/polygon-proposals/owner">{this.state.countOwner > 0 && <span className="badge badge-pill badge-success float-right text-dark">{this.state.countOwner}</span>}Owner</Link></li>
                </ul>
            </li>
        );
    }
}

export default SidebarPolygonProposals