import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import FA2Storage from './FA2Storage';
import ERC20Storage from './ERC20Storage';
import PolygonStorage from './PolygonStorage';
import LatestProposals from './LatestProposals';
import Clients from './Clients';
import { truncStringPortion } from '../../helpers/formatter.js';
import PopupBalance from '../PopupBalance';
import {
  getChains,
  listClients,
  contractStorage,
  dictAliases,
  erc20Storage,
  erc20Reserve,
  fa2Reserve,
  getBaseFee,
  polygonStorage,
  polygonReserve,
} from '../../helpers/api';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import EthereumLogo from '../../components/Common/EthereumLogo.js';
import PolygonLogo from '../../components/Common/PolygonLogo';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseFees: null,
      decimals: 100,
      modal: false,
      wallets: [],
      multiSigs: [],
      storage: null,
      loading: true,
      aliases: [],
      erc20: null,
      polygon: null,
      clients: null,
      fa2reserve: null,
      erc20reserve: null,
      polygonreserve: null,
      reserve: 0,
      totalSupply: 0,
      feeFaucet: 0,
      chains: [],
    };
    this.togglemodal.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  componentDidMount = async () => {
    const resp = await getChains();
    this.setState({ chains: resp.chains });
    for (let chain of resp.chains) {
      if (chain.slug === 'fa2') {
        const rs = await fa2Reserve();
        let reserve = parseFloat(this.state.reserve + rs.data.value.balance);
        const res = await contractStorage('token');
        if (res.data) {
          const totalSupply =
            this.state.totalSupply +
            parseFloat(res.data.value.totalSupply / res.data.value.decimals);
          const feeFaucet =
            this.state.feeFaucet +
            parseFloat(
              res.data.value.fees_faucet_balance
                ? res.data.value.fees_faucet_balance / res.data.value.decimals
                : 0,
            );
          this.setState({
            fa2reserve: rs?.data,
            reserve,
            storage: res.data.value,
            totalSupply,
            feeFaucet,
          });
        }
      } else if (chain.slug === 'erc20') {
        const rs = await erc20Reserve();
        let reserve = parseFloat(this.state.reserve + rs.data.value.balance);
        const res = await erc20Storage();
        const totalSupply =
          this.state.totalSupply +
          parseFloat(res.data.totalSupply / res.data.decimals);
        const feeFaucet = this.state.feeFaucet + res.data?.balance;
        this.setState({
          erc20reserve: rs?.data,
          reserve,
          erc20: res.data,
          totalSupply,
          feeFaucet,
        });
      } else if (chain.slug === 'polygon') {
        const rs = await polygonReserve();
        let reserve = parseFloat(this.state.reserve + rs.data.value.balance);
        const res = await polygonStorage();
        const totalSupply =
          this.state.totalSupply +
          parseFloat(res.data.totalSupply / res.data.decimals);
        const feeFaucet = this.state.feeFaucet + res.data?.balance;
        this.setState({
          polygonreserve: rs?.data,
          reserve,
          polygon: res.data,
          totalSupply,
          feeFaucet,
        });
      }
    }
    this.setState({ loading: false });

    this._asyncRequest = listClients()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ clients: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this._asyncRequest = getBaseFee()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ baseFees: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            ) : (
              <Row>
                <Col xl="4">
                  {this.props.activeToken === 'fa2' && (
                    <FA2Storage storage={this.state.storage} />
                  )}
                  {this.props.activeToken === 'erc20' && (
                    <ERC20Storage storage={this.state.erc20} />
                  )}
                  {this.props.activeToken === 'polygon' && (
                    <PolygonStorage storage={this.state.polygon} />
                  )}
                  <Clients clients={this.state.clients} />
                </Col>
                <Col xl="8">
                  <Row>
                    <Col md="6">
                      <PopupBalance totalSupply={this.state.totalSupply} />
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                Global Circulating supply
                              </p>
                              <h4 className="mb-0">
                                <SVGLogo />
                                <span className="ml-2">
                                  {this.state.totalSupply.toLocaleString('fr')}
                                </span>
                              </h4>
                              <hr />
                              {this.state.storage && (
                                <div>
                                  <span>
                                    <TezosLogo width="12" height="12" />{' '}
                                    {this.state.storage.totalSupply
                                      ? parseFloat(
                                          this.state.storage.totalSupply /
                                            this.state.storage.decimals,
                                        ).toLocaleString('fr')
                                      : 0}{' '}
                                    FA2
                                  </span>
                                </div>
                              )}
                              {this.state.erc20 && (
                                <div>
                                  <span>
                                    <EthereumLogo width="12" height="12" />{' '}
                                    {this.state.erc20.totalSupply
                                      ? parseFloat(
                                          this.state.erc20.totalSupply /
                                            this.state.erc20.decimals,
                                        ).toLocaleString('fr')
                                      : 0}{' '}
                                    ERC20
                                  </span>
                                </div>
                              )}
                              {this.state.polygon && (
                                <div>
                                  <span>
                                    <PolygonLogo width="12" height="12" />{' '}
                                    {this.state.polygon.totalSupply
                                      ? parseFloat(
                                          this.state.polygon.totalSupply /
                                            this.state.polygon.decimals,
                                        ).toLocaleString('fr')
                                      : 0}{' '}
                                    POLYGON ERC20
                                  </span>
                                </div>
                              )}
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                              <span
                                className="avatar-title bg-white text-primary"
                                style={{ paddingLeft: '6px' }}
                              >
                                <SVGLogo width="70" height="70" />
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>

                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                Reserve wallets
                              </p>
                              <h4 className="mb-0">
                                <SVGLogo />{' '}
                                {this.state.reserve.toLocaleString('fr')}
                              </h4>
                              <hr />
                              {this.state.fa2reserve && (
                                <div>
                                  <span>
                                    <TezosLogo width="12" height="12" />{' '}
                                    {parseFloat(
                                      this.state.fa2reserve.value.balance,
                                    ).toLocaleString('fr')}{' '}
                                    FA2
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.fa2reserve.publicKeyHash,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                              {this.state.erc20reserve && (
                                <div>
                                  <span>
                                    <EthereumLogo width="12" height="12" />{' '}
                                    {parseFloat(
                                      this.state.erc20reserve.value.balance,
                                    ).toLocaleString('fr')}{' '}
                                    ERC20
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.erc20reserve.publicKeyHash,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                              {this.state.polygonreserve && (
                                <div>
                                  <span>
                                    <PolygonLogo width="12" height="12" />{' '}
                                    {parseFloat(
                                      this.state.polygonreserve.value.balance,
                                    ).toLocaleString('fr')}{' '}
                                    POLYGON ERC20
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.polygonreserve.publicKeyHash,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                              <span className="avatar-title">
                                <i className={`bx bx-wallet font-size-24`}></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>

                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                Fees faucet
                              </p>
                              <h4 className="mb-0">
                                <SVGLogo />
                                <span className="ml-1">
                                  {parseFloat(
                                    this.state.feeFaucet,
                                  ).toLocaleString('fr')}
                                </span>
                              </h4>
                              <hr />
                              {this.state.storage && (
                                <div>
                                  <span>
                                    <TezosLogo width="12" height="12" />{' '}
                                    {this.state.storage.fees_faucet_balance > 0
                                      ? parseFloat(
                                          this.state.storage
                                            .fees_faucet_balance /
                                            this.state.decimals,
                                        ).toLocaleString('fr')
                                      : 0}{' '}
                                    FA2
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.storage.fees_faucet,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                              {this.state.erc20 && (
                                <div>
                                  <span>
                                    <EthereumLogo width="12" height="12" />{' '}
                                    {this.state.erc20.balance
                                      ? this.state.erc20.balance.toLocaleString(
                                          'fr',
                                        )
                                      : 0}{' '}
                                    ERC20
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.erc20.feeFaucet,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                              {this.state.polygon && (
                                <div>
                                  <span>
                                    <PolygonLogo width="12" height="12" />{' '}
                                    {this.state.polygon.balance
                                      ? this.state.polygon.balance.toLocaleString(
                                          'fr',
                                        )
                                      : 0}{' '}
                                    POLYGON ERC20
                                    <small className="ml-2">
                                      (
                                      {truncStringPortion(
                                        this.state.polygon.feeFaucet,
                                        6,
                                        7,
                                      )}
                                      )
                                    </small>
                                  </span>
                                </div>
                              )}
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                              <span className="avatar-title">
                                <i
                                  className={`bx bx-donate-blood font-size-24`}
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      {this.props.activeToken === 'fa2' && this.state.storage && (
                        <Card>
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="font-weight-medium">
                                  FA2 Fees manager metrics
                                </p>
                                <div className="mb-0">
                                  Transfer:{' '}
                                  <b>
                                    {this.state.storage.fees
                                      ? this.state.storage.fees.gasFee / 100
                                      : '-'}
                                    %
                                  </b>{' '}
                                  <small className="mr-1">of amount</small>|
                                  Gasless transfer:{' '}
                                  <b>
                                    {this.state.storage.fees
                                      ? this.state.storage.fees.gaslessFee / 100
                                      : '-'}
                                    %
                                  </b>{' '}
                                  <small className="mr-1">of amount</small>
                                  <hr />
                                  <span
                                    className={`font-size-11 font-weight-bold badge badge-${
                                      this.state.storage.fees &&
                                      parseFloat(
                                        this.state.storage.euro_change * 0.003,
                                      ) >
                                        parseFloat(
                                          this.state.storage.fees.threshold /
                                            this.state.decimals,
                                        )
                                        ? 'danger'
                                        : 'success'
                                    }`}
                                  >
                                    €
                                    {this.state.storage.fees
                                      ? this.state.storage.fees.threshold /
                                        this.state.decimals
                                      : '-'}
                                  </span>
                                  <small className="ml-1">
                                    min. amount for gasless transfer
                                  </small>
                                  <br />
                                  <b>
                                    Tx ~0.003 XTZ = €
                                    {this.state.storage.euro_change
                                      ? parseFloat(
                                          this.state.storage.euro_change *
                                            0.003,
                                        ).toFixed(3)
                                      : '-'}
                                  </b>{' '}
                                  <small className="ml-1">
                                    (1 XTZ = €
                                    {this.state.storage.euro_change
                                      ? this.state.storage.euro_change.toFixed(
                                          3,
                                        )
                                      : '-'}
                                    )
                                  </small>
                                  <hr />
                                  <span
                                    className={`font-size-11 font-weight-bold badge badge-${
                                      this.state.storage.fees &&
                                      parseFloat(
                                        this.state.storage.euro_change * 0.0195,
                                      ) >
                                        parseFloat(
                                          this.state.storage.fees.storageFee /
                                            this.state.decimals,
                                        )
                                        ? 'danger'
                                        : 'success'
                                    }`}
                                  >
                                    €
                                    {this.state.storage.fees
                                      ? this.state.storage.fees.storageFee /
                                        this.state.decimals
                                      : '-'}
                                  </span>
                                  <small className="ml-1">
                                    storage amount for new account
                                  </small>
                                  <br />
                                  <b>
                                    Tx ~0.0195 XTZ = €
                                    {this.state.storage.euro_change
                                      ? parseFloat(
                                          this.state.storage.euro_change *
                                            0.0195,
                                        ).toFixed(3)
                                      : '-'}
                                  </b>{' '}
                                  <small className="ml-1">
                                    (1 XTZ = €
                                    {this.state.storage.euro_change
                                      ? this.state.storage.euro_change.toFixed(
                                          3,
                                        )
                                      : '-'}
                                    )
                                  </small>
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      )}
                      {this.props.activeToken === 'erc20' && (
                        <Card>
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="font-weight-medium">
                                  ERC20 Fees manager metrics
                                </p>
                                <div className="mb-0">
                                  Transfer:{' '}
                                  <b>
                                    {this.state.erc20.txFeeRate
                                      ? this.state.erc20.txFeeRate / 10000
                                      : '-'}
                                    %
                                  </b>{' '}
                                  <small className="mr-1">of amount</small>
                                  <hr />
                                  <span
                                    className={`font-size-11 font-weight-bold badge badge-success`}
                                  >
                                    €
                                    {this.state.erc20.gaslessBaseFee
                                      ? this.state.erc20.gaslessBaseFee
                                      : '-'}
                                  </span>
                                  <small className="ml-1">
                                    min. amount for gasless transfer
                                  </small>
                                  <hr />
                                  {this.state.erc20.estimation && (
                                    <b>
                                      {this.state.erc20.estimation.wei} wei = €
                                      {parseFloat(
                                        this.state.erc20.estimation.eur,
                                      ).toFixed(2)}
                                    </b>
                                  )}
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      )}
                      {this.props.activeToken === 'polygon' && (
                        <Card>
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="font-weight-medium">
                                  POLYGON Fees manager metrics
                                </p>
                                <div className="mb-0">
                                  Transfer:{' '}
                                  <b>
                                    {this.state.polygon.txFeeRate
                                      ? this.state.polygon.txFeeRate / 10000
                                      : '-'}
                                    %
                                  </b>{' '}
                                  <small className="mr-1">of amount</small>
                                  <hr />
                                  <span
                                    className={`font-size-11 font-weight-bold badge badge-success`}
                                  >
                                    €
                                    {this.state.polygon.gaslessBaseFee
                                      ? this.state.polygon.gaslessBaseFee
                                      : '-'}
                                  </span>
                                  <small className="ml-1">
                                    min. amount for gasless transfer
                                  </small>
                                  <hr />
                                  {this.state.polygon.estimation && (
                                    <b>
                                      {this.state.polygon.estimation.wei} wei =
                                      €
                                      {parseFloat(
                                        this.state.polygon.estimation.eur,
                                      ).toFixed(2)}
                                    </b>
                                  )}
                                </div>
                              </Media>
                            </Media>
                          </CardBody>
                        </Card>
                      )}
                      {this.props.activeToken === 'fa2' && (
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Media>
                              <Media body>
                                <p className="font-weight-medium">
                                  FA2 Gas fees
                                </p>
                                <h4 className="mb-0">
                                  €{' '}
                                  {this.state.baseFees &&
                                    this.state.baseFees.map(
                                      (basefee) =>
                                        basefee.chain === 'tezos' &&
                                        basefee.euro,
                                    )}
                                </h4>
                                <hr />
                                {this.state.storage && <span>
                                  <small className="ml-1">
                                    (gas Mint / Burn = €
                                    {this.state.storage.euro_change
                                      ? parseFloat(
                                          this.state.storage.euro_change * 0.01,
                                        ).toFixed(3)
                                      : '-'}
                                    )
                                  </small>
                                  <Link
                                    to="/basefees"
                                    className="btn btn-primary waves-effect waves-light btn-sm ml-4"
                                  >
                                    Click to edit{' '}
                                    <i className="mdi mdi-arrow-right ml-1"></i>
                                  </Link>
                                </span>}
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                                {this.state.baseFees &&
                                  this.state.baseFees.map(
                                    (basefee, i) =>
                                      basefee.chain === 'tezos' && (
                                        <span
                                          key={i}
                                          className={`avatar-title bg-${
                                            this.state.storage.euro_change &&
                                            parseFloat(
                                              this.state.storage.euro_change *
                                                0.01,
                                            ) > basefee.euro
                                              ? 'danger'
                                              : 'success'
                                          }`}
                                        >
                                          <i
                                            className={`bx bxs-hot font-size-24`}
                                          ></i>
                                        </span>
                                      ),
                                  )}
                              </div>
                            </Media>
                          </CardBody>
                        </Card>
                      )}
                      {this.props.activeToken === 'erc20' &&
                        this.state.erc20 && (
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="font-weight-medium">
                                    ERC20 Gas fees
                                  </p>
                                  <h4 className="mb-0">
                                    €{' '}
                                    {this.state.baseFees &&
                                      this.state.baseFees.map(
                                        (basefee) =>
                                          basefee.chain === 'ethereum' &&
                                          basefee.euro,
                                      )}
                                  </h4>
                                  <hr />
                                  <span>
                                    <small className="ml-1">
                                      (gas Mint / Burn = €
                                      {this.state.erc20.estimation
                                        ? parseFloat(
                                            this.state.erc20.estimation.eur *
                                              1.2,
                                          ).toFixed(3)
                                        : '-'}
                                      )
                                    </small>
                                    <Link
                                      to="/basefees"
                                      className="btn btn-primary waves-effect waves-light btn-sm ml-4"
                                    >
                                      Click to edit{' '}
                                      <i className="mdi mdi-arrow-right ml-1"></i>
                                    </Link>
                                  </span>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                                  {this.state.baseFees &&
                                    this.state.baseFees.map(
                                      (basefee, i) =>
                                        basefee.chain === 'ethereum' && (
                                          <span
                                            key={i}
                                            className={`avatar-title bg-${
                                              this.state.erc20.estimation &&
                                              parseFloat(
                                                this.state.erc20.estimation
                                                  .eur * 1.2,
                                              ).toFixed(3) > basefee.euro
                                                ? 'danger'
                                                : 'success'
                                            }`}
                                          >
                                            <i
                                              className={`bx bxs-hot font-size-24`}
                                            ></i>
                                          </span>
                                        ),
                                    )}
                                </div>
                              </Media>
                            </CardBody>
                          </Card>
                        )}
                      {this.props.activeToken === 'polygon' &&
                        this.state.polygon && (
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="font-weight-medium">
                                    POLYGON Gas fees
                                  </p>
                                  <h4 className="mb-0">
                                    €{' '}
                                    {this.state.baseFees &&
                                      this.state.baseFees.map(
                                        (basefee) =>
                                          basefee.chain === 'polygon' &&
                                          basefee.euro,
                                      )}
                                  </h4>
                                  <hr />
                                  <span>
                                    <small className="ml-1">
                                      (gas Mint / Burn = €
                                      {this.state.polygon.estimation
                                        ? parseFloat(
                                            this.state.polygon.estimation.eur *
                                              1.2,
                                          ).toFixed(3)
                                        : '-'}
                                      )
                                    </small>
                                    <Link
                                      to="/basefees"
                                      className="btn btn-primary waves-effect waves-light btn-sm ml-4"
                                    >
                                      Click to edit{' '}
                                      <i className="mdi mdi-arrow-right ml-1"></i>
                                    </Link>
                                  </span>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-top">
                                  {this.state.baseFees &&
                                    this.state.baseFees.map(
                                      (basefee, i) =>
                                        basefee.chain === 'polygon' && (
                                          <span
                                            key={i}
                                            className={`avatar-title bg-${
                                              this.state.polygon.estimation &&
                                              parseFloat(
                                                this.state.polygon.estimation
                                                  .eur * 1.2,
                                              ).toFixed(3) > basefee.euro
                                                ? 'danger'
                                                : 'success'
                                            }`}
                                          >
                                            <i
                                              className={`bx bxs-hot font-size-24`}
                                            ></i>
                                          </span>
                                        ),
                                    )}
                                </div>
                              </Media>
                            </CardBody>
                          </Card>
                        )}
                      <LatestProposals />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { activeToken } = state.Token;
  return { activeToken };
};

export default connect(mapStatetoProps)(withTranslation()(Dashboard));
