import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Media,
  Button,
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Can from '../../components/Can';
import TezosLogo from '../../components/Common/TezosLogo.js';
import EthereumLogo from '../../components/Common/EthereumLogo.js';
import {
  registrationDetails,
  acceptCompany,
  rejectCompany,
} from '../../helpers/api';

class RegistrationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laoding: true,
      company: {},
      owners: [],
      processing: false,
    };
  }

  loadRegistration = async () => {
    this.setState({ loading: true });
    const res = await registrationDetails(this.props.match.params.id);
    this.setState({ company: res.company, owners: res.owners, loading: false });
  };

  componentDidMount = () => {
    this.loadRegistration();
  };

  openLink = (link, title, pdf) => {
    var newWindow = window.open();
    let html = `<html><head><title>${title}</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head><body>`;
    if (pdf) {
      html += `<embed width="100%" height="100%" src="${link}" type="application/pdf" />`;
    } else
      html += `<img src="${link}" style={max-width: 100vw; max-height: 100vh}/>`;
    html += '</body>';
    html += '</html>';
    setTimeout(() => {
      newWindow.document.write(html);
    }, 0);
  };

  accept = async () => {
    if (
      window.confirm(
        `Accept & validate ${this.state.company.companyName} as new client ?`,
      )
    ) {
      this.setState({ processing: true });
      await acceptCompany(this.state.company.id);
      window.location.reload();
    }
  };

  reject = async () => {
    if (
      window.confirm(`Reject ${this.state.company.companyName} as new client ?`)
    ) {
      this.setState({ processing: true });
      await rejectCompany(this.state.company.id);
      window.location.reload();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.loading ? (
              <Loader type="Puff" color="#545454" height={50} width={50} />
            ) : (
              <Row>
                <Col xl="6">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-2">
                      <Row>
                        <Col sm="12">
                          {this.state.company.status === 'REJECTED' ? (
                            <span className="badge badge-danger font-size-12">
                              Rejected
                            </span>
                          ) : this.state.company.status === 'VALIDATED' ? (
                            <span className="badge badge-success font-size-12">
                              Approved
                            </span>
                          ) : (
                            <span className="badge badge-warning font-size-12 float-right">
                              Pending
                            </span>
                          )}
                          <h3 className="text-truncate mt-4 p-relative">
                            {this.state.company.companyName}
                          </h3>
                          <p className="text-muted mb-2 text-truncate p-relative font-size-11">
                            Registered on {this.state.company.createdAt}
                          </p>
                          <hr />
                          <p className="text-muted mb-2 text-truncate p-relative">
                            Registration n°{' '}
                            {this.state.company.registrationNumber}
                          </p>
                          <p className="text-muted mb-2 text-truncate p-relative">
                            Legal form {this.state.company.legalForm}
                          </p>
                          <hr />
                          <p className="text-muted mb-2 text-truncate p-relative">
                            <strong>Incorporation document</strong>
                            <br />
                            {this.state.company.incorporation && (
                              <a
                                href="#incorporation"
                                onClick={() =>
                                  this.openLink(
                                    `data:${
                                      this.state.company.incorporation
                                        .fileExtension === 'pdf'
                                        ? 'application'
                                        : 'image'
                                    }/${
                                      this.state.company.incorporation
                                        .fileExtension
                                    };base64,${
                                      this.state.company.incorporation.fileData
                                    }`,
                                    `${this.state.company.companyName}_incorporation.${this.state.company.incorporation.fileExtension}`,
                                    this.state.company.incorporation
                                      .fileExtension === 'pdf',
                                  )
                                }
                              >
                                {`${this.state.company.companyName}_incorporation.${this.state.company.incorporation.fileExtension}`}
                              </a>
                            )}
                          </p>
                          <hr />
                          <p className="text-muted mb-2 text-truncate p-relative">
                            <strong>Kbis document</strong>
                            <br />
                            {this.state.company.kbis && (
                              <a
                                href="#kbis"
                                onClick={() =>
                                  this.openLink(
                                    `data:${
                                      this.state.company.kbis.fileExtension ===
                                      'pdf'
                                        ? 'application'
                                        : 'image'
                                    }/${
                                      this.state.company.kbis.fileExtension
                                    };base64,${
                                      this.state.company.kbis.fileData
                                    }`,
                                    `${this.state.company.companyName}_kbis.${this.state.company.kbis.fileExtension}`,
                                    this.state.company.kbis.fileExtension ===
                                      'pdf',
                                  )
                                }
                              >
                                {`${this.state.company.companyName}_kbis.${this.state.company.kbis.fileExtension}`}
                              </a>
                            )}
                          </p>
                          <hr />
                          <p className="text-muted mb-2 text-truncate p-relative">
                            Business sector: {this.state.company.businessSector}
                          </p>
                          <p className="text-muted mb-0 text-truncate p-relative">
                            Full address: {this.state.company.fullAddress}
                          </p>
                        </Col>
                        <Col sm="12">
                          <div className="pt-4">
                            <Row>
                              <Col xs="12">
                                <h5 className="font-size-15">
                                  Business purpose
                                </h5>
                                <p className="text-muted mb-0 font-size-12 p-relative">
                                  {this.state.company.purpose}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col sm="12">
                          <div className="pt-4">
                            <Row>
                              <Col xs="12">
                                <h5 className="font-size-15">Contact email</h5>
                                <p className="text-muted mb-0 font-size-12 p-relative">
                                  {this.state.company.contactMail}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    {this.state.company.status === 'PENDING' && (
                      <Can
                        role={this.props.user.role}
                        perform="operator:financial"
                        yes={() => {
                          return this.state.processing ? (
                            <CardFooter>
                              <Loader
                                type="Puff"
                                color="#50a5f1"
                                height={20}
                                width={20}
                              />
                              <p className="mt-2 text-info">
                                Notification sent onto your TrustBuilder Authenticator
                                application.{' '}
                                <strong>
                                  Set your pin code to complete operation...
                                </strong>
                              </p>
                            </CardFooter>
                          ) : (
                            <CardFooter>
                              <Row>
                                <Col sm="6">
                                  <Button color="success" onClick={this.accept}>
                                    Accept & register as client
                                  </Button>
                                </Col>
                                <Col sm="6">
                                  <Button color="danger" onClick={this.reject}>
                                    Reject application
                                  </Button>
                                </Col>
                              </Row>
                            </CardFooter>
                          );
                        }}
                      />
                    )}
                  </Card>
                  {this.state.company.tezosPkh && (
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              Tezos FA2 Wallet
                              <br />
                              <small>{this.state.company.tezosPkh}</small>
                            </p>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span
                              className="avatar-title"
                              style={{ paddingLeft: '6px' }}
                            >
                              <TezosLogo width="20" height="20" />
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  )}
                  {this.state.company.ethPkh && (
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted font-weight-medium">
                              ERC20 Wallet
                              <br />
                              <small>{this.state.company.ethPkh}</small>
                            </p>
                          </Media>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span
                              className="avatar-title"
                              style={{ paddingLeft: '6px' }}
                            >
                              <EthereumLogo width="20" height="20" />
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  )}
                </Col>
                <Col xl="6">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-2">
                      <Row>
                        <Col sm="12">
                          <h5 className="text-truncate mt-4 p-relative">
                            Owners / Representatives
                          </h5>
                          <p className="text-muted mb-3 text-truncate p-relative font-size-11">
                            <strong>{this.state.owners.length}</strong>{' '}
                            {this.state.owners.length > 1
                              ? 'individuals'
                              : 'individual'}{' '}
                            referenced
                          </p>
                          {this.state.owners.map((owner, i) => (
                            <Card key={i} style={{ border: '1px solid #eee' }}>
                              <CardTitle className="pt-3 px-4">
                                #{i + 1} {owner.firstname} {owner.lastname}{' '}
                                <small>
                                  born {owner.birthdate} at {owner.birthcity} -{' '}
                                  {owner.birthcountry}
                                </small>
                              </CardTitle>
                              <CardBody className="pt-0 mt-0">
                                {owner.isHolder && (
                                  <span className="d-block ml-2">
                                    Hold more than 25% of the capital and/or
                                    voting rights (UBO{' '}
                                    <small>Ultimate Beneficial Owner</small>)
                                  </span>
                                )}
                                {owner.isController && (
                                  <span className="d-block ml-2">
                                    Exercise control over the company
                                  </span>
                                )}
                                {owner.isLegal && (
                                  <span className="d-block ml-2">
                                    Legal representative
                                  </span>
                                )}
                              </CardBody>
                              <CardFooter>
                                {owner.idDoc && (
                                  <p className="text-muted mb-2 text-truncate p-relative">
                                    <strong>Identification document</strong>
                                    <br />
                                    <a
                                      href="#incorporation"
                                      onClick={() =>
                                        this.openLink(
                                          `data:${
                                            owner.idDoc.fileExtension === 'pdf'
                                              ? 'application'
                                              : 'image'
                                          }/${
                                            owner.idDoc.fileExtension
                                          };base64,${owner.idDoc.fileData}`,
                                          `${owner.firstname}-${owner.lastname}_id.${owner.idDoc.fileExtension}`,
                                          owner.idDoc.fileExtension === 'pdf',
                                        )
                                      }
                                    >
                                      {`${owner.firstname}-${owner.lastname}_id.${owner.idDoc.fileExtension}`}
                                    </a>
                                  </p>
                                )}
                                {owner.uboDoc && (
                                  <p className="text-muted mb-2 text-truncate p-relative">
                                    <strong>UBO document</strong>
                                    <br />
                                    <a
                                      href="#incorporation"
                                      onClick={() =>
                                        this.openLink(
                                          `data:${
                                            owner.uboDoc.fileExtension === 'pdf'
                                              ? 'application'
                                              : 'image'
                                          }/${
                                            owner.uboDoc.fileExtension
                                          };base64,${owner.uboDoc.fileData}`,
                                          `${owner.firstname}-${owner.lastname}_ubo.${owner.uboDoc.fileExtension}`,
                                          owner.uboDoc.fileExtension === 'pdf',
                                        )
                                      }
                                    >
                                      {`${owner.firstname}-${owner.lastname}_ubo.${owner.uboDoc.fileExtension}`}
                                    </a>
                                  </p>
                                )}
                              </CardFooter>
                            </Card>
                          ))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default withRouter(
  connect(mapStatetoProps, null)(withTranslation()(RegistrationDetails)),
);
