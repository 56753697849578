import React, { Component } from 'react';

import { updtClientLogo } from '../../../../helpers/api';

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      fileSize: 0,
      fileExtension: 'jpg',
      fileData: null,
    };
    this.hiddenFileInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({ checked: !this.state.checked });
  }

  handleUpload = () => {
    this.hiddenFileInput.current.click();
  };

  onFileHandler = async (event) => {
    const reader = new FileReader();
    this.setState({
      processing: true,
      error: null,
      success: false,
      info: null,
    });
    const extension =
      event.target.files[0] &&
      event.target.files[0].name.split('.').pop().toLowerCase();
    if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
      this.setState({ processing: false, error: 'Invalid image file' });
    } else if (event.target.files[0].size > 12000000) {
      this.setState({ processing: false, error: 'Image exceeding 10Mo' });
    } else {
      this.setState({
        fileSize: event.target.files[0].size,
        fileExtension: extension,
      });
      reader.onloadend = () => this.processFile(reader);
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  processFile = async (reader) => {
    const buffer = Buffer.from(reader.result);
    const base64data = buffer.toString('base64');
    this.setState({ processing: false, fileData: base64data });
    this._asyncRequest = updtClientLogo(this.props.publicKeyHash, base64data)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.props.loadClient();
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString(), pending: false });
      });
  };

  render() {
    return (
      <React.Fragment>
        <span className="editor font-size-18" onClick={this.handleUpload}>
          <i className="bx bx-edit-alt"></i>
        </span>
        <input
          className="d-none"
          type="file"
          ref={this.hiddenFileInput}
          accept="image/x-png,image/gif,image/jpeg"
          onChange={(e) => this.onFileHandler(e)}
        />
      </React.Fragment>
    );
  }
}

export default Logo;
