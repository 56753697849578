import React, { useState } from 'react'

import { Modal, Alert, UncontrolledTooltip } from "reactstrap";
import { AvForm } from 'availity-reactstrap-validation';
import { updateActivation } from '../../helpers/api'

const UpdateActivation = ({ user }) => {
    const [open, setOpen] = useState(false)

    const [step, setStep] = useState('FORM')

    const [error, setError] = useState('')

    const handleSubmit = async (evt, values) => {
        setError('')

        let res = await updateActivation(user.id)

        if (res.status === 'SUCCESS') {
            setStep('SUCCESS')
        } else {
            setError(res.error)
        }
    }

    return (
        <React.Fragment>
            <button className="btn btn-lg" id={"project" + user.id} onClick={() => setOpen(true)}>
                <i className="bx bx-wrench"></i>
                <UncontrolledTooltip placement="top" target={"project" + user.id}>
                    Resent enrollment code
                </UncontrolledTooltip>
            </button>
            <Modal
                isOpen={open}
                toggle={() => setOpen(prev => !prev)}
            >
                <AvForm className="form-horizontal" onValidSubmit={handleSubmit} log>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">New TrustBuilder activation code</h5>
                        <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {step === 'FORM' && (
                            <React.Fragment>
                                {error !== '' ? <Alert color="danger">{error}</Alert> : null}
                                <p>If you click the send button, the TrustBuilder activaton code will be reset and the old configuration will be expired.</p>
                                <button className="btn btn-block btn-warning waves-effect waves-light" type="submit">Send new activation code</button>
                            </React.Fragment>
                        )}

                        {step === 'SUCCESS' && (
                            <React.Fragment>
                                <h5>Activation code successfully resent</h5>
                                <p>An email to install TrustBuilder Authenticator mobile app with its activation code has been sent to the user</p>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                        >Close</button>
                    </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateActivation
