import * as React from 'react';

function SVGLogo(props) {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      width={props.width || "20"} height={props.height || "20"} {...props}
      viewBox="0 0 43 43"
    >
      <g>
        <path
          d="M43,21.5c0,7.438-4.305,13.872-10.545,16.99c4.764-3.82,7.879-10.004,7.879-16.99c0-6.987-3.115-13.17-7.879-16.99
		C38.695,7.628,43,14.063,43,21.5z M38,21.5c0-10.477-8.521-19-19-19c-10.477,0-19,8.523-19,19c0,10.477,8.523,19,19,19
		C29.479,40.5,38,31.977,38,21.5z M33,21.5c0,7.721-6.277,14-14,14c-7.719,0-14-6.279-14-14c0-7.72,6.281-14,14-14
		C26.721,7.5,33,13.78,33,21.5z M27.715,24.246h-3.65l0.025,0.855c0.041,1.246-0.229,2.068-0.799,2.473
		c-0.531,0.375-1.707,0.562-3.523,0.562c-2.043,0-3.264-0.337-3.668-1.012c-0.205-0.347-0.359-1.039-0.463-2.078h5.506l0.352-2.358
		H15.51c-0.02-0.686-0.026-1.376-0.026-2.081v-0.281h6.363l0.352-2.359h-6.616c0.104-1.171,0.301-1.929,0.592-2.276
		c0.455-0.552,1.717-0.829,3.774-0.829c1.537,0,2.523,0.123,2.966,0.365c0.655,0.366,0.983,1.223,0.983,2.571v0.491h3.65
		l-0.014-0.435c-0.084-2.509-0.547-4.139-1.379-4.888c-0.908-0.815-2.734-1.223-5.477-1.223c-3.576,0-5.949,0.496-7.121,1.489
		c-0.975,0.824-1.547,2.401-1.713,4.733h-1.209l-0.353,2.359h1.505c0.01,0.871,0.024,1.656,0.057,2.357h-1.209l-0.353,2.36h1.728
		c0.219,2.181,0.576,3.633,1.082,4.354c0.873,1.234,3.104,1.854,6.699,1.854c3.082,0,5.092-0.305,6.026-0.914
		c1.263-0.814,1.896-2.626,1.896-5.436L27.715,24.246L27.715,24.246z"
        />
      </g>
    </svg>
  );
}

export default SVGLogo;
