import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { Modal, FormGroup, Label } from 'reactstrap';

class LoadingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          size="md"
          isOpen={this.props.pending && this.props.passActive === 'platform'}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0 text-danger" id="myLargeModalLabel">
              DO NOT REFRESH
            </h5>
          </div>
          <div className="modal-body">
            <FormGroup>
              <Label>Processing</Label>
              <p className="text-muted mb-2 text-break">
                The new Multisig {this.props.contract} is being{' '}
                <b>published and proposed</b>.<br />
                It may take <b>up to 1 minute</b>.
              </p>
              <Loader type="Puff" color="#545454" height={50} width={50} />
              <p className="text-muted mt-2 text-break">Please wait...</p>
            </FormGroup>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LoadingModal;
