import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import { updtClientFees, feesClient } from '../../../helpers/api';

class Fees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeModel: null,
      commission: false,
      subscription: false,
      mint: null,
      burn: null,
      euroFees: null,
      cryptoFees: null,
      baseEuro: null,
      baseCrypto: null,
      revenues: null,
      levelFields: {},
      error: null,
      pending: false,
    };
  }

  handleMint = (e) => {
    this.setState({ mint: e.target.value });
  };

  handleBurn = (e) => {
    this.setState({ burn: e.target.value });
  };

  handleEuroFees = (e) => {
    this.setState({ euroFees: e.target.value });
  };

  handleCryptoFees = (e) => {
    this.setState({ cryptoFees: e.target.value });
  };

  handleBaseEuro = (e) => {
    this.setState({ baseEuro: e.target.value });
  };

  handleBaseCrypto = (e) => {
    this.setState({ baseCrypto: e.target.value });
  };

  addLevelField = () => {
    let levelFields = this.state.levelFields;
    levelFields[Math.floor(Math.random() * (10000 - 0 + 1))] = {
      level: null,
      price: null,
    };
    this.setState({ levelFields: levelFields });
  };

  deleteLevelField = (key) => {
    let levelFields = this.state.levelFields;
    delete levelFields[key];
    this.setState({ levelFields: levelFields });
  };

  handleRevenueLevel = (event, key) => {
    let levelFields = this.state.levelFields;
    levelFields[key].level = event.target.value;
  };

  handleRevenuePrice = (event, key) => {
    let levelFields = this.state.levelFields;
    levelFields[key].price = event.target.value;
  };

  updateFee = async () => {
    if (
      (this.state.euroFees &&
        this.state.cryptoFees &&
        this.state.baseEuro &&
        this.state.baseCrypto) ||
      (!this.state.euroFees &&
        !this.state.cryptoFees &&
        !this.state.baseEuro &&
        !this.state.baseCrypto)
    ) {
      let revenues = [];
      for (let key of Object.keys(this.state.levelFields)) {
        revenues.push(this.state.levelFields[key]);
      }
      await updtClientFees(
        this.props.publicKeyHash,
        this.state.mint,
        this.state.burn,
        this.state.euroFees,
        this.state.cryptoFees,
        this.state.baseEuro,
        this.state.baseCrypto,
        revenues,
        Date.now(),
        null,
      );
      this.loadFees();
      this.props.toggleFeesModel();
    } else {
      this.setState({ pending: false, error: 'Invalid set of parameters' });
    }
  };

  loadFees = async () => {
    this.setState({ pending: true });
    const res = await feesClient(this.props.publicKeyHash);
    const feeModels = res.data;
    this.setState({ feeModels: feeModels, pending: false });
  };

  componentDidMount = () => {
    this.loadFees();
  };

  render() {
    return this.state.pending ? (
      <Loader type="Puff" color="#545454" height={50} width={50} />
    ) : (
      <React.Fragment>
        {this.props.displayFeesModel && (
          <Card className="mini-stats-wid">
            <CardBody>
              <h5>Fees model</h5>
              {this.state.error && (
                <span className="badge badge-danger font-size-10">
                  {this.state.error}
                </span>
              )}
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>Set wallet minting fees</Label>
                    <Row>
                      <Col sm="12">
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">%</span>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={this.handleMint}
                            autoComplete="off"
                            placeholder="Percent on minting amount"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>Set wallet burning fees</Label>
                    <Row>
                      <Col sm="12">
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">%</span>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            className="form-control"
                            min="0"
                            onChange={this.handleBurn}
                            autoComplete="off"
                            placeholder="Percent on burning amount"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="controlCommission"
                      checked={this.state.commission}
                      onClick={() => {
                        this.setState({ commission: !this.state.commission });
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="controlCommission"
                    >
                      Set commission on OCA reporting
                    </label>
                  </div>
                </Col>
                {this.state.commission && (
                  <Col xs="12">
                    <FormGroup>
                      <Label>Euro commission on fees</Label>
                      <Row>
                        <Col sm="12">
                          <InputGroup className="mb-2">
                            <InputGroupAddon addonType="prepend">
                              <span className="input-group-text">%</span>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              className="form-control"
                              min="0"
                              onChange={this.handleEuroFees}
                              autoComplete="off"
                              placeholder="Percent on fees for EUROP <-> Eur operations"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label>Min. Euro commission on amount</Label>
                      <Row>
                        <Col sm="12">
                          <InputGroup className="mb-2">
                            <InputGroupAddon addonType="prepend">
                              <span className="input-group-text">%</span>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              className="form-control"
                              min="0"
                              onChange={this.handleBaseEuro}
                              autoComplete="off"
                              placeholder="Min. Percent on amount for EUROP <-> Eur operations"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label>Crypto commission on fees</Label>
                      <Row>
                        <Col sm="12">
                          <InputGroup className="mb-2">
                            <InputGroupAddon addonType="prepend">
                              <span className="input-group-text">%</span>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              className="form-control"
                              min="0"
                              onChange={this.handleCryptoFees}
                              autoComplete="off"
                              placeholder="Percent on fees for EUROP <-> Crypto operations"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label>Min. Crypto commission on amount</Label>
                      <Row>
                        <Col sm="12">
                          <InputGroup className="mb-2">
                            <InputGroupAddon addonType="prepend">
                              <span className="input-group-text">%</span>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              className="form-control"
                              min="0"
                              onChange={this.handleBaseCrypto}
                              autoComplete="off"
                              placeholder="Min. Percent on amount for EUROP <-> Crypto operations"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                )}
                <Col xs="12">
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="controlSubscription"
                      checked={this.state.subscription}
                      onClick={() => {
                        this.setState({
                          subscription: !this.state.subscription,
                        });
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="controlSubscription"
                    >
                      Add monthly subscription model
                    </label>
                  </div>
                </Col>
                {this.state.subscription && (
                  <Col xs="12">
                    <FormGroup>
                      <Label>Set subscription model</Label>
                      {Object.keys(this.state.levelFields).map((key, i) => (
                        <Row key={`lvl_${i}`}>
                          <Col sm="10">
                            <InputGroup className="mb-2">
                              <Input
                                type="text"
                                style={{
                                  width: '40px',
                                  borderRight: '0px',
                                  backgroundColor: '#fafafa',
                                }}
                                onChange={(event) => {
                                  this.handleRevenueLevel(event, key);
                                }}
                                placeholder="X€ - X€"
                              />
                              <Input
                                type="number"
                                className="form-control"
                                min="0"
                                onChange={(event) => {
                                  this.handleRevenuePrice(event, key);
                                }}
                                autoComplete="off"
                                placeholder="Price"
                              />
                              <InputGroupAddon addonType="append">
                                <span className="input-group-text">€</span>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col sm="2">
                            <Button
                              type="button"
                              color="danger"
                              size="sm"
                              onClick={() => {
                                this.deleteLevelField(key);
                              }}
                            >
                              x
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col sm="12">
                          <Button
                            type="button"
                            color="info"
                            size="sm"
                            onClick={() => {
                              this.addLevelField();
                            }}
                          >
                            Add subscription level
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row className="mb-2">
                <Col sm="12">
                  <p className="text-muted mb-0 font-size-11 mb-2">
                    Set and update Fee model
                  </p>
                  <Button
                    type="button"
                    color="success"
                    onClick={() => {
                      this.updateFee();
                    }}
                  >
                    Update fees model
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {this.state.feeModels &&
          this.state.feeModels.map((feeModel) => (
            <Card className="mini-stats-wid" key={`f-${feeModel.id}`}>
              <CardBody>
                {!feeModel.endDate ||
                Date.parse(feeModel.endDate) > Date.now() ? (
                  <div>
                    <span className="badge badge-success font-size-10">
                      Active
                    </span>{' '}
                    <small>Fees model</small>
                  </div>
                ) : (
                  <div>
                    <span className="badge badge-danger font-size-10">
                      Expired
                    </span>{' '}
                    <small>Fees model</small>
                  </div>
                )}
                <Label className="d-block mt-2">
                  From{' '}
                  <strong>
                    {new Date(feeModel.startDate).toLocaleString()}
                  </strong>
                  {feeModel.endDate && (
                    <span className="ml-2">
                      to{' '}
                      <strong>
                        {new Date(feeModel.endDate).toLocaleString()}
                      </strong>
                    </span>
                  )}
                </Label>
                <Label className="d-block mt-1">
                  Mint fees: <strong>{feeModel.mint}</strong>% | Burn fees:{' '}
                  <strong>{feeModel.burn}</strong>%
                </Label>
                {feeModel.euroFees ||
                feeModel.cryptoFees ||
                feeModel.baseEuro ||
                feeModel.baseCrypto ? (
                  <div>
                    <Label className="d-block mt-2 mb-0">OCA commissions</Label>
                    <small className="d-block">
                      Euro fees: <strong>{feeModel.euroFees}</strong>% | Base
                      euro amount fees: <strong>{feeModel.baseEuro}</strong>%
                      <br />
                      Crypto fees: <strong>{feeModel.cryptoFees}</strong>% |
                      Base crypto amount fees:{' '}
                      <strong>{feeModel.baseCrypto}</strong>%
                    </small>
                  </div>
                ) : (
                  <div className="d-block mt-1">
                    <span className="badge badge-dark font-size-10">
                      No commissions
                    </span>
                  </div>
                )}
                {feeModel.revenues && feeModel.revenues.length > 0 ? (
                  <div>
                    <Label className="d-block mt-2 mb-0">
                      Subscription model
                    </Label>
                    {feeModel.revenues.map((revenue) => (
                      <small className="d-block mb-1" key={revenue.price}>
                        <span className="badge badge-light font-size-10">
                          {revenue.level}
                        </span>{' '}
                        {revenue.price}€
                      </small>
                    ))}
                  </div>
                ) : (
                  <div className="d-block mt-1">
                    <span className="badge badge-dark font-size-10">
                      No subscription
                    </span>
                  </div>
                )}
              </CardBody>
            </Card>
          ))}
      </React.Fragment>
    );
  }
}

export default Fees;
