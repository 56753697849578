import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  Media,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

import { addressDetails, dictAliases } from '../../helpers/api';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import Breadcrumbs from '../../components/Common/Breadcrumb';

class KeystoreUtils extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      pending: false,
      error: null,
      success: null,
      activeTab: '1',
      keystore: null,
      encryptedWallet: null,
      fileName: null,
      passPhrase: null,
      publicKeyHash: null,
      ops: [],
      details: {},
      token: {},
      amount: 0,
      receiver: null,
    };
    this.hiddenFileInput = React.createRef();
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleUpload = (event) => {
    this.hiddenFileInput.current.click();
  };

  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      try {
        const data = event.target.result.split(';base64,').pop();
        const buff = new Buffer(data, 'base64');
        const jsonData = JSON.parse(buff.toString('ascii'));
        this.setState({
          encryptedWallet: jsonData,
          error: null,
        });
      } catch (error) {
        console.log(error);
        this.setState({ error: error.toString() });
      }
    });
    this.setState({
      fileName: event.target.files[0].name,
      publicKeyHash: null,
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  updatePassphrase = (event) => {
    this.setState({ passPhrase: event.target.value });
  };

  updateReceiver = (event) => {
    this.setState({ receiver: event.target.value });
  };

  updateAmount = (event) => {
    this.setState({ amount: event.target.value });
  };

  unlockKeys = async () => {
    this.setState({ pending: true, error: null });
    try {
      const loadedWallet = null; //await conseiljs.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.passPhrase);
      const info = await addressDetails(
        loadedWallet.identities[0].publicKeyHash,
      );
      this.setState({
        loading: false,
        error: null,
        publicKeyHash: loadedWallet.identities[0].publicKeyHash,
        pending: false,
      });
      if (info.status === 'SUCCESS') {
        this.setState({
          ops: info.data.ops || [],
          details: info.data.details || {},
          token: info.data.token || {},
        });
      } else {
        this.setState({ error: 'Unrevealed or empty address' });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: 'Wrong file or password', pending: false });
    }
  };

  revealKey = async () => {
    this.setState({ pending: true, error: null });
    /* const node = await rpcNode();
        const loadedWallet = await conseiljs.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.passPhrase);
        try{
            const result = await conseiljs.TezosNodeWriter.sendKeyRevealOperation(node.data, loadedWallet.identities[0]);
            this.setState({pending: false, success: `Reveal operation sent with ID ${result.operationGroupID.replace(/['"]+/g, '')}. It may take up to 1 min. to be effective.`});
        }catch(e){
            this.setState({pending: false, error: 'Could not reveal public key. No funds or already revealed.'});
        } */
  };

  transferFunds = async () => {
    this.setState({ pending: true, error: null });
    /* const node = await rpcNode();
        const loadedWallet = await conseiljs.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.passPhrase);
        try{
            const result = await conseiljs.TezosNodeWriter.sendTransactionOperation(node.data, loadedWallet.identities[0], this.state.receiver, Math.round(parseFloat(this.state.amount) * this.state.decimals), 2000, derivationPath);
            this.setState({pending: false, success: `Transfer operation sent with ID ${result.operationGroupID.replace(/['"]+/g, '')}. It may take up to 1 min. to be effective.`});
        }catch(e){
            this.setState({pending: false, error: 'Could not send transfer.'});
        } */
  };

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Wallet" breadcrumbItem="Keystore utils" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-wallet"></i> Upload keystore file and
                      retrieve information
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="create">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}

                          {this.state.publicKeyHash && (
                            <Row className="mb-4">
                              <Col md="3">
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <Media>
                                      <Media body>
                                        <p className="text-muted font-weight-medium">
                                          Balance
                                        </p>
                                        <h4 className="mb-0">
                                          <SVGLogo />{' '}
                                          {this.state.token
                                            ? parseFloat(
                                                this.state.token.balance / 100,
                                              ).toLocaleString()
                                            : 0}
                                        </h4>
                                      </Media>
                                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                        <span className="avatar-title">
                                          <SVGLogo />
                                        </span>
                                      </div>
                                    </Media>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="3">
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <Media>
                                      <Media body>
                                        <p className="text-muted font-weight-medium">
                                          Provision
                                        </p>
                                        <h4 className="mb-0">
                                          <TezosLogo />{' '}
                                          {this.state.details.total_balance
                                            ? this.state.details.total_balance.toLocaleString()
                                            : 0}
                                        </h4>
                                      </Media>
                                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                        <span className="avatar-title">
                                          <TezosLogo />
                                        </span>
                                      </div>
                                    </Media>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="3">
                                {this.state.token &&
                                this.state.token.lock.toString() === 'true' ? (
                                  <Card className="mini-stats-wid bg-danger">
                                    <CardBody>
                                      <Media>
                                        <Media body>
                                          <p className="text-white font-weight-medium">
                                            Lock status
                                          </p>
                                          <h4 className="text-white mb-0">
                                            locked
                                          </h4>
                                        </Media>
                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                          <span className="avatar-title bg-white">
                                            <i className="bx bx-lock font-size-24 text-danger"></i>
                                          </span>
                                        </div>
                                      </Media>
                                    </CardBody>
                                  </Card>
                                ) : (
                                  <Card className="mini-stats-wid bg-success">
                                    <CardBody>
                                      <Media>
                                        <Media body>
                                          <p className="text-white font-weight-medium">
                                            Lock status
                                          </p>
                                          <h4 className="text-white mb-0">
                                            Unlocked
                                          </h4>
                                        </Media>
                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                          <span className="avatar-title bg-white">
                                            <i className="bx bx-lock-open font-size-24 text-success"></i>
                                          </span>
                                        </div>
                                      </Media>
                                    </CardBody>
                                  </Card>
                                )}
                              </Col>
                              <Col md="3">
                                {this.state.token &&
                                this.state.token.white.toString() === 'true' ? (
                                  <Card className="mini-stats-wid bg-success">
                                    <CardBody>
                                      <Media>
                                        <Media body>
                                          <p className="text-white font-weight-medium">
                                            Whitelist status
                                          </p>
                                          <h4 className="text-white mb-0">
                                            Approved
                                          </h4>
                                        </Media>
                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                          <span className="avatar-title bg-white">
                                            <i className="bx bx-check font-size-24 text-success"></i>
                                          </span>
                                        </div>
                                      </Media>
                                    </CardBody>
                                  </Card>
                                ) : (
                                  <Card className="mini-stats-wid bg-danger">
                                    <CardBody>
                                      <Media>
                                        <Media body>
                                          <p className="text-white font-weight-medium">
                                            Whitelist status
                                          </p>
                                          <h4 className="text-white mb-0">
                                            Unapproved
                                          </h4>
                                        </Media>
                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-white align-self-center">
                                          <span className="avatar-title bg-white">
                                            <i className="bx bx-x font-size-24 text-danger"></i>
                                          </span>
                                        </div>
                                      </Media>
                                    </CardBody>
                                  </Card>
                                )}
                              </Col>
                            </Row>
                          )}

                          <FormGroup>
                            <Label>
                              Select keystore file{' '}
                              <small>*usually .tezwallet</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup>
                                  <input
                                    placeholder="Keystore file"
                                    type="file"
                                    className="d-none"
                                    ref={this.hiddenFileInput}
                                    onChange={(e) => this.onFileHandler(e)}
                                  />
                                  {!this.state.encryptedWallet ? (
                                    <Button
                                      color="primary"
                                      type="button"
                                      onClick={this.handleUpload}
                                    >
                                      Upload Keystore file
                                    </Button>
                                  ) : (
                                    <div>
                                      <span className="text-primary mb-1">
                                        <strong>{this.state.fileName}</strong>{' '}
                                        selected
                                      </span>
                                      <p
                                        className="text-info cursor-pointer mt-1"
                                        onClick={() => {
                                          this.setState({
                                            encryptedWallet: null,
                                          });
                                        }}
                                      >
                                        <small>Click to change file</small>
                                      </p>
                                    </div>
                                  )}
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          {!this.state.publicKeyHash && (
                            <FormGroup>
                              <Label>Set passphrase to unlock Keystore</Label>
                              <Row>
                                <Col sm="8">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        Passphrase
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      onChange={this.updatePassphrase}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          {this.state.encryptedWallet &&
                            !this.state.pending &&
                            !this.state.publicKeyHash && (
                              <Button
                                color="primary"
                                type="button"
                                onClick={this.unlockKeys}
                              >
                                Unlock keystore
                              </Button>
                            )}

                          {this.state.pending && (
                            <p>
                              <small>Processing... Please wait.</small>
                            </p>
                          )}

                          {this.state.publicKeyHash && (
                            <span className="text-success mb-3">
                              <strong>{this.state.publicKeyHash}</strong>{' '}
                              unlocked
                            </span>
                          )}

                          {this.state.publicKeyHash && <hr />}

                          {this.state.publicKeyHash &&
                            !this.state.details.is_revealed &&
                            !this.state.pending && (
                              <FormGroup className="mt-4">
                                <Label>
                                  Reveal public key{' '}
                                  <small>*mandatory to send operations</small>
                                </Label>
                                <Row>
                                  <Col sm="8">
                                    <Button
                                      color="primary"
                                      type="button"
                                      onClick={this.revealKey}
                                    >
                                      Reveal
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            )}

                          {this.state.publicKeyHash &&
                            this.state.details.is_revealed &&
                            !this.state.pending && (
                              <FormGroup className="mt-4">
                                <Label>
                                  Transfer <TezosLogo width="14" height="14" />
                                </Label>
                                <Row>
                                  <Col sm="8">
                                    <InputGroup className="mb-2">
                                      <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text">
                                          <TezosLogo width="14" height="14" />{' '}
                                          Amount
                                        </span>
                                      </InputGroupAddon>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        onChange={this.updateAmount}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                    <InputGroup className="mb-2">
                                      <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text">
                                          Receiver
                                        </span>
                                      </InputGroupAddon>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        onChange={this.updateReceiver}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="8">
                                    <Button
                                      color="primary"
                                      type="button"
                                      onClick={this.transferFunds}
                                    >
                                      Transfer{' '}
                                      <TezosLogo width="14" height="14" />
                                    </Button>
                                  </Col>
                                </Row>
                              </FormGroup>
                            )}
                          {this.state.success && (
                            <p className="text-success mt-4">
                              {this.state.success}
                            </p>
                          )}
                        </TabPane>
                        <TabPane tabId="2" id="success"></TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default KeystoreUtils;
