import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { connect } from 'react-redux';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { fetchQuote, saveQuote } from '../../helpers/api';

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: null,
      calls: [],
      newQuote: 1,
      success: false,
      error: null,
    };
  }

  updtQuote = (e) => {
    if (!isNaN(e.target.value)) this.setState({ newQuote: e.target.value });
  };

  componentDidMount = async () => {
    const resp = await fetchQuote(
      this.props.match.params.currency.toLowerCase(),
    );
    this.setState({ quote: resp.data.quote, calls: resp.data.calls });
  };

  pushNewQuote = async () => {
    try {
      const resp = await saveQuote(
        this.props.match.params.currency.toLowerCase(),
        this.state.newQuote,
      );
      this.setState({ success: true, error: null, quote: resp.data });
    } catch (e) {
      console.log(e);
      this.setState({ error: e.toString(), success: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Quote"
              breadcrumbItem={`Manage ${this.props.match.params.currency} quote`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-2">
                      Update {this.props.match.params.currency.toUpperCase()}{' '}
                      quote
                    </CardTitle>
                    {this.state.error && (
                      <p className="badge badge-danger font-size-12">
                        {this.state.error}
                      </p>
                    )}
                    {this.state.success && (
                      <p className="badge badge-success font-size-12">
                        Quote successfully updated
                      </p>
                    )}
                    <Row className="mb-2">
                      <Col md="2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.updtQuote}
                          value={this.state.newQuote}
                        />
                      </Col>
                      <Col md="2">
                        <h4 className="mt-2">
                          {this.props.match.params.currency.toUpperCase()}
                        </h4>
                      </Col>
                      <Col md="6">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            this.pushNewQuote();
                          }}
                        >
                          Set new quote
                        </button>
                      </Col>
                    </Row>
                    {this.state.quote && (
                      <React.Fragment>
                        <hr />
                        <CardTitle className="mt-4 mb-2">
                          {this.props.match.params.currency.toUpperCase()} quote
                        </CardTitle>
                        <Table className="project-list-table table-nowrap table-centered">
                          <thead>
                            <tr>
                              <th scope="col">Currency</th>
                              <th scope="col">Quote</th>
                              <th scope="col">Last update</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.quote.currency.toUpperCase()}</td>
                              <td>
                                <strong>{this.state.quote.quote}</strong>
                              </td>
                              <td>{this.state.quote.timestamp}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      <hr />
                      <CardTitle className="mt-4 mb-2">API calls</CardTitle>
                      <Table className="project-list-table table-nowrap table-centered">
                        <thead>
                          <tr>
                            <th scope="col">Client Name</th>
                            <th scope="col">Counter</th>
                            <th scope="col">Last call</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.calls &&
                            this.state.calls.map((call) => (
                              <tr key={`cl_${call.clientId}`}>
                                <td>{call.clientName}</td>
                                <td>
                                  <strong>{call.counter}</strong>
                                </td>
                                <td>{call.lastCall}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Quotes);
