import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

import './datatables.scss';
import { listClients } from '../../helpers/api';

class AllClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      clients: {
        columns: [
          {
            label: 'Logo',
            field: 'logo',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Identifier',
            field: 'identifier',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Current funds',
            field: 'funds',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Details',
            field: 'details',
            sort: 'asc',
            width: 50,
          },
          {
            label: 'OCA',
            field: 'oca',
            sort: 'asc',
            width: 50,
          },
        ],
        rows: [],
      },
      loading: true,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = async () => {
    const res = await listClients();
    if (res.status === 'SUCCESS') {
      for (let c of res.data) {
        let row = {
          logo: c.logo && (
            <img
              style={{ display: 'block', height: '30px' }}
              src={`https://premiere.infura-ipfs.io/ipfs/${c.logo}`}
              alt="logo"
            />
          ),
          name: <h5>{c.name}</h5>,
          identifier: c.publicKeyHash,
          funds: `${c.funds.toLocaleString()} EUROP`,
          details: (
            <Link
              as="span"
              className="badge badge-primary font-size-11"
              style={{ cursor: 'pointer' }}
              to={`/client-details/${c.publicKeyHash}`}
            >
              View Details
            </Link>
          ),
          oca: (
            <Link
              as="span"
              className="badge badge-success font-size-11"
              style={{ cursor: 'pointer' }}
              to={`/oca/${c.publicKeyHash}`}
            >
              View OCA reporting
            </Link>
          ),
        };

        await this.setState({
          clients: {
            columns: [...this.state.clients.columns],
            rows: [...this.state.clients.rows, row],
          },
        });
      }
      this.setState({ loading: false });
    } else {
      console.log(res.error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {!this.state.loading && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title">List of all clients</h4>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="p-3"
                      >
                        <TabPane tabId="1" id="all-clients">
                          <MDBDataTable
                            responsive
                            bordered
                            data={this.state.clients}
                            className="mt-1"
                            hover
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.loading && (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AllClients;
