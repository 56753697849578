import React, { Component } from 'react';
import { Modal, FormGroup, Label, Button, Row, Col } from 'reactstrap';

class ProviderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          size="md"
          isOpen={this.props.selectProvider}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 text-primary"
              id="myLargeModalLabel"
            >
              SELECT PROVIDER
            </h5>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Connect Ledger Nano</Label>
                  <div></div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Connect with Beacon</Label>
                  <Button>Beacon connect</Button>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProviderModal;
