import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import Avatar from 'react-avatar';

import { deleteUser } from '../../helpers/api';
import Update2FA from './reset-2fa';
import UpdateActivation from './re-activate';

const CardContact = ({ loadUsers, user, canDelete }) => {
  const handleDeleteUser = async (id) => {
    await deleteUser(id);
    loadUsers();
  };

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {
              <div className="avatar-sm mx-auto mb-4">
                <Avatar
                  name={`${user.name || ''}`}
                  round
                  size={48}
                  textSizeRatio={2}
                />
              </div>
            }

            <h5 className="font-size-15">
              <Link to="#" className="text-dark">
                {user.name}
              </Link>
            </h5>
            <p className="text-muted">{user.designation}</p>

            <div>
              {user.skills.map((skill, key) => (
                <div
                  to="#"
                  className={`badge badge-${
                    skill.name === 'CLIENT' ? 'pink' : 'primary'
                  } font-size-11 m-1`}
                  key={'_skill_' + key}
                >
                  {skill.name}
                  {user.client && (
                    <span className="ml-2">{user.client.name}</span>
                  )}
                </div>
              ))}
              {user.inwebo && (
                <div to="#" className={`badge badge-info font-size-11 m-1`}>
                  TrustBuilder
                </div>
              )}
              {user.active === "ACTIVATED" && (
                <div to="#" className={`badge badge-success font-size-11 m-1`}>
                  Active
                </div>
              )}
              {["PENDING_ACTIVATION", "INACTIVE"].includes(user.active)  && (
                <div to="#" className={`badge badge-warning font-size-11 m-1`}>
                  Inactive
                </div>
              )}
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                {user.inwebo ? (
                  ["PENDING_ACTIVATION", "INACTIVE"].includes(user.active) &&
                  <UpdateActivation user={user} />
                ) : (
                  <Update2FA user={user} />
                )}
              </div>
              <div className="flex-fill">
                {canDelete && (
                  <button
                    className="btn btn-lg"
                    id={'profile' + user.id}
                    onClick={() => {
                      if (window.confirm('Are you sure to delete this user?')) {
                        handleDeleteUser(user.id);
                      }
                    }}
                  >
                    <i className="bx bx-trash"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={'profile' + user.id}
                    >
                      Delete
                    </UncontrolledTooltip>
                  </button>
                )}
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardContact;
