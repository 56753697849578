import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';

import { opDetails, dictAliases } from '../../helpers/api';
import { truncStringPortion } from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import logo from '../../assets/images/logo-dark.png';

class TransactionDetails extends Component {
  constructor() {
    super();
    this.state = {
      decimals: 100,
      operation: null,
      aliases: [],
      loading: true,
    };
    this.printInvoice.bind(this);
  }

  //Print the Invoice
  printInvoice() {
    window.print();
  }

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data, decimals: res.decimals });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const opHash = this.props.match.params.opHash;
    this._asyncRequest = opDetails(opHash)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          let ops = [];
          res.data.forEach((op) => {
            op.parameters =
              op.parameters.value[op.parameters.entrypoint] ||
              op.parameters.value;
            ops.push(op);
          });
          this.setState({ operation: ops, loading: false });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              breadcrumbItem="Transaction details"
              title="EUROP Transactions"
              titleLink={'/transactions'}
            />

            <Row>
              {this.state.loading && (
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                    Loading{' '}
                  </Link>
                </div>
              )}
              {this.state.operation &&
                this.state.operation.map((op, key) => (
                  <Col lg="12" key={key}>
                    <Card>
                      <CardBody>
                        <div className="invoice-title">
                          <h4 className="float-right font-size-16">
                            <b className="mr-2">
                              {op.parameters
                                ? op.parameters.entrypoint
                                : op.type}
                            </b>
                            # {op.hash}
                          </h4>
                          <div className="mb-4">
                            <img src={logo} alt="logo" height="26" />
                          </div>
                        </div>
                        <hr />
                        <Row>
                          <Col xs="5">
                            <address>
                              <strong>Caller:</strong>
                              <br />
                              {this.state.aliases[op.sender] &&
                              this.state.aliases[op.sender].name
                                ? this.state.aliases[op.sender].name
                                : truncStringPortion(op.sender, 8, 6)}
                              <br />
                            </address>
                          </Col>
                          <Col xs="1" className="text-right">
                            <h2>-</h2>
                          </Col>
                          <Col xs="6" className="text-right">
                            <address>
                              <strong>To:</strong>
                              <br />
                              {this.state.aliases[op.receiver] &&
                              this.state.aliases[op.receiver].name
                                ? this.state.aliases[op.receiver].name
                                : truncStringPortion(op.receiver, 8, 6)}
                              <br />
                            </address>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="mt-3">
                            {op.parameters && (
                              <address>
                                <strong>Parameters:</strong>
                                <br />
                                {['string', 'integer', 'boolean'].includes(
                                  typeof op.parameters,
                                ) ? (
                                  this.state.aliases[
                                    op.parameters.toString()
                                  ] &&
                                  this.state.aliases[op.parameters.toString()]
                                    .name ? (
                                    this.state.aliases[op.parameters.toString()]
                                      .name
                                  ) : (
                                    op.parameters.toString()
                                  )
                                ) : (
                                  <>
                                    {Object.keys(op.parameters).map((e, i) => (
                                      <div key={i}>
                                        {this.state.aliases[e] &&
                                        this.state.aliases[e].name
                                          ? this.state.aliases[e].name
                                          : e}{' '}
                                        :
                                        <span className="ml-1">
                                          {[
                                            'amount',
                                            'balance',
                                            'value',
                                            'allowance',
                                          ].includes(e) ? (
                                            <span>
                                              {parseFloat(
                                                op.parameters[e] /
                                                  this.state.decimals,
                                              )}{' '}
                                              <SVGLogo width="16" height="16" />
                                            </span>
                                          ) : this.state.aliases[
                                              op.parameters[e].toString()
                                            ] &&
                                            this.state.aliases[
                                              op.parameters[e].toString()
                                            ].name ? (
                                            this.state.aliases[
                                              op.parameters[e].toString()
                                            ].name
                                          ) : (
                                            op.parameters[e].toString()
                                          )}
                                        </span>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </address>
                            )}
                          </Col>
                          <Col xs="6" className="mt-3 text-right">
                            <address>
                              <strong>Operation date:</strong>
                              <br />
                              {new Date(op.time).toUTCString()}
                              <br />
                              <br />
                            </address>
                          </Col>
                        </Row>
                        <div className="py-2 mt-3">
                          <h3 className="font-size-15 font-weight-bold">
                            Summary
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr>
                                <th style={{ width: '70px' }}>No.</th>
                                <th>Address</th>
                                <th className="text-right">Parameters</th>
                              </tr>
                            </thead>
                            <tbody>
                              {op.big_map_diff &&
                                op.big_map_diff.map(
                                  (diff, key) =>
                                    typeof diff.key !== 'object' && (
                                      <tr key={diff.key}>
                                        <td>{key}</td>
                                        <td>
                                          {this.state.aliases[diff.key] &&
                                          this.state.aliases[diff.key].name
                                            ? this.state.aliases[diff.key].name
                                            : diff.key}
                                        </td>
                                        <td className="text-right">
                                          {Object.keys(diff.value).map(
                                            (e, i) => (
                                              <div key={i}>
                                                {e}:
                                                <span className="ml-1">
                                                  {[
                                                    'amount',
                                                    'balance',
                                                    'value',
                                                    'allowance',
                                                  ].includes(e) ? (
                                                    <span>
                                                      {parseFloat(
                                                        diff.value[e] /
                                                          this.state.decimals,
                                                      )}{' '}
                                                      <SVGLogo
                                                        width="16"
                                                        height="16"
                                                      />
                                                    </span>
                                                  ) : (
                                                    diff.value[e].toString()
                                                  )}
                                                </span>
                                              </div>
                                            ),
                                          )}
                                        </td>
                                      </tr>
                                    ),
                                )}
                              <tr>
                                <td colSpan="2" className="border-0 text-right">
                                  <strong>Fees</strong>
                                </td>
                                <td className="border-0 text-right">
                                  <TezosLogo width="13" height="13" /> {op.fee}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="border-0 text-right">
                                  <strong>Gas used</strong>
                                </td>
                                <td className="border-0 text-right">
                                  {op.gas_used}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="border-0 text-right">
                                  <strong>Gas price</strong>
                                </td>
                                <td className="border-0 text-right">
                                  <TezosLogo width="13" height="13" />{' '}
                                  {op.gas_price}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="d-print-none">
                          <div className="float-right">
                            <Link
                              to="#"
                              onClick={this.printInvoice}
                              className="btn btn-success waves-effect waves-light mr-2"
                            >
                              <i className="fa fa-print"></i>
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TransactionDetails;
