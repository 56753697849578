import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { TezosToolkit } from '@taquito/taquito';
import { bytes2Char } from '@taquito/utils';
import { MDBDataTable } from 'mdbreact';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import { TezosContractIntrospector } from 'conseiljs';

import './datatables.scss';
import logo from '../../assets/images/logo-dark.png';
import {
  financialOps,
  callEntrypoint,
  registerOperation,
  bigmapKey,
  proposalOps,
  dictAliases,
  codeContract,
} from '../../helpers/api';
import OpModal from './Modals/OpModal.js';
import ProviderModal from './Modals/ProviderModal';
import SuccessModal from './Modals/SuccessModal.js';
import { truncStringPortion } from '../../helpers/formatter.js';
import Can from '../../components/Can';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';

const derivationPath = "44'/1729'/0'/0'/0'";

class ProposalDetails extends Component {
  constructor() {
    super();
    this.state = {
      decimals: 1000000,
      operation: null,
      loading: true,
      proposalId: null,
      contract: null,
      storage: null,
      aliases: [],
      details: {},
      signers: [],
      contractStorage: {},
      tezosNode: null,
      selectedWallet: null,
      passActive: null,
      keystore: {},
      connected: false,
      pending: false,
      error: null,
      success: null,
      proposal: {},
      displayResult: null,
      selectProvider: false,
      isAccept: true,
      code: null,
      ops: {
        columns: [
          {
            label: 'Operation ID',
            field: 'operationId',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation Type',
            field: 'operationType',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Date',
            field: 'date',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Parameters',
            field: 'parameters',
            width: 160,
          },
          {
            label: 'Caller',
            field: 'caller',
            sort: 'asc',
            width: 104,
          },
          {
            label: 'More',
            field: 'more',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: [],
      },
    };
    this.printInvoice.bind(this);
    this.passPhrase = React.createRef();
  }

  //Print the Invoice
  printInvoice() {
    window.print();
  }

  changePassActive = (wType, publicKeyHash = null) => {
    this.setState({ passActive: wType });
    if (publicKeyHash) this.setState({ selectedWallet: publicKeyHash });
  };

  entryParsing = (parameters, entrypoint, params) => {
    const entryPoints =
      TezosContractIntrospector.generateEntryPointsFromParams(parameters);
    var i = 0;
    while (i < entryPoints.length) {
      if (entryPoints[i].name === entrypoint) break;
      i++;
    }
    return entryPoints[i].generateInvocationPair(...params);
  };

  acceptProposal = async () => {
    this.setState({ isAccept: true });
    const genParams = this.entryParsing(
      this.state.details.parameters,
      'accept',
      ['"' + this.state.proposalId + '"'],
    );
    if (this.state.passActive === 'ledger') {
      //this.setState({selectProvider: true})
      this.ledgerSendOp('accept');
    } else if (this.state.passActive === 'platform') {
      this.platformSendOp(genParams);
    }
  };

  rejectProposal = async () => {
    this.setState({ isAccept: false });
    const genParams = this.entryParsing(
      this.state.details.parameters,
      'reject',
      ['"' + this.state.proposalId + '"'],
    );
    if (this.state.passActive === 'ledger') {
      this.ledgerSendOp('reject');
    } else if (this.state.passActive === 'platform') {
      this.platformSendOp(genParams);
    }
  };

  ledgerSendOp = async (entrypoint) => {
    try {
      this.setState({
        pending: true,
        info: `Connect your Ledger and approve connection request`,
      });
      const Tezos = new TezosToolkit(this.state.tezosNode);
      const transport = await TransportWebHID.create();
      const ledgerSigner = new LedgerSigner(
        transport, //required
        derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
        true, // prompt optional
        DerivationType.ED25519, // derivationType optional
      );
      Tezos.setProvider({ signer: ledgerSigner });
      const publicKeyHash = await Tezos.signer.publicKeyHash();
      if (this.state.selectedWallet !== publicKeyHash) {
        this.setState({
          error: 'Not connected with the right Ledger',
          pending: false,
          success: false,
        });
        return;
      }
      const contract = await Tezos.wallet.at(this.state.details.contract);
      this.setState({
        info: `Follow the instruction from your Ledger to send operation`,
      });
      let operation = await contract.methods[entrypoint](
        this.state.proposalId,
      ).send();
      this.setState({
        info: 'Awaiting confirmation. It may take up to 1 min...',
      });
      await operation.confirmation();
      this.setState({
        pending: false,
        success: `Operation ${operation.opHash} sent`,
      });
      this._asyncRequest = registerOperation(
        operation.opHash,
        entrypoint,
        `Sent ${entrypoint} for proposal ID ${this.state.proposalId}`,
        this.state.selectedWallet,
        this.state.contract,
        this.state.proposalId,
      )
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      await transport.close();
    } catch (e) {
      console.log(e);
      this.setState({
        pending: false,
        info: null,
        success: false,
        error: JSON.stringify(e),
      });
    }
  };

  platformSendOp = (genParams) => {
    this.setState({
      pending: true,
      error: null,
      success: null,
      displayResult: false,
    });
    this._asyncRequest = callEntrypoint(
      {entrypoint: genParams.entrypoint, parameters: [this.state.proposalId]},
      this.state.selectedWallet,
      this.passPhrase.current.value,
      this.state.contract,
    )
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            pending: false,
            success: `Operation ${res.data} sent`,
            displayResult: true,
          });
          this._asyncRequest = registerOperation(
            res.data,
            genParams.entrypoint,
            `Sent ${genParams.entrypoint} for proposal ID ${this.state.proposalId}`,
            this.state.selectedWallet,
            this.state.contract,
            this.state.proposalId,
          )
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({ pending: false, error: res.error });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ pending: false, error: error.toString() });
      });
  };

  getContractCode = (codeId) => {
    this._asyncRequest = codeContract(codeId)
      .then((res) => {
        this.setState({ code: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const contract = params.get('contract');
    const proposalId = params.get('proposalId');

    this.setState({ contract: contract, proposalId: proposalId });
    this._asyncRequest = bigmapKey(contract, proposalId)
      .then((res) => {
        this._asyncRequest = proposalOps(contract, proposalId)
          .then((propOps) => {
            if (propOps.status === 'SUCCESS') {
              propOps.data.forEach((op) => {
                if (op.is_success) {
                  let params =
                    op.parameters.value[op.parameters.entrypoint] ||
                    op.parameters.value;
                  let row = {
                    operationId: truncStringPortion(op.hash, 8, 6),
                    operationType: op.parameters.entrypoint,
                    date: op.time.replace('T', ' ').replace('Z', ''),
                    parameters: (
                      <span className="badge font-size-9 text-left">
                        {['string', 'integer', 'boolean'].includes(
                          typeof params,
                        ) ? (
                          ['mint', 'burn'].includes(
                            op.parameters.entrypoint,
                          ) ? (
                            <span>
                              {parseFloat(params / this.state.decimals)}{' '}
                              <SVGLogo width="13" height="13" />
                            </span>
                          ) : (
                            params.toString()
                          )
                        ) : (
                          <>
                            {Object.keys(params).map((e, i) => (
                              <div key={i} className="mt-1">
                                {e === 'f' ? 'From' : e === 't' ? 'To' : e} :
                                <span className="ml-1">
                                  {['amount', 'balance', 'value'].includes(
                                    e,
                                  ) ? (
                                    <span>
                                      {parseFloat(
                                        params[e] / this.state.decimals,
                                      )}{' '}
                                      <SVGLogo width="13" height="13" />
                                    </span>
                                  ) : this.state.aliases[
                                      params[e].toString()
                                    ] &&
                                    this.state.aliases[params[e].toString()]
                                      .name ? (
                                    this.state.aliases[params[e].toString()]
                                      .name
                                  ) : (
                                    params[e].toString()
                                  )}
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </span>
                    ),
                    caller:
                      this.state.aliases[op.sender] &&
                      this.state.aliases[op.sender].name
                        ? this.state.aliases[op.sender].name
                        : truncStringPortion(op.sender, 8, 6),
                    more: (
                      <Link
                        as="span"
                        className="badge badge-primary font-size-11 clickable"
                        to={`/transaction-details/${op.hash}`}
                      >
                        View Details
                      </Link>
                    ),
                  };
                  this.setState({
                    ops: {
                      columns: [...this.state.ops.columns],
                      rows: [...this.state.ops.rows, row],
                    },
                  });
                }
              });
              this.setState({
                storage: res.data,
                proposal: res.proposal,
                loading: false,
              });
              if (
                ['set_master_minter', 'set_administrator'].includes(
                  res.data.value.operation,
                )
              )
                this.getContractCode(res.data.value['address'].toString());
              if (res.data.value.status.toString() === 'false') {
                this._asyncRequest = financialOps(contract)
                  .then((res) => {
                    if (res.status === 'SUCCESS') {
                      this.setState({
                        contractStorage: res.data.storage,
                        details: res.data.details,
                        signers: res.data.signers,
                        tezosNode: res.data.tezosNode,
                      });
                    } else {
                      console.log(res.error);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              console.log(res.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <SuccessModal
              displayResult={this.state.displayResult}
              proposalId={this.state.proposalId}
              isAccept={this.state.isAccept}
              aliases={this.state.aliases}
              selectedWallet={this.state.selectedWallet}
            />
            <OpModal
              pending={this.state.pending}
              info={this.state.info}
              passActive={this.state.passActive}
            />
            <ProviderModal selectProvider={this.state.selectProvider} />
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              titleLink={window.location.pathname.replace(
                '/proposal-details',
                '',
              )}
              title="Proposals"
              breadcrumbItem={
                'Proposal for ' +
                this.state.contract +
                ' #' +
                this.state.proposalId
              }
            />

            <Row>
              {this.state.loading && (
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                    Loading{' '}
                  </Link>
                </div>
              )}
              {this.state.storage && (
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-right font-size-16">
                          <b className="mr-2">
                            {this.state.storage.value.operation}
                          </b>
                          # {this.state.storage.key}
                        </h4>
                        <div className="mb-4">
                          <img src={logo} alt="logo" height="28" />
                        </div>
                        {this.state.proposal && (
                          <p>{this.state.proposal.description}</p>
                        )}
                      </div>
                      <hr />
                      <Row>
                        <Col xs="12">
                          {this.state.storage.value.approvals ? (
                            <address>
                              <strong>Approvals:</strong>
                              <br />
                              {this.state.storage.value.approvals.map(
                                (approval) => (
                                  <p key={approval} className="mt-2 mb-0">
                                    {this.state.aliases[approval] &&
                                    this.state.aliases[approval].name
                                      ? this.state.aliases[approval].name
                                      : approval}
                                  </p>
                                ),
                              )}
                            </address>
                          ) : (
                            <address>
                              <strong>Operating approvals:</strong>
                              <br />
                              {this.state.storage.value.opApprovals.map(
                                (approval) => (
                                  <p key={approval} className="mt-2 mb-0">
                                    {this.state.aliases[approval] &&
                                    this.state.aliases[approval].name
                                      ? this.state.aliases[approval].name
                                      : approval}
                                  </p>
                                ),
                              )}
                              <br />
                              <strong>Controller approvals:</strong>
                              <br />
                              {this.state.storage.value.ctrlApprovals.map(
                                (approval) => (
                                  <p key={approval} className="mt-2 mb-0">
                                    {this.state.aliases[approval] &&
                                    this.state.aliases[approval].name
                                      ? this.state.aliases[approval].name
                                      : approval}
                                  </p>
                                ),
                              )}
                            </address>
                          )}
                        </Col>
                        <Col xs="12">
                          {this.state.storage.value.rejects ? (
                            <address>
                              <strong>Rejects:</strong>
                              <br />
                              {this.state.storage.value.rejects.map(
                                (reject) => (
                                  <p key={reject} className="mt-2 mb-0">
                                    {this.state.aliases[reject] &&
                                    this.state.aliases[reject].name
                                      ? this.state.aliases[reject].name
                                      : reject}
                                  </p>
                                ),
                              )}
                            </address>
                          ) : (
                            <address>
                              <strong>Operating rejects:</strong>
                              <br />
                              {this.state.storage.value.opRejects.map(
                                (reject) => (
                                  <p key={reject} className="mt-2 mb-0">
                                    {this.state.aliases[reject] &&
                                    this.state.aliases[reject].name
                                      ? this.state.aliases[reject].name
                                      : reject}
                                  </p>
                                ),
                              )}
                              <br />
                              <strong>Controller rejects:</strong>
                              <br />
                              {this.state.storage.value.ctrlRejects.map(
                                (reject) => (
                                  <p key={reject} className="mt-2 mb-0">
                                    {this.state.aliases[reject] &&
                                    this.state.aliases[reject].name
                                      ? this.state.aliases[reject].name
                                      : reject}
                                  </p>
                                ),
                              )}
                            </address>
                          )}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        {!this.state.storage.value.operation &&
                          Object.keys(this.state.storage.value).map(
                            (e, i) =>
                              ![
                                'approvals',
                                'opApprovals',
                                'ctrlApprovals',
                                'rejects',
                                'opRejects',
                                'ctrlRejects',
                                'status',
                                'contractAddr',
                              ].includes(e) && (
                                <Col xs="6" className="mt-3" key={i}>
                                  <address>
                                    <strong>
                                      {e === 'f'
                                        ? 'From'
                                        : e === 't'
                                        ? 'To'
                                        : e}
                                    </strong>{' '}
                                    :<br />
                                    <span className="ml-1">
                                      {['amount', 'balance', 'value'].includes(
                                        e,
                                      ) ? (
                                        <span>
                                          {parseFloat(
                                            this.state.storage.value[e] /
                                              this.state.decimals,
                                          )}{' '}
                                          <SVGLogo width="16" height="16" />
                                        </span>
                                      ) : this.state.aliases[
                                          this.state.storage.value[e].toString()
                                        ] &&
                                        this.state.aliases[
                                          this.state.storage.value[e].toString()
                                        ].name ? (
                                        this.state.aliases[
                                          this.state.storage.value[e].toString()
                                        ].name
                                      ) : (
                                        this.state.storage.value[e].toString()
                                      )}
                                    </span>
                                  </address>
                                </Col>
                              ),
                          )}

                        {[
                          'transfer',
                          'mint',
                          'burn',
                          'set_master_minter',
                          'set_administrator',
                          'add_minter',
                          'update_allowance',
                          'remove_minter',
                          'set_metadata',
                          'updateGasFee',
                        ].includes(this.state.storage.value.operation) &&
                          Object.keys(this.state.storage.value).map(
                            (e, i) =>
                              ![
                                'approvals',
                                'opApprovals',
                                'ctrlApprovals',
                                'rejects',
                                'opRejects',
                                'ctrlRejects',
                                'status',
                                'contractAddr',
                                'state',
                              ].includes(e) && (
                                <Col xs="6" className="mt-3" key={i}>
                                  <address>
                                    <strong>
                                      {e === 'f'
                                        ? 'From'
                                        : e === 't'
                                        ? 'To'
                                        : e}
                                    </strong>{' '}
                                    :<br />
                                    {['amount', 'balance', 'value'].includes(
                                      e,
                                    ) ? (
                                      <span>
                                        {parseFloat(
                                          this.state.storage.value[e] /
                                            this.state.decimals,
                                        )}{' '}
                                        <SVGLogo width="16" height="16" />
                                      </span>
                                    ) : this.state.aliases[
                                        this.state.storage.value[e].toString()
                                      ] &&
                                      this.state.aliases[
                                        this.state.storage.value[e].toString()
                                      ].name ? (
                                      this.state.aliases[
                                        this.state.storage.value[e].toString()
                                      ].name
                                    ) : typeof this.state.storage.value[e] ===
                                      'object' ? (
                                      JSON.stringify(
                                        this.state.storage.value[e],
                                      )
                                    ) : ['v'].includes(e) ? (
                                      bytes2Char(this.state.storage.value[e])
                                    ) : (
                                      this.state.storage.value[e].toString()
                                    )}
                                  </address>
                                </Col>
                              ),
                          )}
                        {['set_pause', 'setPause'].includes(
                          this.state.storage.value.operation,
                        ) && (
                          <Col xs="6" className="mt-3">
                            <address>
                              <strong>Status</strong> :<br />
                              {this.state.storage.value.state.toString() ===
                              'true' ? (
                                <span className="badge badge-danger">
                                  Transfers Paused
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  Transfers Active
                                </span>
                              )}
                            </address>
                          </Col>
                        )}
                        {this.state.storage.value.operation ===
                          'set_activation' && (
                          <Col xs="6" className="mt-3">
                            <address>
                              <strong>Status</strong> :<br />
                              {!this.state.storage.value.state.toString() ===
                              'false' ? (
                                <span className="badge badge-danger">
                                  Rights manager deActivated
                                </span>
                              ) : (
                                <span className="badge badge-success">
                                  Rights manager Activated
                                </span>
                              )}
                            </address>
                          </Col>
                        )}
                        {this.state.storage.value.operation ===
                          'set_minter' && (
                          <>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Minter</strong> :<br />
                                {this.state.aliases[
                                  this.state.storage.value.f
                                ] &&
                                this.state.aliases[this.state.storage.value.f]
                                  .name
                                  ? this.state.aliases[
                                      this.state.storage.value.f
                                    ].name
                                  : truncStringPortion(
                                      this.state.storage.value.f,
                                      8,
                                      6,
                                    )}
                                <br />
                              </address>
                            </Col>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Reserve</strong> :<br />
                                {this.state.aliases[
                                  this.state.storage.value.t
                                ] &&
                                this.state.aliases[this.state.storage.value.t]
                                  .name
                                  ? this.state.aliases[
                                      this.state.storage.value.t
                                    ].name
                                  : truncStringPortion(
                                      this.state.storage.value.t,
                                      8,
                                      6,
                                    )}
                                <br />
                              </address>
                            </Col>
                          </>
                        )}
                        {[
                          'grant',
                          'revoke',
                          'addIssuer',
                          'removeIssuer',
                          'set_fees_manager',
                          'set_fees_faucet',
                        ].includes(this.state.storage.value.operation) && (
                          <>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Address</strong> :<br />
                                {this.state.aliases[
                                  this.state.storage.value.t
                                ] &&
                                this.state.aliases[this.state.storage.value.t]
                                  .name
                                  ? this.state.aliases[
                                      this.state.storage.value.t
                                    ].name
                                  : truncStringPortion(
                                      this.state.storage.value.t,
                                      8,
                                      6,
                                    )}
                                <br />
                              </address>
                            </Col>
                          </>
                        )}
                        {['set_lock', 'setLock'].includes(
                          this.state.storage.value.operation,
                        ) && (
                          <>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Address</strong> :<br />
                                {this.state.aliases[
                                  this.state.storage.value.t
                                ] &&
                                this.state.aliases[this.state.storage.value.t]
                                  .name
                                  ? this.state.aliases[
                                      this.state.storage.value.t
                                    ].name
                                  : truncStringPortion(
                                      this.state.storage.value.t,
                                      8,
                                      6,
                                    )}
                                <br />
                              </address>
                            </Col>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Status</strong> :<br />
                                {this.state.storage.value.state ? (
                                  <span className="badge badge-danger">
                                    Locked
                                  </span>
                                ) : (
                                  <span className="badge badge-success">
                                    Unlocked
                                  </span>
                                )}
                              </address>
                            </Col>
                          </>
                        )}
                        {['setWhiteListing'].includes(
                          this.state.storage.value.operation,
                        ) && (
                          <>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Address</strong> :<br />
                                {this.state.aliases[
                                  this.state.storage.value.t
                                ] &&
                                this.state.aliases[this.state.storage.value.t]
                                  .name
                                  ? this.state.aliases[
                                      this.state.storage.value.t
                                    ].name
                                  : truncStringPortion(
                                      this.state.storage.value.t,
                                      8,
                                      6,
                                    )}
                                <br />
                              </address>
                            </Col>
                            <Col xs="6" className="mt-3">
                              <address>
                                <strong>Status</strong> :<br />
                                {this.state.storage.value.state ? (
                                  <span className="badge badge-success">
                                    Authorized
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    Non authorized
                                  </span>
                                )}
                              </address>
                            </Col>
                          </>
                        )}
                      </Row>
                      <hr />
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          Status :
                          {this.state.storage.value.status.toString() ===
                          'true' ? (
                            this.state.storage.value.ctrlRejects &&
                            this.state.storage.value.ctrlRejects.length > 0 ? (
                              <b className="text-danger ml-1">Rejected</b>
                            ) : this.state.storage.value.opRejects &&
                              this.state.storage.value.opRejects.length > 0 ? (
                              <b className="text-danger ml-1">Rejected</b>
                            ) : this.state.storage.value.rejects &&
                              this.state.storage.value.rejects.length > 0 ? (
                              <b className="text-danger ml-1">Rejected</b>
                            ) : (
                              <b className="text-success ml-1">Accepted</b>
                            )
                          ) : (
                            <b className="text-warning ml-1">Pending</b>
                          )}
                        </h3>
                      </div>

                      <Can
                        role={this.props.user.role}
                        perform="operator:financial"
                        yes={() => {
                          return (
                            <React.Fragment>
                              {this.state.storage.value.status.toString() ===
                                'false' && (
                                <>
                                  <div className="mb-2">
                                    <Label>
                                      Select a signer to accept or reject
                                      proposal
                                    </Label>
                                    {this.state.contractStorage.value && (
                                      <div>
                                        {this.state.contractStorage.value
                                          .operators ? (
                                          <Row>
                                            {this.state.contractStorage.value.operators.map(
                                              (operator) => (
                                                <Col
                                                  xl="3"
                                                  sm="12"
                                                  key={operator}
                                                >
                                                  <div className="mb-3">
                                                    <label
                                                      className="card-radio-label mb-2"
                                                      onClick={() => {
                                                        this.state.aliases[
                                                          operator
                                                        ] &&
                                                          this.state.aliases[
                                                            operator
                                                          ].walletType !==
                                                            'auto' &&
                                                          this.changePassActive(
                                                            this.state.aliases[
                                                              operator
                                                            ].walletType,
                                                            operator,
                                                          );
                                                      }}
                                                    >
                                                      {this.state.aliases[
                                                        operator
                                                      ] &&
                                                        this.state.aliases[
                                                          operator
                                                        ].walletType !==
                                                          'auto' && (
                                                          <input
                                                            type="radio"
                                                            name="currency"
                                                            id={operator}
                                                            className="card-radio-input"
                                                            readOnly
                                                          />
                                                        )}

                                                      <div
                                                        className={
                                                          this.state.aliases[
                                                            operator
                                                          ] &&
                                                          this.state.aliases[
                                                            operator
                                                          ].walletType !==
                                                            'auto'
                                                            ? 'card-radio'
                                                            : 'card'
                                                        }
                                                      >
                                                        <div>
                                                          {this.state.aliases[
                                                            operator
                                                          ] &&
                                                          this.state.aliases[
                                                            operator
                                                          ].walletType ===
                                                            'ledger' ? (
                                                            <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                          ) : this.state
                                                              .aliases[
                                                              operator
                                                            ] &&
                                                            this.state.aliases[
                                                              operator
                                                            ].walletType ===
                                                              'platform' ? (
                                                            <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                          ) : (
                                                            <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                          )}
                                                          <span>
                                                            {this.state.aliases[
                                                              operator
                                                            ] &&
                                                            this.state.aliases[
                                                              operator
                                                            ].name ? (
                                                              <span
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    this.state.aliases[
                                                                      operator
                                                                    ].name.replace(
                                                                      '(',
                                                                      '<br/>(',
                                                                    ),
                                                                }}
                                                              ></span>
                                                            ) : (
                                                              truncStringPortion(
                                                                operator,
                                                                8,
                                                                6,
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p className="text-muted font-size-11 mb-1">
                                                            {truncStringPortion(
                                                              operator,
                                                              8,
                                                              6,
                                                            )}
                                                          </p>
                                                          <h5 className="font-size-16 mb-1">
                                                            {this.state.signers[
                                                              operator
                                                            ]
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    operator
                                                                  ]
                                                                    .total_balance *
                                                                    100,
                                                                ) / 100
                                                              : 0}{' '}
                                                            <TezosLogo
                                                              width="16"
                                                              height="16"
                                                            />
                                                          </h5>
                                                          <span className="text-muted">
                                                            ~
                                                            {this.state.signers[
                                                              operator
                                                            ] &&
                                                            this.state.signers[
                                                              operator
                                                            ].total_balance >
                                                              0.12
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    operator
                                                                  ]
                                                                    .total_balance /
                                                                    0.12,
                                                                )
                                                              : 0}{' '}
                                                            operations
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </label>
                                                  </div>
                                                </Col>
                                              ),
                                            )}
                                            {this.state.contractStorage.value.controllers.map(
                                              (controller) => (
                                                <Col
                                                  xl="3"
                                                  sm="12"
                                                  key={controller}
                                                >
                                                  <div className="mb-3">
                                                    <label
                                                      className="card-radio-label mb-2"
                                                      onClick={() => {
                                                        this.state.aliases[
                                                          controller
                                                        ] &&
                                                          this.state.aliases[
                                                            controller
                                                          ].walletType !==
                                                            'auto' &&
                                                          this.changePassActive(
                                                            this.state.aliases[
                                                              controller
                                                            ].walletType,
                                                            controller,
                                                          );
                                                      }}
                                                    >
                                                      {this.state.aliases[
                                                        controller
                                                      ] &&
                                                        this.state.aliases[
                                                          controller
                                                        ].walletType !==
                                                          'auto' && (
                                                          <input
                                                            type="radio"
                                                            name="currency"
                                                            id={controller}
                                                            className="card-radio-input"
                                                            readOnly
                                                          />
                                                        )}

                                                      <div
                                                        className={
                                                          this.state.aliases[
                                                            controller
                                                          ] &&
                                                          this.state.aliases[
                                                            controller
                                                          ].walletType !==
                                                            'auto'
                                                            ? 'card-radio'
                                                            : 'card'
                                                        }
                                                      >
                                                        <div>
                                                          {this.state.aliases[
                                                            controller
                                                          ] &&
                                                          this.state.aliases[
                                                            controller
                                                          ].walletType ===
                                                            'ledger' ? (
                                                            <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                          ) : this.state
                                                              .aliases[
                                                              controller
                                                            ] &&
                                                            this.state.aliases[
                                                              controller
                                                            ].walletType ===
                                                              'platform' ? (
                                                            <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                          ) : (
                                                            <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                          )}
                                                          <span>
                                                            {this.state.aliases[
                                                              controller
                                                            ] &&
                                                            this.state.aliases[
                                                              controller
                                                            ].name ? (
                                                              <span
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    this.state.aliases[
                                                                      controller
                                                                    ].name.replace(
                                                                      '(',
                                                                      '<br/>(',
                                                                    ),
                                                                }}
                                                              ></span>
                                                            ) : (
                                                              truncStringPortion(
                                                                controller,
                                                                8,
                                                                6,
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p className="text-muted font-size-11 mb-1">
                                                            {truncStringPortion(
                                                              controller,
                                                              8,
                                                              6,
                                                            )}
                                                          </p>
                                                          <h5 className="font-size-16 mb-1">
                                                            {this.state.signers[
                                                              controller
                                                            ]
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    controller
                                                                  ]
                                                                    .total_balance *
                                                                    100,
                                                                ) / 100
                                                              : 0}{' '}
                                                            <TezosLogo
                                                              width="16"
                                                              height="16"
                                                            />
                                                          </h5>
                                                          <span className="text-muted">
                                                            ~
                                                            {this.state.signers[
                                                              controller
                                                            ] &&
                                                            this.state.signers[
                                                              controller
                                                            ].total_balance >
                                                              0.12
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    controller
                                                                  ]
                                                                    .total_balance /
                                                                    0.12,
                                                                )
                                                              : 0}{' '}
                                                            operations
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </label>
                                                  </div>
                                                </Col>
                                              ),
                                            )}
                                          </Row>
                                        ) : (
                                          <Row>
                                            {this.state.contractStorage.value.signers.map(
                                              (signer) => (
                                                <Col
                                                  xl="3"
                                                  sm="12"
                                                  key={signer}
                                                >
                                                  <div className="mb-3">
                                                    <label
                                                      className="card-radio-label mb-2"
                                                      onClick={() => {
                                                        this.state.aliases[
                                                          signer
                                                        ] &&
                                                          this.state.aliases[
                                                            signer
                                                          ].walletType !==
                                                            'auto' &&
                                                          this.changePassActive(
                                                            this.state.aliases[
                                                              signer
                                                            ].walletType,
                                                            signer,
                                                          );
                                                      }}
                                                    >
                                                      {this.state.aliases[
                                                        signer
                                                      ] &&
                                                        this.state.aliases[
                                                          signer
                                                        ].walletType !==
                                                          'auto' && (
                                                          <input
                                                            type="radio"
                                                            name="currency"
                                                            id={signer}
                                                            className="card-radio-input"
                                                            readOnly
                                                          />
                                                        )}

                                                      <div
                                                        className={
                                                          this.state.aliases[
                                                            signer
                                                          ] &&
                                                          this.state.aliases[
                                                            signer
                                                          ].walletType !==
                                                            'auto'
                                                            ? 'card-radio'
                                                            : 'card'
                                                        }
                                                      >
                                                        <div>
                                                          {this.state.aliases[
                                                            signer
                                                          ] &&
                                                          this.state.aliases[
                                                            signer
                                                          ].walletType ===
                                                            'ledger' ? (
                                                            <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                          ) : this.state
                                                              .aliases[
                                                              signer
                                                            ] &&
                                                            this.state.aliases[
                                                              signer
                                                            ].walletType ===
                                                              'platform' ? (
                                                            <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                          ) : (
                                                            <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                          )}
                                                          <span>
                                                            {this.state.aliases[
                                                              signer
                                                            ] &&
                                                            this.state.aliases[
                                                              signer
                                                            ].name ? (
                                                              <span
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    this.state.aliases[
                                                                      signer
                                                                    ].name.replace(
                                                                      '(',
                                                                      '<br/>(',
                                                                    ),
                                                                }}
                                                              ></span>
                                                            ) : (
                                                              truncStringPortion(
                                                                signer,
                                                                8,
                                                                6,
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <p className="text-muted font-size-11 mb-1">
                                                            {truncStringPortion(
                                                              signer,
                                                              8,
                                                              6,
                                                            )}
                                                          </p>
                                                          <h5 className="font-size-16 mb-1">
                                                            {this.state.signers[
                                                              signer
                                                            ]
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    signer
                                                                  ]
                                                                    .total_balance *
                                                                    100,
                                                                ) / 100
                                                              : 0}{' '}
                                                            <TezosLogo
                                                              width="16"
                                                              height="16"
                                                            />
                                                          </h5>
                                                          <span className="text-muted">
                                                            ~
                                                            {this.state.signers[
                                                              signer
                                                            ] &&
                                                            this.state.signers[
                                                              signer
                                                            ].total_balance >
                                                              0.12
                                                              ? Math.round(
                                                                  this.state
                                                                    .signers[
                                                                    signer
                                                                  ]
                                                                    .total_balance /
                                                                    0.12,
                                                                )
                                                              : 0}{' '}
                                                            operations
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </label>
                                                  </div>
                                                </Col>
                                              ),
                                            )}
                                          </Row>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {this.state.passActive === 'platform' && (
                                    <FormGroup>
                                      <Label>Passphrase :</Label>
                                      <input
                                        type="password"
                                        ref={this.passPhrase}
                                        className="form-control"
                                      />
                                    </FormGroup>
                                  )}
                                  {this.state.error && (
                                    <p className="badge badge-danger font-size-12">
                                      {this.state.error}
                                    </p>
                                  )}
                                  {this.state.success && (
                                    <p className="badge badge-success font-size-12">
                                      {this.state.success}
                                    </p>
                                  )}
                                  {this.state.displayResult && (
                                    <>
                                      <br />
                                      <p className="font-size-14 mt-1">
                                        <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                                        Proposal ID{' '}
                                        <b className="font-size-12 mr-1">
                                          {this.state.proposalId}
                                        </b>
                                        is operated by
                                        <b className="ml-1">
                                          {this.state.aliases[
                                            this.state.selectedWallet
                                          ] &&
                                          this.state.aliases[
                                            this.state.selectedWallet
                                          ].name
                                            ? this.state.aliases[
                                                this.state.selectedWallet
                                              ].name
                                            : truncStringPortion(
                                                this.state.selectedWallet,
                                                8,
                                                6,
                                              )}
                                        </b>
                                      </p>
                                    </>
                                  )}
                                  {!this.state.success && (
                                    <>
                                      {this.state.passActive === 'platform' && (
                                        <>
                                          {this.state.pending ? (
                                            <div className="py-2 mb-3">
                                              <p>
                                                Processing operation, please
                                                wait...
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="py-2 mb-3">
                                              <p>
                                                Proposal is opened awaiting for
                                                approval or reject
                                              </p>
                                              <Button
                                                type="button"
                                                color="success"
                                                size="sm"
                                                className="btn-rounded waves-effect waves-light"
                                                onClick={() => {
                                                  this.acceptProposal();
                                                }}
                                              >
                                                Accept proposal
                                              </Button>
                                              <Button
                                                type="button"
                                                color="danger"
                                                size="sm"
                                                className="btn-rounded waves-effect waves-light ml-3"
                                                onClick={() => {
                                                  this.rejectProposal();
                                                }}
                                              >
                                                Reject proposal
                                              </Button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                      {this.state.passActive === 'ledger' && (
                                        <>
                                          {this.state.pending ? (
                                            <div className="py-2 mb-3">
                                              <p>
                                                Follow further instructions on
                                                your ledger...
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="py-2 mb-3">
                                              <p>
                                                Proposal is opened awaiting for
                                                approval or reject
                                              </p>
                                              <Button
                                                type="button"
                                                color="success"
                                                size="sm"
                                                className="btn-rounded waves-effect waves-light"
                                                onClick={() =>
                                                  this.ledgerSendOp('accept')
                                                }
                                              >
                                                Accept proposal
                                              </Button>
                                              <Button
                                                type="button"
                                                color="danger"
                                                size="sm"
                                                className="btn-rounded waves-effect waves-light ml-3"
                                                onClick={() => {
                                                  this.rejectProposal();
                                                }}
                                              >
                                                Reject proposal
                                              </Button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        }}
                      />
                      <hr />
                      <h4 className="card-title mt-4">
                        #{this.state.storage.key} operations
                      </h4>
                      <MDBDataTable
                        responsive
                        bordered
                        data={this.state.ops}
                        paging={false}
                        className="mt-1"
                        hover
                      />
                      <div className="d-print-none">
                        <div className="float-right">
                          <Link
                            to="#"
                            onClick={this.printInvoice}
                            className="btn btn-success waves-effect waves-light mr-2"
                          >
                            <i className="fa fa-print"></i>
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(ProposalDetails);
