import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  InputGroupAddon,
} from 'reactstrap';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import Web3 from 'web3';
import Safe, { EthersAdapter } from '@safe-global/protocol-kit';
import { ethers } from 'ethers';
import SafeApiKit from '@safe-global/api-kit';

import {
  capitalizeFirstLetter,
  truncStringPortion,
} from '../../helpers/formatter.js';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  dictAliases,
  registerPolygonOperation,
  fetchPolygonconfig,
  registerPolygonProposal,
} from '../../helpers/api';
import PolygonLogo from '../../components/Common/PolygonLogo.js';
import OpModal from './Modals/OpModal.js';
import SuccessModal from './Modals/SuccessModal.js';

const descLimit = 140;

class Polygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      methods: [],
      parameters: {},
      dropdownOpen: false,
      selectedMethod: null,
      role: null,
      owners: null,
      balances: {},
      isSigner: null,
      signer: null,
      processing: false,
      cfgPolygon: {},
      aliases: [],
      displayAmount: null,
      displayResult: null,
      description: null,
      info: 'Connecting to Ledger Nano...',
      descCharLeft: descLimit,
      requestRef: null,
      requestAmount: 0,
      requestReceiver: 0,
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  componentDidMount = async () => {
    const role = this.props.match.params.role;
    const op = this.props.match.params.op;
    const query = new URLSearchParams(this.props.location.search);
    const requestRef = query.get('ref');
    const requestAmount = query.get('amount')
      ? parseInt(query.get('amount'))
      : 0;
    const requestReceiver = query.get('receiver');
    let cfgPolygon = await fetchPolygonconfig();
    await this.setState({
      cfgPolygon: cfgPolygon.data,
      requestRef,
      requestAmount,
      requestReceiver,
      description: requestRef ? `Request ${requestRef}` : '',
    });

    const res = await dictAliases();
    this.setState({ aliases: res.data });

    let methods = [];
    let parameters = {};
    const banned = ['initialize', 'Role', 'Ownership', 'Interface', 'upgrade'];
    for (let entry of cfgPolygon.data.abi) {
      if (
        entry.type &&
        entry.type === 'function' &&
        !entry.constant &&
        !banned.some((substring) => entry.name.includes(substring))
      ) {
        methods.push(entry.name);
        parameters[entry.name] = entry.inputs;
      }
    }
    this.setState({ methods: methods, parameters: parameters });
    if (role && op) this.selectMethod(op, role);
  };

  selectMethod = async (method, role) => {
    const safeAddr =
      role === 'administrator'
        ? this.state.cfgPolygon.administrator
        : role === 'minter'
        ? this.state.cfgPolygon.master_minter
        : role === 'owner'
        ? this.state.cfgPolygon.owner
        : this.state.cfgPolygon.reserve;
    const transactionServiceUrl = this.state.cfgPolygon.safe_tx_url;
    const resp = await axios.get(
      `${transactionServiceUrl}/api/v1/safes/${safeAddr}`,
    );
    for (let input of this.state.parameters[method]) {
      this['ref_' + input.name] = React.createRef();
    }
    let balances = {};
    for (let owner of resp.data.owners) {
      balances[owner] = await this.fetchBalance(owner);
    }
    this.setState({
      selectedMethod: method,
      role: role,
      owners: resp.data.owners,
      balances: balances,
    });
  };

  connectWallet = async (owner) => {
    this.setState({ signer: owner });
  };

  createOP = async () => {
    this.setState({ processing: true, displayResult: null });

    const provider = new ethers.BrowserProvider(window.ethereum);
    const safeOwner = await provider.getSigner();
    if (this.state.signer !== safeOwner.address) {
      this.setState({ isSigner: 'invalid', processing: false });
    } else {
      this.setState({
        isSigner: 'valid',
        info: 'Connecting to Gnosis Safe...',
      });
      const ethAdapter = new EthersAdapter({
        ethers,
        signerOrProvider: safeOwner,
      });

      const safeAddress =
        this.state.role === 'administrator'
          ? this.state.cfgPolygon.administrator
          : this.state.role === 'minter'
          ? this.state.cfgPolygon.master_minter
          : this.state.role === 'owner'
          ? this.state.cfgPolygon.owner
          : this.state.cfgPolygon.reserve;
      const safeService = new SafeApiKit({
        chainId: this.state.cfgPolygon.network === 'sepolia' ? 11155111 : 137,
      });
      const safeSdk = await Safe.create({ ethAdapter, safeAddress });
      this.setState({ info: 'Building raw transaction...' });
      const web3 = new Web3(this.state.cfgPolygon.infura_url);
      const EURUS = new web3.eth.Contract(
        this.state.cfgPolygon.abi,
        this.state.cfgPolygon.token,
      );

      let params = [];
      let jsonParams = {};
      for (let input of this.state.parameters[this.state.selectedMethod]) {
        if (input.name.toLowerCase().replace('_', '') === 'amount') {
          params.push(
            parseFloat(this['ref_' + input.name].current.value) *
              this.state.cfgPolygon.decimals,
          );
          jsonParams['amount'] = parseFloat(
            this['ref_' + input.name].current.value,
          );
          this.setState({
            displayAmount: parseFloat(this['ref_' + input.name].current.value),
          });
        } else {
          params.push(this['ref_' + input.name].current.value);
          jsonParams[input.name] = this['ref_' + input.name].current.value;
        }
      }

      const tx = EURUS.methods[this.state.selectedMethod](...params);
      const data = tx.encodeABI();

      let transaction = {
        to: this.state.cfgPolygon.token,
        value: 0,
        data: data,
      };
      const safeTransaction = await safeSdk.createTransaction({
        transactions: [transaction],
      });
      this.setState({ info: 'Signing transaction...' });
      const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
      const senderSignature = await safeSdk.signHash(safeTxHash);
      this.setState({ info: 'Publishing proposal...' });
      await safeService.proposeTransaction({
        safeAddress,
        safeTransactionData: safeTransaction.data,
        safeTxHash,
        senderAddress: safeOwner.address,
        senderSignature: senderSignature.data,
        origin: 'SCEME PF',
      });
      //Save proposal
      this._asyncRequest = registerPolygonProposal(
        safeTxHash,
        safeOwner.address,
        this.state.role,
        this.state.description || `Operation ${this.state.selectedMethod}`,
        this.state.selectedMethod,
        jsonParams,
        this.state.requestRef,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      //Save operation
      this._asyncRequest = registerPolygonOperation(
        safeTxHash,
        this.state.selectedMethod,
        `Proposal sent from ${this.state.role}`,
        safeOwner.address,
        this.state.role,
        safeTxHash,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({ info: 'Proposal completed' });
      this.setState({
        safeTxHash,
        processing: false,
        displayResult: 'Proposal successfully posted in the Gnosis safe',
      });
    }
  };

  fetchBalance = async (publicKeyHash) => {
    const web3 = new Web3(this.state.cfgPolygon.infura_url);
    const balance = parseFloat(
      Number(await web3.eth.getBalance(publicKeyHash)) / Math.pow(10, 18),
    ).toFixed(5);
    return balance;
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <SuccessModal
          displayResult={this.state.displayResult}
          proposalId={this.state.safeTxHash}
          op={this.props.match.params.op}
          contract={this.props.match.params.role}
          displayAmount={this.state.displayAmount}
          aliases={this.state.aliases}
          selectedWallet={this.state.signer}
        />
        <OpModal
          pending={this.state.processing}
          info={this.state.info}
          passActive={this.state.passActive}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Financial ERC20 Operation"
              breadcrumbItem={'Send ERC20 Operation'}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody style={{ minHeight: '280px' }}>
                    <Label>Select operation</Label>
                    <Row>
                      <Col sm="8">
                        {this.state.methods.length > 0 && (
                          <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={this.toggleDropdown}
                          >
                            <DropdownToggle caret color="primary">
                              {this.state.selectedMethod ? (
                                <span>
                                  <small>{this.state.role}:</small>
                                  <strong className="ml-2">
                                    {this.state.selectedMethod}
                                  </strong>
                                </span>
                              ) : (
                                'Select an operation'
                              )}
                            </DropdownToggle>
                            <DropdownMenu>
                              {this.state.methods &&
                                this.state.methods.map((method) =>
                                  [
                                    'setMasterMinter',
                                    'setAdministrator',
                                    'setOwner',
                                  ].includes(method) ? (
                                    <DropdownItem
                                      onClick={() => {
                                        this.selectMethod(method, 'owner');
                                      }}
                                      key={method}
                                    >
                                      <small>
                                        <b>owner: </b>
                                      </small>
                                      <span className="ml-1">{method}</span>
                                    </DropdownItem>
                                  ) : [
                                      'pause',
                                      'unpause',
                                      'blacklist',
                                      'unBlacklist',
                                      'forceTransfer',
                                      'setFeeFaucet',
                                      'updateTxFeeRate',
                                      'updateGaslessBasefee',
                                      'setTrustedForwarder',
                                    ].includes(method) ? (
                                    <DropdownItem
                                      onClick={() => {
                                        this.selectMethod(
                                          method,
                                          'administrator',
                                        );
                                      }}
                                      key={method}
                                    >
                                      <small>
                                        <b>admin: </b>
                                      </small>
                                      <span className="ml-1">{method}</span>
                                    </DropdownItem>
                                  ) : [
                                      'mint',
                                      'burn',
                                      'burnFrom',
                                      'updateMintingAllowance',
                                      'addMinter',
                                      'removeMinter',
                                    ].includes(method) ? (
                                    <DropdownItem
                                      onClick={() => {
                                        this.selectMethod(method, 'minter');
                                      }}
                                      key={method}
                                    >
                                      <small>
                                        <b>master_minter: </b>
                                      </small>
                                      <span className="ml-1">{method}</span>
                                    </DropdownItem>
                                  ) : (
                                    ['transfer'].includes(method) && (
                                      <DropdownItem
                                        onClick={() => {
                                          this.selectMethod(method, 'reserve');
                                        }}
                                        key={method}
                                      >
                                        <small>
                                          <b>reserve: </b>
                                        </small>
                                        <span className="ml-1">{method}</span>
                                      </DropdownItem>
                                    )
                                  ),
                                )}
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      {this.state.owners &&
                        this.state.owners.map((owner, index) => (
                          <Col xl="3" sm="12" key={owner}>
                            <div className="mb-3">
                              <label
                                className="card-radio-label mb-2"
                                onClick={() => {
                                  this.connectWallet(owner);
                                }}
                              >
                                <input
                                  type="radio"
                                  name="wallet"
                                  id={owner}
                                  className="card-radio-input"
                                  readOnly
                                />

                                <div className="card-radio">
                                  <div>
                                    <h5 className="font-size-16 mb-1">
                                      <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                      <span>
                                        {this.state.aliases[
                                          owner.toLowerCase()
                                        ] &&
                                        this.state.aliases[owner.toLowerCase()]
                                          .name ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: this.state.aliases[
                                                owner.toLowerCase()
                                              ].name.replace('(', '<br/>('),
                                            }}
                                          ></span>
                                        ) : (
                                          truncStringPortion(owner, 8, 6)
                                        )}
                                      </span>
                                    </h5>
                                    <div>
                                      <p className="text-muted font-size-11 mb-1">
                                        {truncStringPortion(owner, 8, 6)}
                                      </p>
                                      <h5 className="font-size-16 mb-1">
                                        {this.state.balances[owner]}{' '}
                                        <PolygonLogo width="16" height="16" />
                                      </h5>
                                      <span className="text-muted">
                                        ~
                                        {Math.floor(
                                          this.state.balances[owner] / 0.002,
                                        )}{' '}
                                        operations
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>
                        ))}
                    </Row>
                    {this.state.signer && this.state.isSigner === 'valid' && (
                      <React.Fragment>
                        <Label className="mt-4">Connected acccount</Label>
                        <p>
                          <small>
                            {this.state.isSigner && 'signer: '}{' '}
                            <strong>{this.state.signer}</strong>
                          </small>
                        </p>
                      </React.Fragment>
                    )}
                    {this.state.signer && this.state.isSigner === 'invalid' && (
                      <p className="badge badge-pill badge-danger font-size-12">
                        Not connected with correct account
                      </p>
                    )}
                    {this.state.selectedMethod && (
                      <React.Fragment>
                        <Row className="mt-4">
                          <Col sm="12">
                            {this.state.parameters[
                              this.state.selectedMethod
                            ].map((parameter) => (
                              <FormGroup
                                key={parameter.name}
                                className="d-block"
                              >
                                <Label>
                                  {parameter.name.replace('_', '') === 'amount'
                                    ? 'EUROP amount'
                                    : parameter.name.replace('_', '')}
                                </Label>
                                <Row>
                                  <Col sm="6">
                                    <InputGroup className="mb-2">
                                      {parameter.type === 'bool' ? (
                                        <select
                                          ref={this['ref_' + parameter.name]}
                                          className="form-control"
                                        >
                                          <option value={true}>True</option>
                                          <option value={false}>False</option>
                                        </select>
                                      ) : parameter.type === 'uint256' ? (
                                        <input
                                          type="number"
                                          ref={this['ref_' + parameter.name]}
                                          placeholder={capitalizeFirstLetter(
                                            parameter.type,
                                          )}
                                          defaultValue={
                                            parameter.name === 'amount' &&
                                            this.state.requestAmount
                                              ? this.state.requestAmount
                                              : null
                                          }
                                          className="form-control"
                                        />
                                      ) : (
                                        <input
                                          type="type"
                                          ref={this['ref_' + parameter.name]}
                                          placeholder={capitalizeFirstLetter(
                                            parameter.type,
                                          )}
                                          defaultValue={
                                            parameter.name.replace('_', '') ===
                                              'recipient' &&
                                            this.state.requestReceiver
                                              ? this.state.requestReceiver
                                              : null
                                          }
                                          className="form-control"
                                        />
                                      )}
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            ))}
                          </Col>
                        </Row>
                        <hr />
                        <FormGroup>
                          <Label>Set a proposal description</Label>
                          <Row>
                            <Col sm="12">
                              <InputGroup className="mb-2">
                                <InputGroupAddon addonType="prepend">
                                  <span className="input-group-text">
                                    {this.state.descCharLeft} char. left
                                  </span>
                                </InputGroupAddon>
                                <Input
                                  type="text"
                                  className="form-control"
                                  maxLength={descLimit}
                                  onChange={this.updateDescription}
                                  value={this.state.description}
                                  autoComplete="off"
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                        {this.state.pending && (
                          <p>
                            Follow further instructions on your ledger or
                            Metamask...
                          </p>
                        )}
                        {this.state.displayResult && (
                          <p className="badge badge-success font-size-12">
                            {this.state.displayResult}
                          </p>
                        )}
                        <Row>
                          {this.state.signer &&
                            !this.state.processing &&
                            !this.state.safeTxHash && (
                              <Col sm="8">
                                <Button color="success" onClick={this.createOP}>
                                  Send operation
                                </Button>
                              </Col>
                            )}
                        </Row>
                      </React.Fragment>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Polygon;
