import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import { refBank } from '../../helpers/api';
import Breadcrumbs from '../../components/Common/Breadcrumb';
const nameLimit = 40;

class NewBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: null,
      accountNumber: null,
      bankName: null,
      pending: false,
      error: null,
      activeTab: '1',
      processing: false,
    };
  }

  updateAccountName = (event) => {
    this.setState({
      accountName: event.target.value,
    });
  };

  updateAccountNumber = (event) => {
    this.setState({
      accountNumber: event.target.value,
    });
  };

  updateBankname = (event) => {
    this.setState({ bankName: event.target.value });
  };

  referenceBank = () => {
    if (!this.state.accountName || this.state.accountName.length < 4)
      this.setState({ error: 'Invalid account name!', pending: false });
    else if (!this.state.bankName)
      this.setState({ error: 'Invalid bank name!', pending: false });
    else if (!this.state.accountNumber || this.state.accountNumber.length < 10)
      this.setState({ error: 'Invalid account number!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = refBank(
        this.state.accountName,
        this.state.bankName,
        this.state.accountNumber,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            window.location = '/bank';
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  updateCode = (event) => {
    this.setState({ code: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Bank"
              breadcrumbItem="Reference a Bank account"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-building"></i> Reference a new Bank
                      account
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="create">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>Account name</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateAccountName}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Bank name</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateBankname}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Account number</Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    onChange={this.updateAccountNumber}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Label>
                            To validate this operation TrustBuilder
                            Authenticator is required
                          </Label>
                          {this.state.pending ? (
                            <div className="mt-2">
                              <Loader
                                type="Puff"
                                color="#50a5f1"
                                height={50}
                                width={50}
                              />
                              <p className="mt-2 text-info">
                                Notification sent onto your TrustBuilder
                                Authenticator application.{' '}
                                <strong>
                                  Set your pin code to save bank account...
                                </strong>
                              </p>
                            </div>
                          ) : (
                            <div className="mt-2">
                              <Button
                                type="button"
                                color="success"
                                onClick={this.referenceBank}
                              >
                                <strong className="text-dark ">
                                  Reference Bank account
                                </strong>
                              </Button>
                            </div>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default NewBankAccount;
