import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import classnames from 'classnames';

import {
  createWallet,
  createERC20Wallet,
  downloadERC20Wallet,
  fa2Download,
} from '../../helpers/api';
import { strongRegex } from '../../constants';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const descLimit = 100;
const nameLimit = 40;

class CreateWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      passPhrase: null,
      description: null,
      validPhrase: false,
      descCharLeft: descLimit,
      nameCharLeft: nameLimit,
      pending: false,
      error: null,
      activeTab: '1',
      mnemonic: '',
      publicKeyHash: null,
      copied: false,
      toSave: false,
      walletData: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateValidPhrase = (event) => {
    this.setState({ passPhrase: event.target.value });
    if (strongRegex.test(event.target.value)) {
      this.setState({ validPhrase: true });
    } else {
      this.setState({ validPhrase: false });
    }
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateName = (event) => {
    this.setState({
      nameCharLeft: nameLimit - event.target.value.length,
      name: event.target.value,
    });
  };

  updateMnemonic = (event) => {
    this.setState({ mnemonic: event.target.value });
  };

  createFA2Wallet = async () => {
    if (!this.state.validPhrase)
      this.setState({ error: 'Invalid passphrase!', pending: false });
    else if (
      this.state.toSave &&
      (!this.state.name || this.state.name.length < 4)
    )
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      if (this.state.toSave) {
        try {
          const res = await createWallet(
            this.state.name,
            this.state.passPhrase,
            this.state.description,
          );
          if (res.status === 'SUCCESS') {
            this.toggleTab('2');
            this.setState({
              mnemonic: res.data.mnemonic,
              publicKeyHash: res.data.publicKeyHash,
            });
          } else {
            this.setState({ error: res.error, pending: false });
          }
        } catch (e) {
          this.setState({ error: e.toString(), pending: false });
        }
      } else {
        const res = await fa2Download(this.state.passPhrase);
        this.toggleTab('2');
        this.setState({
          mnemonic: res.data.mnemonic,
          publicKeyHash: res.data.publicKeyHash,
          walletData:
            'text/json;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(res.data.keystore)),
        });
      }
    }
  };

  createEthWallet = async () => {
    if (!this.state.validPhrase)
      this.setState({ error: 'Invalid passphrase!', pending: false });
    else if (
      this.state.toSave &&
      (!this.state.name || this.state.name.length < 4)
    )
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      if (this.state.toSave) {
        try {
          const res = await createERC20Wallet(
            this.state.name,
            this.state.passPhrase,
            this.state.description,
          );
          if (res.status === 'SUCCESS') {
            this.toggleTab('2');
            this.setState({
              mnemonic: res.data.mnemonic,
              publicKeyHash: res.data.address,
            });
          } else {
            this.setState({ error: res.error, pending: false });
          }
        } catch (e) {
          this.setState({ error: e.toString(), pending: false });
        }
      } else {
        const res = await downloadERC20Wallet(this.state.passPhrase);
        this.toggleTab('2');
        this.setState({
          mnemonic: res.data.mnemonic,
          publicKeyHash: res.data.address,
          walletData:
            'text/json;charset=utf-8,' + encodeURIComponent(res.data.encrypted),
        });
      }
    }
  };

  copyMmemonic = () => {
    navigator.clipboard.writeText(this.state.mnemonic);
    this.setState({ copied: true });
    setTimeout(
      function () {
        this.setState({ copied: false });
      }.bind(this),
      2000,
    );
  };

  componentDidMount = async () => {};

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Wallet" breadcrumbItem="Create new Wallet" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-wallet"></i> Create & save a new
                      wallet on the platform
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '1',
                            })}
                            onClick={() => {
                              this.toggleTab('1');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Tezos</p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '3',
                            })}
                            onClick={() => {
                              this.toggleTab('3');
                            }}
                          >
                            <p className="font-weight-bold mb-1">Ethereum</p>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="tezos">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}

                          <FormGroup>
                            <Label>
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={() => {
                                  this.setState({ toSave: !this.state.toSave });
                                }}
                              />
                              <span className="ml-2">
                                Check to save wallet on the platform (download
                                otherwise)
                              </span>
                            </Label>
                          </FormGroup>
                          {this.state.toSave && (
                            <FormGroup>
                              <Label>Set Tezos wallet name</Label>

                              <Row>
                                <Col sm="8">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.nameCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={nameLimit}
                                      onChange={this.updateName}
                                      autoComplete="off"
                                    />
                                    <InputGroupAddon addonType="append">
                                      {this.state.name &&
                                      this.state.name.length > 3 ? (
                                        <h4 className="ml-2 text-success">
                                          <b className="bx bx-message-alt-check"></b>
                                        </h4>
                                      ) : (
                                        <h4 className="ml-2 text-danger">
                                          <b className="bx bx-message-alt-x"></b>
                                        </h4>
                                      )}
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          {this.state.toSave && (
                            <FormGroup>
                              <Label>Set short description</Label>

                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.descCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={descLimit}
                                      onChange={this.updateDescription}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          <FormGroup>
                            <Label>
                              Protect the wallet with a passPhrase (min. 8
                              characters, one upper case and one lower case min.
                              and one special character)
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      Passphrase
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    onChange={this.updateValidPhrase}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.validPhrase ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-check-shield"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-shield-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Mnemonic (seed words){' '}
                              <small>*edit to restore existing wallet</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="textarea"
                                    placeholder="Mnemonic (seed words)"
                                    value={this.state.mnemonic}
                                    onChange={this.updateMnemonic}
                                  ></Input>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <div className="text-center mt-4">
                            {this.state.pending ? (
                              <p>Creating wallet. please wait...</p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.createFA2Wallet}
                              >
                                {this.state.toSave
                                  ? `Create & save new wallet`
                                  : `Create & download new wallet`}
                              </Button>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="mnemonic">
                          <p className="text-success mb-4">
                            {this.state.toSave ? (
                              <strong>
                                Wallet {this.state.publicKeyHash} was
                                successfully created and saved on the platform
                              </strong>
                            ) : (
                              <strong>
                                Wallet {this.state.publicKeyHash} was
                                successfully created and ready to download
                              </strong>
                            )}
                          </p>
                          <p>
                            Here is the back up phrase for your wallet. It is
                            strongly advised to write it down and save it in a
                            safe cold storage.
                          </p>
                          <h5 className="mb-3">
                            {this.state.mnemonic}
                            <span
                              className="ml-2 text-success clickable"
                              onClick={() => {
                                this.copyMmemonic();
                              }}
                            >
                              <b className="bx bx-clipboard"></b>{' '}
                              {this.state.copied && 'copied!'}
                            </span>
                          </h5>
                          {this.state.walletData && (
                            <div className="mt-4 mb-2">
                              <a
                                href={`data:${this.state.walletData}`}
                                className="text-white bg-success p-2 rounded w-100 h-100"
                                download={`${this.state.publicKeyHash}.wallet`}
                              >
                                <span className="fw-semi-bold">
                                  Download {this.state.publicKeyHash} wallet
                                </span>
                              </a>
                              <p className="mt-3 text-muted">
                                Donwload keystore file
                              </p>
                            </div>
                          )}
                        </TabPane>
                        <TabPane tabId="3" id="ethereum">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}

                          <FormGroup>
                            <Label>
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={() => {
                                  this.setState({ toSave: !this.state.toSave });
                                }}
                              />
                              <span className="ml-2">
                                Check to save wallet on the platform (download
                                otherwise)
                              </span>
                            </Label>
                          </FormGroup>
                          {this.state.toSave && (
                            <FormGroup>
                              <Label>Set ETH wallet name</Label>

                              <Row>
                                <Col sm="8">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.nameCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={nameLimit}
                                      onChange={this.updateName}
                                      autoComplete="off"
                                    />
                                    <InputGroupAddon addonType="append">
                                      {this.state.name &&
                                      this.state.name.length > 3 ? (
                                        <h4 className="ml-2 text-success">
                                          <b className="bx bx-message-alt-check"></b>
                                        </h4>
                                      ) : (
                                        <h4 className="ml-2 text-danger">
                                          <b className="bx bx-message-alt-x"></b>
                                        </h4>
                                      )}
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          {this.state.toSave && (
                            <FormGroup>
                              <Label>Set short description</Label>

                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.descCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={descLimit}
                                      onChange={this.updateDescription}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          )}

                          <FormGroup>
                            <Label>
                              Protect the wallet with a passPhrase (min. 8
                              characters, one upper case and one lower case min.
                              and one special character)
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      Passphrase
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    onChange={this.updateValidPhrase}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.validPhrase ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-check-shield"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-shield-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>
                              Mnemonic (seed words){' '}
                              <small>*edit to restore existing wallet</small>
                            </Label>
                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="textarea"
                                    placeholder="Mnemonic (seed words)"
                                    value={this.state.mnemonic}
                                    onChange={this.updateMnemonic}
                                  ></Input>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <div className="text-center mt-4">
                            {this.state.pending ? (
                              <p>Creating wallet. please wait...</p>
                            ) : (
                              <Button
                                type="button"
                                color="success"
                                onClick={this.createEthWallet}
                              >
                                {this.state.toSave
                                  ? `Create & save new wallet`
                                  : `Create & download new wallet`}
                              </Button>
                            )}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateWallet;
