// @flow
import {
	CHANGE_TOKEN
} from "./actionTypes";

const INIT_STATE = {
	activeToken: localStorage.getItem('activeToken') || "erc20"
};

const Token = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_TOKEN:
			return {
				...state,
				activeToken: action.payload
			};
		default:
			return state;
	}
};

export default Token;