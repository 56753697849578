import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import { refWalletClient } from '../../../helpers/api';

class NewWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chain: 'tezos',
      name: null,
      address: null,
      error: null,
      pending: false,
    };
  }

  updateName = (event) => {
    this.setState({ name: event.target.value });
  };

  updateAddress = (event) => {
    this.setState({ address: event.target.value });
  };

  referenceWallet = () => {
    if (!this.state.address)
      this.setState({
        error: 'Invalid public key hash / address',
        pending: false,
      });
    else if (this.state.name.length < 4)
      this.setState({ error: 'Invalid wallet name!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = refWalletClient(
        this.state.address,
        this.state.name,
        this.props.publicKeyHash,
        this.state.chain,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            this.props.loadClient();
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  render() {
    return (
      <Card className="mini-stats-wid">
        <CardBody>
          <Row>
            <Col xs="12">
              <h5>Set New Wallet</h5>
              {this.state.error && (
                <p className="badge badge-danger font-size-12">
                  {this.state.error}
                </p>
              )}
              <FormGroup>
                <Label>Wallet name</Label>
                <Row>
                  <Col sm="12">
                    <InputGroup className="mb-2">
                      <Input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Wallet name"
                        onChange={this.updateName}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col xs="12">
              <p>
                <span className="mr-2">
                  <input
                    type="radio"
                    name="chain"
                    onClick={() => {
                      this.setState({ chain: 'tezos' });
                    }}
                    defaultChecked={true}
                  />{' '}
                  Tezos
                </span>
                <span className="mr-2">
                  <input
                    type="radio"
                    name="chain"
                    onClick={() => {
                      this.setState({ chain: 'ethereum' });
                    }}
                  />{' '}
                  Ethereum
                </span>
              </p>
            </Col>
            <Col xs="12">
              <FormGroup>
                <Label>Set wallet {this.state.chain} address</Label>
                <Row>
                  <Col sm="12">
                    <InputGroup className="mb-2">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={this.updateAddress}
                        autoComplete="off"
                        placeholder={
                          this.state.chain === 'ethereum' ? '0x...' : 'tz1...'
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="8">
              {this.state.pending ? (
                <React.Fragment>
                  <Loader type="Puff" color="#50a5f1" height={50} width={50} />
                  <p className="mt-2 text-info">
                    Notification sent onto your TrustBuilder Authenticator
                    application.{' '}
                    <strong>Set your pin code to save wallet...</strong>
                  </p>
                </React.Fragment>
              ) : (
                <Button
                  type="button"
                  color="success"
                  onClick={() => {
                    this.referenceWallet();
                  }}
                >
                  Save Wallet address
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default NewWallet;
