import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { deleteBankOp, getBankAccounts, getBankOps } from '../../helpers/api';
import { connect } from 'react-redux';
import Can from '../../components/Can';

import './datatables.scss';
import CSV from '../../components/Common/CSV';
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: {
        columns: [
          {
            label: 'ID',
            field: 'id',
            sort: 'asc',
            width: 40,
          },
          {
            label: 'Reference',
            field: 'reference',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Request Field',
            field: 'requestField',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'From',
            field: 'fromAccount',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation date',
            field: 'opDate',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Operation type',
            field: 'opType',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Amount',
            field: 'amount',
            sort: 'asc',
            width: 160,
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Receiver',
            field: 'receiver',
            sort: 'asc',
            width: 136,
          },
          {
            label: 'Remove',
            field: 'remove',
            sort: 'asc',
            width: 136,
          },
        ],
        rows: [],
      },
      csvRows: [],
      clients: [],
      banks: [],
      activeTab: 'all',
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount = async () => {
    const banks = await getBankAccounts();
    this.setState({ banks });
    this.setOperations();
  };

  setOperations = async () => {
    this.setState({ loading: true });
    const res = await getBankOps();
    if (res.status === 'SUCCESS') {
      let rows = [],
        csvRows = [];
      res.data.operations.forEach((op) => {
        if (
          this.state.activeTab === 'all' ||
          op.receiver === this.state.activeTab ||
          op.fromAccount === this.state.activeTab
        ) {
          let idbank = res.data.idbanks.find((idbank) =>
            idbank.iban.includes(op.fromAccount),
          );
          let client = idbank
            ? res.data.clients.find(
                (client) => idbank.publicKeyHash === client.publicKeyHash,
              )
            : null;
          rows.push({
            id: op.id,
            reference: op.requestId,
            requestField: op.requestField,
            fromAccount: client
              ? `${client.name} (${op.fromAccount})`
              : op.fromAccount,
            opDate:
              new Date(op.opDate).toLocaleDateString() +
              ' ' +
              new Date(op.opDate).toLocaleTimeString(),
            opType: op.opType && op.opType === 'D' ? 'Debit' : 'Credit',
            amount: op.amount + ' ' + op.currency,
            status: (
              <span
                className={`badge badge-${
                  op.status === 'SUCCESS'
                    ? 'success'
                    : op.status === 'CREATED'
                    ? 'pink'
                    : op.status === 'UNKNOWN'
                    ? 'dark'
                    : 'danger'
                } font-size-10`}
              >
                {op.status}
              </span>
            ),
            receiver:
              op.receiver === res.data.salvusIban
                ? `SCHUMAN ${op.receiver}`
                : op.receiver,
            remove: (
              <Can
                role={this.props.user.role}
                perform="operator:financial"
                yes={() => {
                  return (
                    <span
                      className="text-dark bx bx-trash"
                      onClick={() => {
                        this.rmBankOp(op.id);
                      }}
                    ></span>
                  );
                }}
              />
            ),
          });
          csvRows.push({
            id: op.id,
            reference: op.requestId,
            requestField: op.requestField,
            fromAccount: op.fromAccount,
            opDate:
              new Date(op.opDate).toLocaleDateString() +
              ' ' +
              new Date(op.opDate).toLocaleTimeString(),
            opType: op.opType && op.opType === 'D' ? 'Debit' : 'Credit',
            amount: op.amount + ' ' + op.currency,
            status: op.status,
            receiver: op.receiver,
          });
        }
      });
      this.setState({
        transactions: {
          columns: [
            {
              label: 'ID',
              field: 'id',
              sort: 'asc',
              width: 40,
            },
            {
              label: 'Reference',
              field: 'reference',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'Operation Field',
              field: 'requestField',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'From',
              field: 'fromAccount',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'Operation date',
              field: 'opDate',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'Operation type',
              field: 'opType',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'Amount',
              field: 'amount',
              sort: 'asc',
              width: 160,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Receiver',
              field: 'receiver',
              sort: 'asc',
              width: 136,
            },
            {
              label: 'Remove',
              field: 'remove',
              sort: 'asc',
              width: 136,
            },
          ],
          rows: rows,
        },
        loading: false,
        csvRows: csvRows,
        clients: res.data.clients,
      });
    } else {
      console.log(res.error);
    }
  };

  toggleTab(tab) {
    this.setOperations();
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  rmBankOp = async (id) => {
    if (
      window.confirm(
        `Are you sure to delete operation ${id} ? A TrustBuilder Validation will be required`,
      )
    ) {
      this.setState({ loading: true });
      await deleteBankOp(id);
      this.setOperations();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Statements"
              breadcrumbItem="Bank account operations"
            />
            <Can
              role={this.props.user.role}
              perform="operator:financial"
              yes={() => (
                <Link to="/new-bank" className="float-left">
                  <Button className="bg-primary">+</Button>
                </Link>
              )}
            />
            {!this.state.loading && (
              <div className="checkout-tabs">
                <Row>
                  <Col lg="2">
                    <Nav className="flex-column" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === 'all',
                          })}
                          onClick={() => {
                            this.toggleTab('all');
                          }}
                        >
                          <p className="mb-1">All</p>
                          <small className="font-weight-bold mb-4">
                            Bank accounts
                          </small>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    {this.state.banks.map((bank) => (
                      <Nav
                        className="flex-column"
                        pills
                        key={bank.accountNumber}
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                this.state.activeTab === bank.accountNumber,
                            })}
                            onClick={() => {
                              this.toggleTab(bank.accountNumber);
                            }}
                          >
                            <p className="mb-1">{bank.accountName}</p>
                            <small className="font-weight-bold mb-4">
                              {bank.bankName}
                            </small>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    ))}
                  </Col>
                  <Col lg="10">
                    <Card>
                      <CardBody>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="p-3"
                        >
                          <TabPane tabId="all" id="all-order">
                            <MDBDataTable
                              responsive
                              bordered
                              data={this.state.transactions}
                              className="mt-1 font-size-11"
                              striped
                              hover
                            />
                          </TabPane>
                          {this.state.banks.map((bank) => (
                            <TabPane
                              tabId={bank.accountNumber}
                              id={bank.accountNumber}
                            >
                              <MDBDataTable
                                responsive
                                bordered
                                data={this.state.transactions}
                                className="mt-1 font-size-11"
                                striped
                                hover
                              />
                            </TabPane>
                          ))}
                        </TabContent>
                        <Can
                          role={this.props.user.role}
                          perform="operator:financial"
                          yes={() => (
                            <Link to="/new-bank-op">
                              <Button className="bg-success border-0">
                                <strong className="text-dark ">
                                  + New operation
                                </strong>
                              </Button>
                            </Link>
                          )}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}

            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            ) : (
              <FormGroup>
                <Row className="mr-3">
                  <Col sm="12">
                    <CSV
                      transactions={this.state.transactions}
                      csvRows={this.state.csvRows}
                      name="transactions"
                    />
                  </Col>
                </Row>
              </FormGroup>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Bank);
