import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Media } from 'reactstrap';
import { Link } from 'react-router-dom';

import { lastProposals, dictAliases } from '../../helpers/api';

class LatestProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      aliases: {},
    };
  }

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ aliases: res.data });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this._asyncRequest = lastProposals(4)
          .then((res) => {
            if (res.status === 'SUCCESS') {
              let rows = [];
              res.data.forEach((act) => {
                try {
                  const jsonBody = JSON.parse(
                    act.body.replace(/null/g, '"Not applicable"'),
                  );
                  rows.push({
                    id: act.id,
                    created_at:
                      act.createdAt.replace('T', ' ').replace('Z', '') + ' GMT',
                    event: act.event,
                    body: jsonBody,
                    success: act.success ? (
                      <span className="badge badge-success font-size-10">
                        Validated
                      </span>
                    ) : (
                      <span className="badge badge-danger font-size-10">
                        Error
                      </span>
                    ),
                    user_email: act.User ? act.User.email : act.userId,
                  });
                } catch (e) {
                  rows.push({
                    id: act.id,
                    created_at:
                      act.createdAt.replace('T', ' ').replace('Z', '') + ' GMT',
                    event: act.event,
                    body: JSON.stringify(act.body).replaceAll('null', '""'),
                    success: act.success ? (
                      <span className="badge badge-success font-size-10">
                        Validated
                      </span>
                    ) : (
                      <span className="badge badge-danger font-size-10">
                        Error
                      </span>
                    ),
                    user_email: act.User ? act.User.email : act.userId,
                  });
                  console.log(e);
                }
              });
              this.setState({ activities: rows });
            } else {
              console.log(res.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody className="p-2">
            <CardTitle className="mb-3 p-2">
              Latest activity
              <Link
                to="/activity"
                className="float-right btn btn-primary waves-effect waves-light btn-sm"
              >
                View More <i className="mdi mdi-arrow-right ml-1"></i>
              </Link>
            </CardTitle>
            <ul className="verti-timeline list-unstyled">
              {this.state.activities &&
                this.state.activities.map((act) => (
                  <li className="event-list" key={act.id}>
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <Media>
                      <div className="mr-0" style={{ width: '100%' }}>
                        <h5 className="font-size-14 mb-1">
                          {act.body.entrypoint}{' '}
                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                        </h5>
                        <span className="mr-1 font-size-11">
                          {act.body.multiSig === 'administrator' && (
                            <span className="badge badge-primary font-size-10 mr-2">
                              Admin
                            </span>
                          )}
                          {act.body.multiSig === 'minter' && (
                            <span className="badge badge-info font-size-10 mr-2">
                              Minter
                            </span>
                          )}
                          {act.body.multiSig === 'owner' && (
                            <span className="badge badge-pink font-size-10 mr-2">
                              Owner
                            </span>
                          )}
                          {act.body.multiSig === 'reserve' && (
                            <span className="badge badge-success font-size-10 mr-2">
                              Reserve
                            </span>
                          )}
                          From {act.user_email}
                        </span>
                        <br />
                        <span className="font-size-11">
                          {act.body.description}
                        </span>
                        <br />
                        <span className="text-muted font-size-11">
                          {act.created_at}
                        </span>
                      </div>
                    </Media>
                  </li>
                ))}
            </ul>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default LatestProposals;
