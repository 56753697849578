import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { TezosToolkit } from '@taquito/taquito';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { LedgerSigner, DerivationType } from '@taquito/ledger-signer';
import { TezosContractIntrospector } from 'conseiljs';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  dictAliases,
  rightsOps,
  callEntrypoint,
  registerProposal,
  contractStorage,
  registerOperation,
} from '../../helpers/api';
import {
  truncStringPortion,
  capitalizeFirstLetter,
  convertErrorCode,
} from '../../helpers/formatter.js';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import OpModal from './Modals/OpModal.js';
import SuccessModal from './Modals/SuccessModal.js';

const derivationPath = "44'/1729'/0'/0'/0'";
const descLimit = 140;

class RightsOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decimals: 100,
      tokenId: 0,
      activeTab: '1',
      isMenu: false,
      passActive: null,
      aliases: [],
      storage: {},
      details: {},
      entrypoint: {},
      signers: {},
      parameters: [],
      operation: null,
      keystore: null,
      connected: false,
      callingPoint: null,
      tezosNode: null,
      rightsContract: null,
      error: null,
      selectedWallet: null,
      pending: false,
      descCharLeft: descLimit,
      description: null,
      success: null,
      rightsStorage: null,
      jsonParams: null,
      displayResult: null,
      displayAmount: 0,
    };
    this.passPhrase = React.createRef();
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changePassActive = (wType, publicKeyHash = null) => {
    this.setState({ passActive: wType });
    if (publicKeyHash) this.setState({ selectedWallet: publicKeyHash });
  };

  genRanHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');

  componentDidMount = () => {
    this._asyncRequest = dictAliases()
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            aliases: res.data,
            decimals: res.decimals,
            tokenId: res.tokenId,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.handleFetch();
  };

  handleChangeInput = (event) => {
    this['ref_cents'].current.value = event.target.value.slice(0, 2);
  };

  handleFetch = () => {
    const contract = this.props.match.params.contract;
    const op = this.props.match.params.op;

    this.setState({ operation: op, proposalId: `${op}${this.genRanHex(6)}` });
    this._asyncRequest = rightsOps(contract)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          res.data.details.entrypoints.forEach((entry) => {
            if (
              op === 'updateReserve' &&
              entry.entrypoint === 'createUpdateReserveProposal'
            ) {
              this.setState({ callingPoint: 'createUpdateReserveProposal' });
              this.processEntry(entry);
            } else if (
              op === 'updateOracle' &&
              entry.entrypoint === 'createUpdateOracleProposal'
            ) {
              this.setState({ callingPoint: 'createUpdateOracleProposal' });
              this.processEntry(entry);
            } else if (
              op === 'setActivation' &&
              entry.entrypoint === 'createActivationProposal'
            ) {
              this.setState({ callingPoint: 'createActivationProposal' });
              this.processEntry(entry);
            } else if (
              op === 'setMinter' &&
              entry.entrypoint === 'createMinterProposal'
            ) {
              this.setState({ callingPoint: 'createMinterProposal' });
              this.processEntry(entry);
            } else if (
              op === 'setAdministrator' &&
              entry.entrypoint === 'createProposal'
            ) {
              this.setState({ callingPoint: 'createProposal' });
              this.processEntry(entry);
            } else if (
              ['grant', 'revoke', 'addIssuer', 'removeIssuer'].includes(op) &&
              entry.entrypoint === 'createAddressProposal'
            ) {
              this.setState({ callingPoint: 'createAddressProposal' });
              this.processEntry(entry);
            }
          });
          this.setState({
            storage: res.data.storage,
            details: res.data.details,
            signers: res.data.signers,
            tezosNode: res.data.tezosNode,
            rightsContract: res.data.rightsContract,
          });
          this._asyncRequest = contractStorage('rights')
            .then((resp) => {
              if (resp.status === 'SUCCESS') {
                this.setState({ rightsStorage: resp.data.value });
              } else {
                console.log(resp.error);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  processEntry = (entry) => {
    this.setState({ entrypoint: entry });
    entry.values.forEach((value) => {
      if (
        !['contractAddr', 'operation', 'proposalId', 'tokenId'].includes(
          value.name,
        )
      ) {
        if (value.name === 'amount') this['ref_cents'] = React.createRef();
        this['ref_' + value.name] = React.createRef();
        this.setState({ parameters: [...this.state.parameters, value] });
      }
    });
  };

  entryParsing = (parameters, entrypoint, params) => {
    const entryPoints =
      TezosContractIntrospector.generateEntryPointsFromParams(parameters);
    var i = 0;
    while (i < entryPoints.length) {
      if (entryPoints[i].name === entrypoint) break;
      i++;
    }
    return entryPoints[i].generateInvocationPair(...params);
  };

  buildParameters = () => {
    let params = [];
    let jsonParams = {};
    this.state.entrypoint.values.forEach((value) => {
      if ('contractAddr' === value.name) {
        params.push('"' + this.state.rightsContract + '"');
        jsonParams['contractAddr'] = this.state.rightsContract;
      } else if ('operation' === value.name) {
        params.push('"' + this.state.operation + '"');
        jsonParams['operation'] = this.state.operation;
      } else if ('proposalId' === value.name) {
        params.push('"' + this.state.proposalId + '"');
        jsonParams['proposalId'] = this.state.proposalId;
      } else if ('tokenId' === value.name) {
        params.push(this.state.tokenId);
        jsonParams['tokenId'] = this.state.tokenId;
      } else if ('amount' === value.name) {
        if (this['ref_cents'].current.value) {
          params.push(
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ) + parseFloat(this['ref_cents'].current.value * 10000),
          );
          jsonParams['amount'] =
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ) + parseFloat(this['ref_cents'].current.value * 10000);
        } else {
          params.push(
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ),
          );
          jsonParams['amount'] = parseFloat(
            this['ref_' + value.name].current.value * this.state.decimals,
          );
        }
        this.setState({
          displayAmount: parseFloat(jsonParams['amount'] / this.state.decimals),
        });
      } else {
        if (
          !this['ref_' + value.name].current ||
          !this['ref_' + value.name].current.value ||
          this['ref_' + value.name].current.value.length < 2
        ) {
          this.setState({ error: `No value for ${value.name.toString()}` });
          return;
        } else {
          if (value.type === 'bool')
            params.push(this['ref_' + value.name].current.value);
          else if (
            Number.isNaN(parseFloat(this['ref_' + value.name].current.value))
          )
            params.push('"' + this['ref_' + value.name].current.value + '"');
          else params.push(parseFloat(this['ref_' + value.name].current.value));
          jsonParams[value.name] = this['ref_' + value.name].current.value;
        }
      }
    });
    this.setState({ jsonParams: jsonParams });
    return this.entryParsing(
      this.state.details.parameters,
      this.state.callingPoint,
      params,
    );
  };

  ledgerParameters = () => {
    let params = [];
    let jsonParams = {};
    this.state.entrypoint.values.forEach((value) => {
      if ('contractAddr' === value.name) {
        params.push(this.state.rightsContract);
        jsonParams['contractAddr'] = this.state.rightsContract;
      } else if ('operation' === value.name) {
        params.push(this.state.operation);
        jsonParams['operation'] = this.state.operation;
      } else if ('proposalId' === value.name) {
        params.push(this.state.proposalId);
        jsonParams['proposalId'] = this.state.proposalId;
      } else if ('tokenId' === value.name) {
        params.push(this.state.tokenId);
        jsonParams['tokenId'] = this.state.tokenId;
      } else if ('amount' === value.name) {
        if (this['ref_cents'].current.value) {
          params.push(
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ) + parseFloat(this['ref_cents'].current.value * 10000),
          );
          jsonParams['amount'] =
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ) + parseFloat(this['ref_cents'].current.value * 10000);
        } else {
          params.push(
            parseFloat(
              this['ref_' + value.name].current.value * this.state.decimals,
            ),
          );
          jsonParams['amount'] = parseFloat(
            this['ref_' + value.name].current.value * this.state.decimals,
          );
        }
        this.setState({
          displayAmount: parseFloat(jsonParams['amount'] / this.state.decimals),
        });
      } else {
        if (
          !this['ref_' + value.name].current ||
          !this['ref_' + value.name].current.value ||
          this['ref_' + value.name].current.value.length < 2
        ) {
          this.setState({ error: `No value for ${value.name.toString()}` });
          return;
        } else {
          if (value.type === 'bool')
            params.push(this['ref_' + value.name].current.value);
          else if (
            Number.isNaN(parseFloat(this['ref_' + value.name].current.value))
          )
            params.push(this['ref_' + value.name].current.value);
          else params.push(parseFloat(this['ref_' + value.name].current.value));
          jsonParams[value.name] = this['ref_' + value.name].current.value;
        }
      }
    });
    this.setState({ jsonParams: jsonParams });
    return params;
  };

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  ledgerSendOp = async () => {
    try {
      this.setState({
        pending: true,
        info: `Connect your Ledger and approve connection request`,
      });
      const Tezos = new TezosToolkit(this.state.tezosNode);
      const transport = await TransportWebHID.create();
      const ledgerSigner = new LedgerSigner(
        transport, //required
        derivationPath, // path optional (equivalent to "44'/1729'/1'/0'")
        true, // prompt optional
        DerivationType.ED25519, // derivationType optional
      );
      Tezos.setProvider({ signer: ledgerSigner });
      const contract = await Tezos.wallet.at(this.state.details.contract);
      const genParams = this.ledgerParameters();
      this.setState({
        info: `Follow the instruction from your Ledger to send operation`,
      });
      let operation = await contract.methods[this.state.callingPoint](
        ...genParams,
      ).send();
      this.setState({
        info: 'Awaiting confirmation. It may take up to 1 min...',
      });
      await operation.confirmation();
      this.setState({
        pending: false,
        success: `Operation ${operation.opHash} sent`,
      });
      this._asyncRequest = registerProposal(
        this.state.proposalId,
        this.state.selectedWallet,
        this.props.match.params.contract,
        this.state.description,
        this.state.operation,
        this.state.jsonParams,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      this.setState({ displayResult: true });
      this._asyncRequest = registerOperation(
        operation.opHash,
        this.state.operation.split('_').join(' '),
        `Proposal ID ${this.state.proposalId} sent from ${this.props.match.params.contract}`,
        this.state.selectedWallet,
        this.props.match.params.contract,
        this.state.proposalId,
      )
        .then((response) => {
          console.log(response.status);
        })
        .catch((error) => {
          console.log(error);
        });
      await transport.close();
    } catch (e) {
      console.log(e);
      this.setState({
        pending: false,
        info: null,
        success: false,
        error: JSON.stringify(e),
      });
    }
  };

  platformSendOp = () => {
    const genParams = this.buildParameters();
    this.setState({
      pending: true,
      error: null,
      success: null,
      displayResult: false,
    });
    this._asyncRequest = callEntrypoint(
      genParams,
      this.state.selectedWallet,
      this.passPhrase.current.value,
      this.props.match.params.contract,
    )
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            pending: false,
            success: `Operation ${res.data} sent`,
          });
          this._asyncRequest = registerProposal(
            this.state.proposalId,
            this.state.selectedWallet,
            this.props.match.params.contract,
            this.state.description,
            this.state.operation,
            this.state.jsonParams,
          )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) => {
              console.log(error);
            });
          this.setState({ displayResult: true });
          this._asyncRequest = registerOperation(
            res.data,
            this.state.operation === 'set_rights_manager'
              ? 'Remove rights manager'
              : this.state.operation.split('_').join(' '),
            `Proposal ID ${this.state.proposalId} sent from ${this.props.match.params.contract}`,
            this.state.selectedWallet,
            this.props.match.params.contract,
            this.state.proposalId,
          )
            .then((response) => {
              console.log(response.status);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState({ pending: false, error: res.error });
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.toString().split(/[: ]+/).pop() &&
          error.toString().split(/[: ]+/).pop() !== 'ciphertext'
        ) {
          const errorCode = convertErrorCode(
            this.props.match.params.contract,
            error.toString().split(/[: ]+/).pop().replace(')', ''),
          );
          if (errorCode !== undefined)
            this.setState({ pending: false, error: `Error : ${errorCode}` });
          else this.setState({ pending: false, error: error.toString() });
        } else if (
          error.toString().split(/[: ]+/).pop() &&
          error.toString().split(/[: ]+/).pop() === 'ciphertext'
        ) {
          this.setState({ pending: false, error: 'Could not load wallet' });
        } else {
          this.setState({ pending: false, error: error.toString() });
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <SuccessModal
              displayResult={this.state.displayResult}
              proposalId={this.state.proposalId}
              op={this.props.match.params.op}
              contract={this.props.match.params.contract}
              displayAmount={this.state.displayAmount}
              aliases={this.state.aliases}
              selectedWallet={this.state.selectedWallet}
            />
            <OpModal
              pending={this.state.pending}
              info={this.state.info}
              passActive={this.state.passActive}
            />
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Financial Operation"
              breadcrumbItem={
                this.state.operation &&
                this.state.operation === 'set_rights_manager'
                  ? 'Remove rights manager'
                  : this.state.operation &&
                    this.state.operation.split('_').join(' ')
              }
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Send{' '}
                      {this.state.operation &&
                      this.state.operation === 'set_rights_manager'
                        ? 'Remove rights manager'
                        : this.state.operation &&
                          this.state.operation.split('_').join(' ')}{' '}
                      proposal ({this.state.callingPoint})
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav
                        tabs
                        className="nav-tabs-custom"
                        role="tablist"
                      ></Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="operation">
                          <form>
                            <div className="mb-2">
                              <Label>Select a signer to send proposal</Label>
                              {this.state.storage.value && (
                                <Row>
                                  {this.state.storage.value.signers.map(
                                    (signer) => (
                                      <Col xl="3" sm="12" key={signer}>
                                        <div className="mb-3">
                                          <label
                                            className="card-radio-label mb-2"
                                            onClick={() => {
                                              this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType !== 'auto' &&
                                                this.changePassActive(
                                                  this.state.aliases[signer]
                                                    .walletType,
                                                  signer,
                                                );
                                            }}
                                          >
                                            {this.state.aliases[signer] &&
                                              this.state.aliases[signer]
                                                .walletType !== 'auto' && (
                                                <input
                                                  type="radio"
                                                  name="currency"
                                                  id={signer}
                                                  className="card-radio-input"
                                                  readOnly
                                                />
                                              )}

                                            <div
                                              className={
                                                this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType !== 'auto'
                                                  ? 'card-radio'
                                                  : 'card'
                                              }
                                            >
                                              <div>
                                                {this.state.aliases[signer] &&
                                                this.state.aliases[signer]
                                                  .walletType === 'ledger' ? (
                                                  <i className="mdi mdi-shield-key font-size-24 text-info align-middle mr-2"></i>
                                                ) : this.state.aliases[
                                                    signer
                                                  ] &&
                                                  this.state.aliases[signer]
                                                    .walletType ===
                                                    'platform' ? (
                                                  <i className="mdi mdi-wallet font-size-24 text-warning align-middle mr-2"></i>
                                                ) : (
                                                  <i className="mdi mdi-cogs font-size-24 text-dark align-middle mr-2"></i>
                                                )}
                                                <span>
                                                  {this.state.aliases[signer] &&
                                                  this.state.aliases[signer]
                                                    .name ? (
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          this.state.aliases[
                                                            signer
                                                          ].name.replace(
                                                            '(',
                                                            '<br/>(',
                                                          ),
                                                      }}
                                                    ></span>
                                                  ) : (
                                                    truncStringPortion(
                                                      signer,
                                                      8,
                                                      6,
                                                    )
                                                  )}
                                                </span>
                                              </div>
                                              <div>
                                                <p className="text-muted font-size-11 mb-1">
                                                  {truncStringPortion(
                                                    signer,
                                                    8,
                                                    6,
                                                  )}
                                                </p>
                                                <h5 className="font-size-16 mb-1">
                                                  {this.state.signers[signer]
                                                    ? Math.round(
                                                        this.state.signers[
                                                          signer
                                                        ].total_balance * 100,
                                                      ) / 100
                                                    : 0}{' '}
                                                  <TezosLogo
                                                    width="16"
                                                    height="16"
                                                  />
                                                </h5>
                                                <span className="text-muted">
                                                  ~
                                                  {this.state.signers[signer] &&
                                                  this.state.signers[signer]
                                                    .total_balance > 0.12
                                                    ? Math.round(
                                                        this.state.signers[
                                                          signer
                                                        ].total_balance / 0.12,
                                                      )
                                                    : 0}{' '}
                                                  operations
                                                </span>
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </Col>
                                    ),
                                  )}
                                </Row>
                              )}
                            </div>
                            <FormGroup>
                              <Label>Set a proposal description</Label>
                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        {this.state.descCharLeft} char. left
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      maxLength={descLimit}
                                      onChange={this.updateDescription}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                            {this.state.parameters.map((parameter) => (
                              <FormGroup
                                key={parameter.name}
                                className={
                                  parameter.type === 'bool' &&
                                  this.state.callingPoint ===
                                    'createAddressProposal'
                                    ? 'd-none'
                                    : 'd-block'
                                }
                              >
                                <Label>
                                  {capitalizeFirstLetter(
                                    this.state.operation
                                      .split('_')
                                      .join(' ')
                                      .replace('set ', ''),
                                  )}{' '}
                                  {parameter.name &&
                                    capitalizeFirstLetter(parameter.name)}
                                </Label>
                                <Row>
                                  <Col sm="6">
                                    <InputGroup className="mb-2">
                                      <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text">
                                          {parameter.name &&
                                            capitalizeFirstLetter(
                                              parameter.name,
                                            )}
                                        </span>
                                      </InputGroupAddon>
                                      {parameter.type === 'number' ? (
                                        <>
                                          {parameter.name === 'amount' ? (
                                            <>
                                              <input
                                                type="number"
                                                ref={
                                                  this['ref_' + parameter.name]
                                                }
                                                placeholder="Amount in €"
                                                min="1"
                                                className="form-control text-right w-50 mr-0"
                                                onChange={
                                                  this.handleAmountValue
                                                }
                                              />
                                              <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text">
                                                  .
                                                </span>
                                              </InputGroupAddon>
                                              <input
                                                type="number"
                                                ref={this['ref_cents']}
                                                placeholder="00"
                                                min="0"
                                                max="99"
                                                className="form-control ml-0"
                                                onChange={
                                                  this.handleChangeInput
                                                }
                                              />
                                            </>
                                          ) : (
                                            <input
                                              type="number"
                                              ref={
                                                this['ref_' + parameter.name]
                                              }
                                              placeholder={
                                                parameter.type &&
                                                capitalizeFirstLetter(
                                                  parameter.type,
                                                )
                                              }
                                              min="1"
                                              className="form-control"
                                            />
                                          )}
                                        </>
                                      ) : parameter.type === 'bool' ? (
                                        <select
                                          ref={this['ref_' + parameter.name]}
                                          className="form-control"
                                        >
                                          <option value="True">True</option>
                                          <option value="False">False</option>
                                        </select>
                                      ) : (
                                        <input
                                          type={parameter.type}
                                          ref={this['ref_' + parameter.name]}
                                          placeholder={
                                            parameter.type &&
                                            capitalizeFirstLetter(
                                              parameter.type,
                                            )
                                          }
                                          className="form-control"
                                          autoComplete="off"
                                        />
                                      )}
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            ))}
                            {this.state.passActive === 'platform' && (
                              <FormGroup>
                                <Label>Passphrase :</Label>
                                <input
                                  type="password"
                                  ref={this.passPhrase}
                                  className="form-control"
                                />
                              </FormGroup>
                            )}

                            {this.state.error && (
                              <p className="badge badge-danger font-size-12">
                                {this.state.error}
                              </p>
                            )}
                            {this.state.success && (
                              <p className="badge badge-success font-size-12">
                                {this.state.success}
                              </p>
                            )}
                            {this.state.displayResult && (
                              <>
                                <br />
                                <p className="font-size-14 mt-1">
                                  <i className="bx bx-info-circle text-success font-size-18 mr-1"></i>
                                  Proposal ID{' '}
                                  <b className="font-size-12 mr-1">
                                    {this.state.proposalId}
                                  </b>
                                  for operation{' '}
                                  <b>{this.props.match.params.op}</b> sent from
                                  <b className="ml-1">
                                    {capitalizeFirstLetter(
                                      this.props.match.params.contract,
                                    )}
                                  </b>{' '}
                                  {this.state.displayAmount > 0 && (
                                    <span>
                                      of <SVGLogo width="16" height="16" />{' '}
                                      <b>{this.state.displayAmount}</b>
                                    </span>
                                  )}{' '}
                                  with
                                  <b className="ml-1">
                                    {this.state.aliases[
                                      this.state.selectedWallet
                                    ] &&
                                    this.state.aliases[
                                      this.state.selectedWallet
                                    ].name
                                      ? this.state.aliases[
                                          this.state.selectedWallet
                                        ].name
                                      : truncStringPortion(
                                          this.state.selectedWallet,
                                          8,
                                          6,
                                        )}
                                  </b>
                                </p>
                              </>
                            )}

                            {this.state.passActive === 'platform' && (
                              <>
                                {this.state.pending ? (
                                  <p>Processing operation, please wait...</p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.platformSendOp();
                                      }}
                                    >
                                      Send{' '}
                                      {this.state.operation &&
                                        capitalizeFirstLetter(
                                          this.state.operation
                                            .split('_')
                                            .join(' '),
                                        )}{' '}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                            {this.state.passActive === 'ledger' && (
                              <>
                                {this.state.pending ? (
                                  <p>
                                    Follow further instructions on your
                                    ledger...
                                  </p>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      type="button"
                                      disabled={this.state.success}
                                      color="success"
                                      onClick={() => {
                                        this.ledgerSendOp();
                                      }}
                                    >
                                      Send{' '}
                                      {this.state.operation &&
                                        capitalizeFirstLetter(
                                          this.state.operation
                                            .split('_')
                                            .join(' '),
                                        )}{' '}
                                      proposal
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default RightsOperation;
