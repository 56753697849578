import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  Button,
  Media,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  extractsOCA,
  ocaAnalytics,
  clientWhitelistings,
} from '../../helpers/api';
import SVGLogo from '../../components/Common/SVGLogo.js';
import TransactionList from './TransactionList';
import Whitelistings from './whitelistings';
import Can from '../../components/Can';
import CSV from '../../components/Common/CSV';

const curr = new Date();
curr.setDate(curr.getDate());
const todayDate = curr.toISOString().substr(0, 10);

const final = new Date();
final.setDate(final.getDate() - 6);
const startDate = final.toISOString().substr(0, 10);

class OCA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extracts: [],
      client: {},
      loading: true,
      startDate: startDate,
      endDate: todayDate,
      loadingAnalytics: false,
      nbTx: 0,
      volume: 0,
      dispatch: 0,
      sell: 0,
      buy: 0,
      transfer: 0,
      uniqueUsers: [],
      changes: [],
      sortedKeysChange: [],
      errorDate: null,
      filter: 'analytics',
      dailyFrame: [],
      options: {},
      seriesNbTx: [],
      seriesFees: [],
      seriesCashFlow: [],
      seriesVolume: [],
      csvRows: [],
      transactions: { columns: [], rows: [] },
      nbDays: 0,
      totalCryptoFees: 0.0,
      totalEurFees: 0.0,
      whitelistings: [],
      totalPendings: 0,
    };
  }

  componentDidMount = () => {
    const publicKeyHash = this.props.match.params.publicKeyHash;
    // Retrieve all extracts for client
    this._asyncRequest = extractsOCA(publicKeyHash)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            extracts: res.data.extracts,
            client: res.data.client,
            loading: false,
          });
        } else {
          console.log(res.error);
          this.setState({ loading: false });
        }
        this.getAnalytics();
      })
      .catch((error) => {
        console.log(error);
      });
    // Fetch whitelistings for client
    this.fetchWhitelistings();
  };

  getAnalytics = () => {
    this.setState({ loadingAnalytics: true, errorDate: null });
    this._asyncRequest = ocaAnalytics(
      this.state.client.publicKeyHash,
      this.state.startDate,
      this.state.endDate,
    )
      .then((res) => {
        if (res.status === 'SUCCESS') {
          const keysSorted = Object.keys(res.data.changes).sort(
            (a, b) => res.data.changes[b].counter - res.data.changes[a].counter,
          );
          let sortedRows = res.data.txs,
            formattedRows = [];
          sortedRows.sort(function (a, b) {
            var x = a.timestamp.toLowerCase();
            var y = b.timestamp.toLowerCase();
            if (x > y) {
              return -1;
            }
            if (x < y) {
              return 1;
            }
            return 0;
          });
          sortedRows.forEach((op) => {
            op.date = new Date(op.timestamp * 1000).toISOString();
            formattedRows.push(op);
          });
          this.setState({
            loadingAnalytics: false,
            nbTx: res.data.txs.length,
            volume: res.data.volume,
            dispatch: res.data.dispatch,
            buy: res.data.buy,
            sell: res.data.sell,
            transfer: res.data.transfer,
            uniqueUsers: res.data.uniqueUsers,
            changes: res.data.changes,
            sortedKeysChange: keysSorted,
            nbDays: res.data.dates.length,
            totalCryptoFees: res.data.totalCryptoFees,
            totalEurFees: res.data.totalEurFees,
            options: {
              chart: {
                id: 'nbTx-chart',
                stacked: true,
              },
              colors: ['#556ee6', '#003581', '#7200ea'],
              xaxis: {
                categories: res.data.dates.sort(),
              },
            },
            seriesNbTx: [
              {
                name: 'purchases',
                data: res.data.dates
                  .sort()
                  .map((d) => res.data.dailyFrame[d].buy),
              },
              {
                name: 'sales',
                data: res.data.dates
                  .sort()
                  .map((d) => res.data.dailyFrame[d].sell),
              },
              {
                name: 'transfers',
                data: res.data.dates
                  .sort()
                  .map((d) => res.data.dailyFrame[d].transfer),
              },
            ],
            seriesFees: [
              {
                name: 'cryptoFees',
                data: res.data.dates
                  .sort()
                  .map(
                    (d) =>
                      Math.round(res.data.dailyFrame[d].cryptoFees * 100) / 100,
                  ),
              },
              {
                name: 'eurFees',
                data: res.data.dates
                  .sort()
                  .map(
                    (d) =>
                      Math.round(res.data.dailyFrame[d].eurFees * 100) / 100,
                  ),
              },
            ],
            seriesCashFlow: [
              {
                name: 'cashFlow',
                data: res.data.dates
                  .sort()
                  .map(
                    (d) =>
                      Math.round(res.data.dailyFrame[d].dispatch * 100) / 100,
                  ),
              },
            ],
            seriesVolume: [
              {
                name: 'volume',
                data: res.data.dates
                  .sort()
                  .map(
                    (d) =>
                      Math.round(res.data.dailyFrame[d].volume * 100) / 100,
                  ),
              },
            ],
            transactions: {
              columns: [
                {
                  label: 'Operation ID',
                  field: 'op_id',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'EUROP amount',
                  field: 'eurus',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Date',
                  field: 'date',
                  sort: 'asc',
                  width: 136,
                },
                {
                  label: 'Operation',
                  field: 'operation',
                  width: 264,
                },
                {
                  label: 'Pair',
                  field: 'pair',
                  sort: 'asc',
                  width: 104,
                },
                {
                  label: 'User ID',
                  field: 'user_id',
                  sort: 'asc',
                  width: 104,
                },
                {
                  label: 'Fees',
                  field: 'fees',
                  sort: 'asc',
                  width: 104,
                },
              ],
              rows: formattedRows,
            },
            csvRows: formattedRows,
          });
        } else {
          console.log(res.error);
          this.setState({ loadingAnalytics: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loadingAnalytics: false });
      });
  };

  regStartDate = (event) => {
    if (event.target.value <= this.state.endDate)
      this.setState({ startDate: event.target.value, errorDate: null });
    else {
      this.setState({
        errorDate: 'Start date must be before end date',
        startDate: this.state.endDate,
      });
    }
  };

  regEndDate = (event) => {
    if (event.target.value >= this.state.startDate)
      this.setState({ endDate: event.target.value, errorDate: null });
    else
      this.setState({
        errorDate: 'End date must be after start date',
        endDate: this.state.startDate,
      });
  };

  setFilter = (filter) => {
    this.setState({ filter: filter });
  };

  fetchWhitelistings = () => {
    this._asyncRequest = clientWhitelistings(
      this.props.match.params.publicKeyHash,
    )
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({
            whitelistings: res.data.whitelistings,
            totalPendings: res.data.totalPendings,
          });
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.filter === 'analytics' ? (
              <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-2">
                <i className="mdi mdi-chart-timeline-variant mr-1"></i>Analytics
              </div>
            ) : (
              <div
                className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-2"
                onClick={() => {
                  this.setFilter('analytics');
                }}
              >
                <i className="mdi mdi-chart-timeline-variant mr-1"></i>Analytics
              </div>
            )}
            {this.state.filter === 'extracts' ? (
              <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-2">
                <i className="mdi mdi-checkbook mr-1"></i>Extracts
              </div>
            ) : (
              <div
                className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-2"
                onClick={() => {
                  this.setFilter('extracts');
                }}
              >
                <i className="mdi mdi-checkbook mr-1"></i>Extracts
              </div>
            )}
            <Can
              role={this.props.user.role}
              perform="admin:access"
              yes={() => {
                return (
                  <>
                    {this.state.filter === 'whitelistings' ? (
                      <div className="btn btn-primary waves-effect waves-light btn-sm mr-2 mb-2">
                        <i className="mdi mdi-account-check mr-1"></i>
                        Whitelistings
                        {this.state.totalPendings > 0 && (
                          <span className="badge badge-pill badge-success float-right font-size-10 ml-2">
                            {this.state.totalPendings}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div
                        className="btn btn-secondary waves-effect waves-light btn-sm mr-2 mb-2"
                        onClick={() => {
                          this.setFilter('whitelistings');
                        }}
                      >
                        <i className="mdi mdi-account-check mr-1"></i>
                        Whitelistings
                        {this.state.totalPendings > 0 && (
                          <span className="badge badge-pill badge-success float-right font-size-10 ml-2">
                            {this.state.totalPendings}
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              }}
            />
            {this.state.loading ? (
              <div className="text-center my-3">
                <Link to="#" className="text-primary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                  Loading{' '}
                </Link>
              </div>
            ) : (
              <>
                {this.state.filter === 'analytics' && (
                  <>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          {this.state.client.name} Analytics
                        </CardTitle>
                        <Row>
                          {this.state.errorDate && (
                            <Col md="12">
                              <p className="text-danger">
                                {this.state.errorDate}
                              </p>
                            </Col>
                          )}
                          <Col md="4">
                            <FormGroup>
                              <Label>Set a start date</Label>
                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        Start
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      value={this.state.startDate}
                                      onChange={this.regStartDate}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Set an end date</Label>
                              <Row>
                                <Col sm="12">
                                  <InputGroup className="mb-2">
                                    <InputGroupAddon addonType="prepend">
                                      <span className="input-group-text">
                                        End
                                      </span>
                                    </InputGroupAddon>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      value={this.state.endDate}
                                      onChange={this.regEndDate}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Label>Get analytics</Label>
                              <Row>
                                <Col sm="12">
                                  {!this.state.loadingAnalytics && (
                                    <Button
                                      type="button"
                                      color="success"
                                      onClick={() => {
                                        this.getAnalytics();
                                      }}
                                    >
                                      Calculate
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2" className="text-right">
                            {!this.state.loadingAnalytics && (
                              <FormGroup>
                                <Label className="text-right">
                                  Download report
                                </Label>
                                <Row>
                                  <Col sm="12">
                                    <CSV
                                      transactions={this.state.transactions}
                                      csvRows={this.state.csvRows}
                                      name="transactions"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {this.state.loadingAnalytics ? (
                      <div className="text-center my-3 mb-4">
                        <Link to="#" className="text-success">
                          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{' '}
                          Calculating analytics...{' '}
                        </Link>
                      </div>
                    ) : (
                      <Row>
                        <Col md={this.state.nbDays > 7 ? '12' : '6'}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted font-weight-medium">
                                    Cash-flow
                                  </p>
                                  <h4 className="mb-0">
                                    <SVGLogo />{' '}
                                    {this.state.dispatch &&
                                      this.state.dispatch.toLocaleString('fr')}
                                  </h4>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="bx bx-chart font-size-24"></i>
                                  </span>
                                </div>
                              </Media>
                              <div
                                id="cashFlow"
                                className="apex-charts mt-4"
                                dir="ltr"
                              >
                                <ReactApexChart
                                  series={this.state.seriesCashFlow}
                                  options={this.state.options}
                                  type="bar"
                                  height={310}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={this.state.nbDays > 7 ? '12' : '6'}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted font-weight-medium">
                                    Volume
                                  </p>
                                  <h4 className="mb-0">
                                    <SVGLogo />{' '}
                                    {this.state.volume &&
                                      this.state.volume.toLocaleString('fr')}
                                  </h4>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="bx bx-trending-up font-size-24"></i>
                                  </span>
                                </div>
                              </Media>
                              <div
                                id="volume"
                                className="apex-charts mt-4"
                                dir="ltr"
                              >
                                <ReactApexChart
                                  series={this.state.seriesVolume}
                                  options={this.state.options}
                                  type="bar"
                                  height={310}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={this.state.nbDays > 7 ? '12' : '6'}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted font-weight-medium">
                                    Number of Tx
                                  </p>
                                  <h4 className="mb-0">
                                    {this.state.nbTx}
                                    <span className="ml-2 font-size-14">
                                      ({' '}
                                      <span className="ml-1 mr-1 text-muted font-size-12">
                                        Purchases
                                      </span>{' '}
                                      {this.state.buy}
                                      <span className="ml-1 mr-1 text-muted">
                                        |
                                      </span>
                                      <span className="ml-1 mr-1 text-muted font-size-12">
                                        Sales
                                      </span>{' '}
                                      {this.state.sell}
                                      <span className="ml-1 mr-1 text-muted">
                                        |
                                      </span>
                                      <span className="ml-1 mr-1 text-muted font-size-12">
                                        Transfers
                                      </span>{' '}
                                      {this.state.transfer} )
                                    </span>
                                  </h4>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="bx bx-transfer-alt font-size-24"></i>
                                  </span>
                                </div>
                              </Media>
                              <div
                                id="nbTx"
                                className="apex-charts mt-4"
                                dir="ltr"
                              >
                                <ReactApexChart
                                  series={this.state.seriesNbTx}
                                  options={this.state.options}
                                  type="bar"
                                  height={310}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={this.state.nbDays > 7 ? '12' : '6'}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted font-weight-medium">
                                    Fees
                                  </p>
                                  <h4 className="mb-0">
                                    €
                                    {parseFloat(
                                      this.state.totalCryptoFees +
                                        this.state.totalEurFees,
                                    ).toFixed(2)}
                                    <span className="ml-2 font-size-14">
                                      ({' '}
                                      <span className="ml-1 mr-1 text-muted font-size-12">
                                        Crypto fees
                                      </span>{' '}
                                      €{this.state.totalCryptoFees}{' '}
                                      <span className="ml-1 mr-1 text-muted">
                                        |
                                      </span>{' '}
                                      <span className="ml-1 mr-1 text-muted font-size-12">
                                        Eur fees
                                      </span>{' '}
                                      €{this.state.totalEurFees} )
                                    </span>
                                  </h4>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <span className="font-size-24">€</span>
                                  </span>
                                </div>
                              </Media>
                              <div
                                id="fees"
                                className="apex-charts mt-4"
                                dir="ltr"
                              >
                                <ReactApexChart
                                  series={this.state.seriesFees}
                                  options={this.state.options}
                                  type="bar"
                                  height={310}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="6">
                          <Card className="mini-stats-wid bg-primary">
                            <CardBody>
                              <Media>
                                <Media
                                  body
                                  style={{
                                    height: '300px',
                                    overflowX: 'hidden',
                                  }}
                                >
                                  <p className="text-white font-weight-medium">
                                    Trading pair
                                  </p>
                                  {this.state.sortedKeysChange &&
                                    this.state.sortedKeysChange.map(
                                      (keyChange) => (
                                        <Row
                                          className="mb-0 text-white"
                                          key={keyChange}
                                        >
                                          <Col md="2">
                                            {
                                              this.state.changes[keyChange]
                                                .counter
                                            }
                                          </Col>
                                          <Col md="5">{keyChange}</Col>
                                          <Col md="5">
                                            {this.state.changes[keyChange]
                                              .volume &&
                                              this.state.changes[
                                                keyChange
                                              ].volume.toLocaleString(
                                                'fr',
                                              )}{' '}
                                            <SVGLogo />
                                          </Col>
                                        </Row>
                                      ),
                                    )}
                                </Media>
                              </Media>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="2">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <Media>
                                <Media body>
                                  <p className="text-muted font-weight-medium">
                                    Unique users
                                  </p>
                                  <h4 className="mb-0">
                                    {this.state.uniqueUsers &&
                                      this.state.uniqueUsers.length}
                                  </h4>
                                </Media>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="bx bx-user-circle font-size-24"></i>
                                  </span>
                                </div>
                              </Media>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {this.state.filter === 'extracts' && (
                  <Row>
                    <Col lg="12">
                      <TransactionList
                        extracts={this.state.extracts}
                        client={this.state.client}
                      />
                    </Col>
                  </Row>
                )}
                {this.state.filter === 'whitelistings' && (
                  <Whitelistings
                    publicKeyHash={this.props.match.params.publicKeyHash}
                    fetchWhitelistings={this.fetchWhitelistings}
                    whitelistings={this.state.whitelistings}
                    clientName={this.state.client && this.state.client.name}
                  />
                )}
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default withRouter(
  connect(mapStatetoProps, null)(withTranslation()(OCA)),
);
