import React, { Component } from 'react';
import { Modal, Row, Col, FormGroup, Label } from 'reactstrap';
import Loader from 'react-loader-spinner';

import { regenerateCredentials } from '../../../helpers/api';

class CredentialsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: null,
      pending: false,
    };
  }

  copyToken = () => {
    navigator.clipboard.writeText(this.props.apicredentials.token);
    this.setState({ copied: 'token' });
    setTimeout(
      function () {
        this.setState({ copied: null });
      }.bind(this),
      2000,
    );
  };

  copySecret = () => {
    navigator.clipboard.writeText(this.props.apicredentials.secret);
    this.setState({ copied: 'secret' });
    setTimeout(
      function () {
        this.setState({ copied: null });
      }.bind(this),
      2000,
    );
  };

  regenerateClientCredentials = () => {
    this.setState({ pending: true });
    this._asyncRequest = regenerateCredentials(this.props.apicredentials.clientPublicKeyHash)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          this.setState({ pending: false });
          this.props.loadClient();
        } else {
          this.setState({ error: res.error, pending: false });
          console.log(res.error);
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString(), pending: false });
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          size="md"
          isOpen={this.props.credentialsModal}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Client credentials
            </h5>
          </div>
          <div className="modal-body">
            {this.props.error && (
              <p className="text-danger">{this.props.error}</p>
            )}
            {this.props.success && (
              <p className="text-success">{this.props.success}</p>
            )}
            <FormGroup>
              <Label>X-Access-Token</Label>

              <Row>
                <Col sm="12">
                  <p className="text-muted mb-2 text-break">
                    {this.props.apicredentials.token || 'None'}
                    {this.state.copied !== 'token' ? (
                      this.props.apicredentials.token &&
                      <span
                        className="badge badge-primary clickable ml-2"
                        onClick={() => {
                          this.copyToken();
                        }}
                      >
                        copy
                      </span>
                    ) : (
                      <span className="badge text-success pointer ml-2">
                        copied!
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Label>Secret</Label>

              <Row>
                <Col sm="12">
                  <p className="text-muted mb-2 text-break">
                    {this.props.apicredentials.secret || 'None'}
                    {this.state.copied !== 'secret' ? (
                      this.props.apicredentials.secret &&
                      <span
                        className="badge badge-primary clickable ml-2"
                        onClick={() => {
                          this.copySecret();
                        }}
                      >
                        copy
                      </span>
                    ) : (
                      <span className="badge text-success pointer ml-2">
                        copied!
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            </FormGroup>
            <Label>
              To regenerate credentials TrustBuilder Authenticator is required
            </Label>
            {this.state.pending ? (
              <React.Fragment>
                <Loader type="Puff" color="#50a5f1" height={50} width={50} />
                <p className="mt-2 text-info">
                  Notification sent onto your TrustBuilder Authenticator
                  application.{' '}
                  <strong>
                    Set your pin code to regenerate API credentials...
                  </strong>
                </p>
              </React.Fragment>
            ) : (
              <Row className="mt-3">
                <Col sm="4">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => this.props.toggleCredentialsModal()}
                  >
                    Close
                  </button>
                </Col>
                <Col sm="6">
                  <button
                    className="btn btn-warning btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      if (
                        window.confirm('Regenerate Secret and X-Access-Token?')
                      ) {
                        this.regenerateClientCredentials();
                      }
                    }}
                  >
                    Regenerate
                  </button>
                  <p className="text-muted font-size-11">
                    If you click Regenerate, actual credentials will not work
                    anymore
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CredentialsModal;
