import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { toggleRightSidebar, changeToken } from "../../store/actions";
import { PLATFORM } from "../../constants.js";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import TezosLogo from "../../components/Common/TezosLogo.js";
import EthereumLogo from "../../components/Common/EthereumLogo.js";
import PolygonLogo from "../Common/PolygonLogo";
import { getChains } from "../../helpers/api.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      dropdownOpen: false,
      chains: [],
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.searchEntry = React.createRef();
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggles the network selecter
   */
  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (
        this.searchEntry.current.value.length === 36 &&
        this.searchEntry.current.value.includes("tz")
      )
        window.open(
          `${
            PLATFORM === "staging"
              ? "https://ghostnet.tzkt.io"
              : "https://tzkt.io"
          }/${this.searchEntry.current.value}`
        );
      else if (
        this.searchEntry.current.value.length === 36 &&
        this.searchEntry.current.value.includes("KT")
      )
        window.open(
          `${
            PLATFORM === "staging"
              ? "https://ghostnet.tzkt.io"
              : "https://tzkt.io"
          }/${this.searchEntry.current.value}`
        );
      else if (this.searchEntry.current.value.length === 51)
        window.open(
          `${
            PLATFORM === "staging"
              ? "https://ghostnet.tzkt.io"
              : "https://tzkt.io"
          }/${this.searchEntry.current.value}`
        );
      else if (
        this.searchEntry.current.value.length === 42 &&
        this.searchEntry.current.value.includes("0x")
      )
        window.open(
          `${
            PLATFORM === "staging"
              ? "https://sepolia.etherscan.io/address/"
              : "https://etherscan.io/address/"
          }${this.searchEntry.current.value}`
        );
      else if (
        this.searchEntry.current.value.length === 66 &&
        this.searchEntry.current.value.includes("0x")
      )
        window.open(
          `${
            PLATFORM === "staging"
              ? "https://sepolia.etherscan.io/tx/"
              : "https://etherscan.io/tx/"
          }${this.searchEntry.current.value}`
        );
      else alert("Unrecognized format");
    }
  };

  /**
   * Switch selected token
   */
  changeToken = (token) => {
    this.props.changeToken(token);
  };

  componentDidMount = async () => {
    const resp = await getChains();
    this.setState({ chains: resp.chains });
    if (!this.props.activeToken || this.props.activeToken === "fa12")
      await this.props.changeToken("fa2");
  };

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link
                  to={"/"}
                  className="logo logo-dark"
                >
                  <span className="logo-sm">
                    <img src={logo} alt="logo-salvus" height="26" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="logo-salvus" height="30" />
                  </span>
                </Link>

                <Link
                  to={"/"}
                  className="logo logo-light"
                >
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="logo-salvus" height="26" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="logo-salvus" height="30" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
              {this.props.user && this.props.user.role !== "CLIENT" && (
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}
                  className="mt-3"
                >
                  {this.state.chains.map(
                    (chain) =>
                      this.props.activeToken === chain.slug && (
                        <DropdownToggle
                          caret
                          color="primary"
                          key={`${chain.name}-active`}
                        >
                          {chain.name === "tezos" && (
                            <TezosLogo width="16" height="16" />
                          )}
                          {chain.name === "ethereum" && (
                            <EthereumLogo width="16" height="16" />
                          )}
                          {chain.name === "polygon" && (
                            <PolygonLogo width="16" height="16" />
                          )}
                          <span className="text-capitalize">
                            {chain.name} [{chain.standard}]
                          </span>
                        </DropdownToggle>
                      )
                  )}
                  <DropdownMenu>
                    {this.state.chains.map((chain) => (
                      <DropdownItem
                        key={`${chain.name}-select`}
                        onClick={() => {
                          this.changeToken(chain.slug);
                        }}
                      >
                        <span className="text-capitalize">
                          {chain.name} [{chain.standard}]
                        </span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              )}
              {this.props.user && this.props.user.role !== "CLIENT" && (
                <div className="app-search d-none d-lg-block ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      ref={this.searchEntry}
                      className="form-control"
                      placeholder={this.props.t("Search opId, address") + "..."}
                      onKeyPress={this.handleKeyPress}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex">
              {/*<LanguageDropdown />*/}

              {/*
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={this.toggleFullscreen} className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
              */}

              {this.props.user && this.props.user.role !== "CLIENT" && (
                <NotificationDropdown />
              )}
              <ProfileMenu />

              {this.props.user && this.props.user.role !== "CLIENT" && (
                <div
                  onClick={this.toggleRightbar}
                  className="dropdown d-inline-block"
                >
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <i className="bx bxs-wallet"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  const { user } = state.Login;
  const { activeToken } = state.Token;
  return { layoutType, user, activeToken };
};

export default connect(mapStatetoProps, { toggleRightSidebar, changeToken })(
  withTranslation()(Header)
);
