import './assets/scss/theme.scss';
import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authProtectedRoutes, publicRoutes } from './routes/';
import { AUTH_TOKEN } from './constants';
import { getUser } from './helpers/api';
import { loginSuccess, logoutUser, checkEndUser } from './store/actions';
import AppRoute from './routes/route';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import BeatLoader from 'react-spinners/BeatLoader';
import ProposalsStore from './components/ProposalsStore';

const App = ({ loginSuccess, logoutUser, checkEndUser, history, user }) => {
  const loadUser = async () => {
    if (localStorage.getItem(AUTH_TOKEN)) {
      let res = await getUser();
      if (res.status === 'SUCCESS') {
        loginSuccess(res, null);
      } else {
        logoutUser(history);
      }
    } else {
      checkEndUser(history);
    }
  };
  const getLayout = () => {
    return VerticalLayout;
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {user && user.checkLoading && (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <BeatLoader size={15} margin={2} loading={true} color={'#2a3042'} />
          </div>
        </div>
      )}
      {user && !user.checkLoading && (
        <ProposalsStore.Provider>
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  user={user}
                  path={route.path}
                  layout={getLayout()}
                  component={route.component}
                  action={route.action}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Router>
        </ProposalsStore.Provider>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: state.Login,
  };
};

export default withRouter(
  connect(mapStateToProps, { loginSuccess, logoutUser, checkEndUser })(App),
);
