import React, { useState } from 'react'
import { Modal, Alert } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BeatLoader from 'react-spinners/BeatLoader';

import { updateNotificationGroup } from '../../helpers/api';

const NewAlert = ({ loadNotifications, notification }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (evt, values) => {
    setLoading(true)
    setError('')
    let res = await updateNotificationGroup({ alertId: notification.id, ...values })
    setLoading(false)
    if (res.status === 'SUCCESS') {
      loadNotifications()
      setOpen(false)
    } else {
      setError(res.error||'An error occured')
    }
  }

  return (
    <React.Fragment>
      <span className="w-md" style={{ cursor: 'pointer' }} onClick={() => {
        setOpen(true)
      }}><i className="bx bx-pencil"></i></span>
      <Modal
        isOpen={open}
        toggle={() => setOpen(prev => !prev)}
      >
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">Update Alert Group</h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading && (
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                  <BeatLoader
                    size={15}
                    margin={2}
                    loading={true}
                    color={"#2a3042"}
                  />
                </div>
              </div>
            )}
            {!loading && (
              <React.Fragment>
                {error !== '' ? <Alert color="danger">{error}</Alert> : null}

                <div className="form-group">
                  <AvField name="name" label="Group name" value={notification.name} className="form-control" placeholder="Name" type="text" required />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >Close</button>

            <button className="btn btn-primary waves-effect waves-light" type="submit">Update alert group</button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  )
}

export default NewAlert