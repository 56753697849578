import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGIN_INWEBO, LOGOUT_USER } from './actionTypes';
import { loginSuccess, apiError } from './actions';

import {
  loginUser as apiLoginUser,
  loginInwebo as apiLoginInwebo,
} from '../../../helpers/api';
import { AUTH_TOKEN } from '../../../constants';

function* loginUser({ payload: { user, history, recaptcha } }) {
  try {
      const response = yield call(apiLoginUser, user.email, user.password, user.code, recaptcha);
      if (response && response.expired) {
          history.push(`/reset-password?k=${response.resetPasswordKey}`);
      } else {
          if(response.user && ['CLIENT', 'CLIENT_ADMIN'].includes(response.user.role))
              yield put(apiError({message: '{"err":"CLIENT"}'}));
          else{
              yield put(loginSuccess(response));
              localStorage.setItem(AUTH_TOKEN, response.token);
              history.push('/dashboard');
          }
      }
  } catch (error) {
      console.log(error)
      yield put(apiError(error));
  }
}

function* loginInwebo({ payload: { user, history, recaptcha } }) {
  try {
    const response = yield call(apiLoginInwebo, user.email, recaptcha);

    if (response) {
      if (user && user.role !== 'CLIENT') {
        yield put(loginSuccess(response));
        localStorage.setItem(AUTH_TOKEN, response.token);
        history.push('/dashboard');
      }else{
        alert("Cannot sign as client here!!!")
      }
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(AUTH_TOKEN);
    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLoginInwebo() {
  yield takeEvery(LOGIN_INWEBO, loginInwebo);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLoginInwebo),
    fork(watchUserLogout),
  ]);
}

export default authSaga;
