import React, { Component } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

import { updtClientEmail } from '../../../../helpers/api';

const regEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      error: null,
      pending: false,
    };
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
      validEmail: regEmail.test(event.target.value),
    });
  };

  updtEmail = () => {
    if (!this.state.validEmail)
      this.setState({ error: 'Invalid email!', pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = updtClientEmail(
        this.props.publicKeyHash,
        this.state.email,
      )
        .then((res) => {
          if (res.status === 'SUCCESS') {
            this.props.loadClient();
            this.props.toggleEmailModal();
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  render() {
    return (
      <Modal
        size="md"
        isOpen={this.props.emailModal}
        centered={true}
        backdrop="static"
        wrapClassName="modal-balance"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Update Email
          </h5>
        </div>
        <div className="modal-body">
          {this.state.error && (
            <p className="text-danger">{this.state.error}</p>
          )}
          <FormGroup>
            <Label>Email</Label>

            <Row>
              <Col sm="12">
                <InputGroup className="mb-2">
                  <InputGroupAddon addonType="prepend">
                    <span className="input-group-text">@</span>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="form-control"
                    onChange={this.handleEmail}
                    autoComplete="off"
                  />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
          {this.state.pending ? (
            <p>Processing...</p>
          ) : (
            <Row className="mt-3">
              <Col sm="4">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  onClick={() => this.props.toggleEmailModal()}
                >
                  Close
                </button>
              </Col>
              <Col sm="6">
                <button
                  className="btn btn-warning btn-block waves-effect waves-light"
                  type="submit"
                  onClick={() => {
                    this.updtEmail();
                  }}
                >
                  Update
                </button>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    );
  }
}

export default EmailModal;
